import React, { useState, useEffect } from "react";
import axiosInstance from "../utils/http/config/axios";
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import './style.css';
import userimg from '../images/user-img.png';
import uploadicon from '../images/upload-icon.png';
import { useNavigate } from 'react-router-dom';

function Profile() {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    gender: '',
    birthDate: '',
    birthCity: '',
    addressNumber: '',  // street number
    streetName: '',  // street name
    additionalAddress: '',  // additional address info
    postalCode: '',
    city: '',
    country: '',
    drivingCity: '',
    profilePicture: ''
  });

  const [value, setValue] = useState(''); 
  const [profilePicPreview, setProfilePicPreview] = useState(userimg);
  const [errors, setErrors] = useState({});
  const [alert, setAlert] = useState({ type: '', message: '' });
  const [loading, setLoading] = useState(false); // Loading state for the update button

  // Fetch profile data from backend
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const token = localStorage.getItem('authToken'); 
        const response = await axiosInstance.get("/api/students/profile", {
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });

        const data = response.data.student;
        setFormData({
          firstName: data.user.firstName || '',
          lastName: data.user.lastName || '',
          email: data.user.email || '',
          gender: data.gender || '',
          birthDate: data.birthDate || '',
          birthCity: data.birthCity || '',
          addressNumber: data.addressNumber || '',  
          streetName: data.streetName || '',
          additionalAddress: data.additionalAddress || '', 
          postalCode: data.postalCode || '',
          city: data.city || '',
          country: data.country || '',
          drivingCity: data.drivingCity || '',
          profilePicture: data.profilePicture || '',
        });

        setValue(data.user.phoneNumber || '');

        const profilePictureUrl = data.user.profilePicture 
          ? `${window.location.origin}/${data.user.profilePicture}` 
          : userimg;
          
        setProfilePicPreview(profilePictureUrl);

        const createdAt = new Date(data.createdAt).toLocaleString('fr-FR', {
          timeZone: 'Europe/Paris',
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
        });
        localStorage.setItem('createdAt', createdAt);

      } catch (error) {
        console.error('Error fetching profile:', error);
      }
    };

    fetchProfile();
  }, []);

  // Handle profile picture change
  const handleProfilePicChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.type !== 'image/jpeg' && file.type !== 'image/png') {
        setAlert({ type: 'error', message: 'Type de fichier non pris en charge. Veuillez télécharger une image JPEG ou PNG.' });
        return;
      }
      setProfilePicPreview(URL.createObjectURL(file));
      setFormData(prevData => ({ ...prevData, profilePicture: file }));
    }
  };

  // Validate form data
  const validateForm = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = 'Le prénom est obligatoire';
    if (!formData.lastName) newErrors.lastName = 'Le nom est obligatoire';
    if (!formData.email) newErrors.email = 'L\'adresse mail est obligatoire';
    if (!formData.gender) newErrors.gender = 'Le genre est obligatoire';
    if (!formData.birthDate) newErrors.birthDate = 'La date de naissance est obligatoire';
    if (!formData.birthCity) newErrors.birthCity = 'La ville de naissance est obligatoire';
    if (!formData.addressNumber) newErrors.addressNumber = 'Le numéro de rue est obligatoire';  // Street number validation
    if (!formData.streetName) newErrors.streetName = 'Le nom de la rue est obligatoire';  // Street name validation
    if (!formData.postalCode) newErrors.postalCode = 'Le code postal est obligatoire';
    if (!formData.city) newErrors.city = 'La ville est obligatoire';
    if (!formData.country) newErrors.country = 'Le pays est obligatoire';
    if (!value) newErrors.phoneNumber = 'Le numéro de téléphone est obligatoire';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Handle form submission
  const handleSubmit = async () => {
    if (validateForm()) {
      setLoading(true); 
      try {
        const token = localStorage.getItem('authToken');
        const formDataToSubmit = new FormData();

        Object.keys(formData).forEach(key => {
          if (formData[key] !== null && formData[key] !== '') {
            formDataToSubmit.append(key, formData[key]);
          }
        });

        formDataToSubmit.append('phoneNumber', value);  // append phone number to formData

        await axiosInstance.put('/api/students/profile', formDataToSubmit, {
          headers: {
            'Authorization': `Bearer ${token}`,
            'Content-Type': 'multipart/form-data'
          }
        });

        setAlert({ type: 'success', message: 'Profil mis à jour avec succès !' });
        setTimeout(() => {
          setAlert({ type: '', message: '' });
        }, 3000);
      } catch (error) {
        if (error.response && error.response.data.message) {
          setAlert({ type: 'error', message: error.response.data.message });
        } else {
          setAlert({ type: 'error', message: 'Échec de la mise à jour du profil. Veuillez réessayer.' });
        }
        setTimeout(() => {
          setAlert({ type: '', message: '' });
        }, 3000);
      } finally {
        setLoading(false); // Reset loading state
      }
    }
  };

  // Handle form input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }));
  };
  
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1); 
  };

  return (
    <section className='userprofile'>
      <div className='container'>
        <div className='wrapping'>
          {alert.message && <div className={`alert alert-${alert.type}`}>{alert.message}</div>}
          <div className='back-btn'>
            <button type="button" onClick={goBack} className="btn btn-secondary btn-main">
              Retour
            </button>
          </div>
          <div className='top-heading-profile'>
            <h3>Informations personnelles</h3>
            
            <div className='user-img'>
              <div className='profile'>
                <img src={profilePicPreview} alt="User" />
              </div>
              <div className='upload'>
                <img
                  style={{ cursor: 'pointer' }}
                  src={uploadicon}
                  alt="Upload"
                  onClick={() => document.getElementById('profilePicInput').click()}
                />
                <input
                  type="file"
                  id="profilePicInput"
                  style={{ display: 'none' }}
                  onChange={handleProfilePicChange}
                />
              </div>
            </div>
          </div>
          <div className='form'>
            <div className='row'>
              <div className='col'>
                <div className="mb-3">
                  <label htmlFor="firstName" className="form-label">Prénom*</label>
                  <input
                    type="text"
                    className="form-control"
                    id="firstName"
                    name="firstName"
                    value={formData.firstName}
                    onChange={handleChange}
                  />
                  {errors.firstName && <div className="text-danger">{errors.firstName}</div>}
                </div>
              </div>
              <div className='col'>
                <div className="mb-3">
                  <label htmlFor="lastName" className="form-label">Nom*</label>
                  <input
                    type="text"
                    className="form-control"
                    id="lastName"
                    name="lastName"
                    value={formData.lastName}
                    onChange={handleChange}
                  />
                  {errors.lastName && <div className="text-danger">{errors.lastName}</div>}
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col'>
                <div className="mb-3">
                  <label htmlFor="email" className="form-label">Adresse mail*</label>
                  <input
                    type="email"
                    className="form-control"
                    id="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                  {errors.email && <div className="text-danger">{errors.email}</div>}
                </div>
              </div>
              <div className='col'>
                <div className="mb-3">
                  <label htmlFor="gender" className="form-label">Genre*</label>
                  <select
                    className="form-control"
                    id="gender"
                    name="gender"
                    value={formData.gender}
                    onChange={handleChange}
                  >
                    <option value="">Sélectionnez...</option>
                    <option value="Homme">Homme</option>
                    <option value="Femme">Femme</option>
                  </select>
                  {errors.gender && <div className="text-danger">{errors.gender}</div>}
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col'>
                <div className="mb-3">
                  <label htmlFor="birthDate" className="form-label">Date de naissance*</label>
                  <input
                    type="date"
                    className="form-control"
                    id="birthDate"
                    name="birthDate"
                    value={formData.birthDate}
                    onChange={handleChange}
                  />
                  {errors.birthDate && <div className="text-danger">{errors.birthDate}</div>}
                </div>
              </div>
              <div className='col'>
                <div className="mb-3">
                  <label htmlFor="birthCity" className="form-label">Ville de naissance*</label>
                  <input
                    type="text"
                    className="form-control"
                    id="birthCity"
                    name="birthCity"
                    value={formData.birthCity}
                    onChange={handleChange}
                  />
                  {errors.birthCity && <div className="text-danger">{errors.birthCity}</div>}
                </div>
              </div>
            </div>

            <div className='row'>
              <div className='col-lg-6'>
                <div className="mb-3">
                  <label htmlFor="phoneNumber" className="form-label">Numéro de téléphone*</label>
                  <PhoneInput
                    placeholder="Entrez votre numéro"
                    value={value}
                    className="form-control"
                    onChange={setValue}
                  />
                  {errors.phoneNumber && <div className="text-danger">{errors.phoneNumber}</div>}
                </div>
              </div>
            </div>

            <h3 className="mb-4">Adresse</h3>
            <div className="form">
              <div className="row">
                <div className="col-lg-2">
                  <div className="mb-3">
                    <label htmlFor="addressNumber" className="form-label">Numéro de rue*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="addressNumber"
                      name="addressNumber"
                      value={formData.addressNumber}
                      onChange={handleChange}
                    />
                    {errors.addressNumber && <div className="text-danger">{errors.addressNumber}</div>}
                  </div>
                </div>
                <div className="col-lg-10">
                  <div className="mb-3">
                    <label htmlFor="streetName" className="form-label">Nom de la route*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="streetName"
                      name="streetName"
                      value={formData.streetName}
                      onChange={handleChange}
                    />
                    {errors.streetName && <div className="text-danger">{errors.streetName}</div>}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <div className="mb-3">
                    <label htmlFor="additionalAddress" className="form-label">Adresse supplémentaire</label>
                    <input
                      type="text"
                      className="form-control"
                      id="additionalAddress"
                      name="additionalAddress"
                      value={formData.additionalAddress}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className='col'>
                  <div className="mb-3">
                    <label htmlFor="postalCode" className="form-label">Code Postal*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="postalCode"
                      name="postalCode"
                      value={formData.postalCode}
                      onChange={handleChange}
                    />
                    {errors.postalCode && <div className="text-danger">{errors.postalCode}</div>}
                  </div>
                </div>
              </div>
              <div className='row'>
                <div className='col'>
                  <div className="mb-3">
                    <label htmlFor="city" className="form-label">Ville*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="city"
                      name="city"
                      value={formData.city}
                      onChange={handleChange}
                    />
                    {errors.city && <div className="text-danger">{errors.city}</div>}
                  </div>
                </div>
                <div className='col'>
                  <div className="mb-3">
                    <label htmlFor="country" className="form-label">Pays*</label>
                    <input
                      type="text"
                      className="form-control"
                      id="country"
                      name="country"
                      value={formData.country}
                      onChange={handleChange}
                    />
                    {errors.country && <div className="text-danger">{errors.country}</div>}
                  </div>
                </div>
              </div>
            </div>

            <h3 className="mb-4">Ville où vous souhaitez conduire</h3>
            <div className="form">
              <div className='row'>
                <div className='col-lg-6'>
                  <div className="mb-3">
                    <label htmlFor="drivingCity" className="form-label">Elle peut être différente de votre adresse</label>
                    <input
                      type="text"
                      className="form-control"
                      id="drivingCity"
                      name="drivingCity"
                      value={formData.drivingCity}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="submit-btn">
              <button type="button" className="btn btn-secondary btn-main" onClick={handleSubmit} disabled={loading}>
                {loading ? 'Mise à jour...' : 'Mettre à jour'}
              </button>
            </div>

          </div>
        </div>
      </div>
    </section>
  );
}

export default Profile;
