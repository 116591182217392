import React from 'react'

function Terms_condition() {
  return (
    <section className='terms_condition'>
        <div className='container-fluid'>
            <div className='row'>
            <div className='col-lg-9 mx-auto'>
                <div className='cover-terms'>
                <h3>Conditions Générales d'Utilisation (CGU)</h3>
                <h5 className='mb-4'>
                Date de mise à jour : 24 september 2024
                </h5>
                <p>
                Bienvenue sur le site https://app.gmp-auto-ecole.com/. En accédant à ce site, vous acceptez les présentes Conditions Générales d'Utilisation (CGU). Veuillez les lire attentivement avant d'utiliser les services proposés par notre plateforme.
                </p>
                <p><strong>1. Objet</strong></p>
                <p>Les présentes CGU ont pour objet de définir les conditions d'accès et d'utilisation du site https://app.gmp-auto-ecole.com/. En utilisant ce site, vous reconnaissez avoir pris connaissance de ces conditions et les accepter sans réserve.</p>
                <p><strong>2. Accès au site</strong></p>
                <p>Le site est accessible gratuitement à tout utilisateur disposant d'une connexion Internet. Nous nous réservons le droit de suspendre, modifier ou limiter l'accès à certaines parties du site à tout moment, sans préavis.</p>
                <p><strong>3. Inscription et Compte utilisateur</strong></p>
                <p>Pour accéder à certains services, vous devez créer un compte utilisateur en fournissant des informations exactes et complètes. Vous êtes responsable de la confidentialité de vos identifiants et de toute activité effectuée sous votre compte.</p>
                <p><strong>4. Utilisation des services</strong></p>
                <p>Les services proposés par https://app.gmp-auto-ecole.com/ sont destinés à un usage personnel et non commercial. Vous vous engagez à ne pas utiliser le site à des fins illégales ou non autorisées. Toute utilisation frauduleuse ou abusive pourra entraîner la suspension ou la résiliation de votre compte.</p>
                <p><strong>5. Protection des données personnelles</strong></p>
                <p>Nous nous engageons à protéger vos données personnelles conformément à la législation en vigueur, notamment le Règlement Général sur la Protection des Données (RGPD). Pour plus d'informations sur la manière dont nous utilisons vos données, consultez notre Politique de confidentialité.</p>
                <p><strong>6. Propriété intellectuelle</strong></p>
                <p>Tous les contenus présents sur le site (textes, images, logos, etc.) sont la propriété exclusive de [Nom de l'auto-école]. Toute reproduction, distribution ou utilisation non autorisée de ces contenus est strictement interdite.</p>
                <p><strong>7. Responsabilité</strong></p>
                <p>Nous mettons tout en œuvre pour assurer la fiabilité et la mise à jour des informations présentes sur le site. Cependant, nous ne saurions être tenus responsables des éventuelles erreurs ou omissions, ni des dommages directs ou indirects résultant de l'utilisation du site.</p>
                <p><strong>8. Modification des CGU</strong></p>
                <p>Nous nous réservons le droit de modifier ces CGU à tout moment. Il est de votre responsabilité de consulter régulièrement cette page pour prendre connaissance des éventuelles modifications.</p>
                <p><strong>9. Contact</strong></p>
                <p>Pour toute question relative à ces CGU, vous pouvez nous contacter à l'adresse suivante :contact@gmp-auto-ecole.com</p>
                <div className='agree-btn'>
                <button type="button" class="btn btn-secondary btn-main">Accepter</button>
                </div>
                </div>
            </div>
            </div>
        </div>
    </section>
  )
}

export default Terms_condition