import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import checktick from "../images/check-tick.svg";
import listoption from "../images/list-option.svg";
import "./style.css";

function Boutique() {
  const navigate = useNavigate();

  const handleBuy = (planDetails) => {
    // Navigate to the cart and pass the selected plan's details including the ID
    navigate('/cart', { state: { planDetails } });
  };

  return (
    <section className='shop'>
      <div className='top-navs'>
        <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
          <Tab eventKey="home" title="Formules Intensive">
            <div className='wrap-cover'>
              <div className='tab-cover'>
                <h2>Formules Classiques</h2>
                <p>Nos formules intensives sont idéales pour ceux qui souhaitent obtenir leur permis de conduire rapidement tout en bénéficiant d'une formation complète et approfondie.</p>
              </div>
              <div className='row bg-white my-5'>
                <div className='col-lg-3'>
                  <div className='plan-first'>
                    <h2>799,00 €</h2>
                    <p>Automatique 13H</p>
                    <ul>
                      <li><span><img src={checktick} alt="tick" /></span>Les formalités administratives</li>
                      <li><span><img src={checktick} alt="tick" /></span>20 heures de conduite sur 10 jours</li>
                      <li><span><img src={checktick} alt="tick" /></span>Une heure de coaching mental</li>
                      <li><span><img src={checktick} alt="tick" /></span>Une date d'examen</li>
                      <li><span><img src={checktick} alt="tick" /></span>Demande de la carte du permis à l'ANTS</li>
                    </ul>
                    <div className='text-center'>
                      <button type="button" className="btn btn-secondary btn-main acheter" onClick={() => handleBuy({ id: 1, name: 'Automatique 13H', price: '799,00 €' })}>Acheter</button>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3'>
                  <div className='plan-first'>
                    <h2>1 125,00</h2>
                    <p>В ААС 20H</p>
                    <ul>
                      <li><span><img src={checktick} alt="tick" /></span>Les formalités administratives</li>
                      <li><span><img src={checktick} alt="tick" /></span>13 heures de conduite</li>
                      <li><span><img src={checktick} alt="tick" /></span>Une heure de coaching mental</li>
                      <li><span><img src={checktick} alt="tick" /></span>Une date d'examen</li>
                      <li><span><img src={checktick} alt="tick" /></span>Une collation booster</li>
                      <li><span><img src={checktick} alt="tick" /></span>Demande de la carte du permis à l'ANTS</li>
                    </ul>
                    <div className='text-center'>
                      <button type="button" className="btn btn-secondary btn-main acheter" onClick={() => handleBuy({ id: 2, name: 'В ААС 20H', price: '1 125,00' })}>Acheter</button>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3'>
                  <div className='plan-first'>
                    <h2>999,00 €</h2>
                    <p>Manuelle 20H</p>
                    <ul>
                      <li><span><img src={checktick} alt="tick" /></span>Les formalités administratives</li>
                      <li><span><img src={checktick} alt="tick" /></span>13 heures de conduite</li>
                      <li><span><img src={checktick} alt="tick" /></span>Une heure de coaching mental</li>
                      <li><span><img src={checktick} alt="tick" /></span>Une date d'examen</li>
                      <li><span><img src={checktick} alt="tick" /></span>Une collation booster</li>
                      <li><span><img src={checktick} alt="tick" /></span>Demande de la carte du permis à l'ANTS</li>
                    </ul>
                    <div className='text-center'>
                      <button type="button" className="btn btn-secondary btn-main acheter" onClick={() => handleBuy({ id: 3, name: 'Manuelle 20H', price: '999,00 €' })}>Acheter</button>
                    </div>
                  </div>
                </div>
                <div className='col-lg-3'>
                  <div className='plan-first populr'>
                    <div className='popular'>
                      <span>Le plus populaire</span>
                    </div>
                    <h2>1 999,00 €</h2>
                    <p>Illimitée</p>
                    <ul>
                      <li><span><img src={listoption} alt="tick" /></span>Les formalités administratives</li>
                      <li><span><img src={listoption} alt="tick" /></span>10 heures de conduite</li>
                      <li><span><img src={listoption} alt="tick" /></span>Une heure de coaching mental</li>
                      <li><span><img src={listoption} alt="tick" /></span>Une date d'examen</li>
                      <li><span><img src={listoption} alt="tick" /></span>Demande de la carte du permis à l'ANTS</li>
                    </ul>
                    <div className='text-center'>
                      <button type="button" className="btn btn-secondary" onClick={() => handleBuy({ id: 4, name: 'Illimitée', price: '1 999,00 €' })}>Acheter</button>
                    </div>
                  </div>
                </div>
              </div>
              <div className='support'>
                <h2>Besoin d'accompagnement ?</h2>
                <button type="button" className="btn btn-secondary btn-main">Être rappelé par un conseiller</button>
              </div>
            </div>
          </Tab>
          <Tab eventKey="profile" title="Formules Express">
      <div className='wrap-cover'>
          <div className='tab-cover'>
              <h2>Formules Accelerees</h2>
              <p>Nos formules intensives sont idéales pour ceux qui souhaitent obtenir leur permis de conduire rapidement tout en bénéficiant d'une formation complète et approfondie.</p>
          </div>
          <div className='row bg-white my-5 '>
              <div className='col-lg-3'>
                  <div className='plan-first'>
                    <h2>699,00 €</h2>
                    <p>Manuelle 3H</p>
                    <ul>
                      <li><span><img src={checktick }/></span>Les formalités administratives</li>
                      <li><span><img src={checktick }/></span>20 heures de conduite sur 10 jours</li>
                      <li><span><img src={checktick }/></span>Une heure de coaching mental</li>
                      <li><span><img src={checktick }/></span>Une date d'exame</li>
                      <li><span><img src={checktick }/></span>Demande de la carte du permis à l'ANTS</li>
                    </ul>
                    <div className='text-center'>
                    <button type="button" class="btn btn-secondary btn-main acheter" onClick={() => handleBuy({ id: 5, name: 'Manuelle 3H', price: '699,00 €' })}>Acheter</button>
                    </div>
                  </div>
              </div>
              <div className='col-lg-3'>
              <div className='plan-first'>
                    <h2>999,00 €</h2>
                    <p>Automatique 3H</p>
                    <ul>
                      <li><span><img src={checktick }/></span>Les formalités administrative</li>
                      <li><span><img src={checktick }/></span>13 heures de conduite</li>
                      <li><span><img src={checktick }/></span>Une heure de coaching mental</li>
                      <li><span><img src={checktick }/></span>Une date d'examen</li>
                      <li><span><img src={checktick }/></span>Une collation booster</li>
                      <li><span><img src={checktick }/></span>Demande de la carte du permis à l'ANTS</li>
                    </ul>
                    <div className='text-center'>
                    <Link to="/cart"><button type="button" class="btn btn-secondary btn-main acheter" onClick={() => handleBuy({ id: 6, name: 'Automatique 3H', price: '999,00 €' })}>Acheter</button></Link>
                    </div>
                  </div> 
            </div>
            <div className='col-lg-3'>
              <div className='plan-first'>
                    <h2>1 299,00 €</h2>
                    <p>Automatique 6H</p>
                    <ul>
                      <li><span><img src={checktick }/></span>Les formalités administrative</li>
                      <li><span><img src={checktick }/></span>13 heures de conduite</li>
                      <li><span><img src={checktick }/></span>Une heure de coaching mental</li>
                      <li><span><img src={checktick }/></span>Une date d'examen</li>
                      <li><span><img src={checktick }/></span>Une collation booster</li>
                      <li><span><img src={checktick }/></span>Demande de la carte du permis à l'ANTS</li>
                    </ul>
                    <div className='text-center'>
                    <Link to="/cart"><button type="button" class="btn btn-secondary btn-main acheter" onClick={() => handleBuy({ id: 7, name: 'Automatique 6H', price: '1 299,00 €' })}>Acheter</button></Link>
                    </div>
                  </div> 
            </div>
                  <div className='col-lg-3'>
                  <div className='plan-first populr'>
                    <div className='popular'>
                      <span>Le plus populaire</span>
                    </div>
                  <h2>1 699,00 €</h2>
                    <p>Manuelle 10H</p>
                    <ul>
                      <li><span><img src={listoption }/></span>Les formalités administrative</li>
                      <li><span><img src={listoption }/></span>10 heures de conduite</li>
                      <li><span><img src={listoption }/></span>Une heure de coaching mental</li>
                      <li><span><img src={listoption}/></span>Une date d'examen</li>
                      <li><span><img src={listoption }/></span>Demande de la carte du permis à l'ANTS</li>
                      
                    </ul>
                    <div className='text-center'>
                    <Link to="/cart"> <button type="button" class="btn btn-secondary" onClick={() => handleBuy({ id: 8, name: 'Manuelle 10H', price: '1 699,00 €' })}>Acheter</button></Link>
                   
                    </div>
                  </div>
            </div>
          </div>
          <div className='row bg-white my-5 '>
              <div className='col-lg-4'>
                  <div className='plan-first'>
                    <h2>2499,00 €</h2>
                    <p>Automatique 13H</p>
                    <ul>
                      <li><span><img src={checktick }/></span>Les formalités administratives</li>
                      <li><span><img src={checktick }/></span>20 heures de conduite sur 10 jours</li>
                      <li><span><img src={checktick }/></span>Une heure de coaching mental</li>
                      <li><span><img src={checktick }/></span>Une date d'exame</li>
                      <li><span><img src={checktick }/></span>Demande de la carte du permis à l'ANTS</li>
                    </ul>
                    <div className='text-center'>
                    <button type="button" class="btn btn-secondary btn-main acheter" onClick={() => handleBuy({ id: 9, name: 'Automatique 13H', price: '2499,00 €' })}>Acheter</button>
                    </div>
                  </div>
              </div>
              <div className='col-lg-4'>
              <div className='plan-first'>
                    <h2>2 999,00 €</h2>
                    <p>MAnuelle 20H 10 jours</p>
                    <ul>
                      <li><span><img src={checktick }/></span>Les formalités administrative</li>
                      <li><span><img src={checktick }/></span>13 heures de conduite</li>
                      <li><span><img src={checktick }/></span>Une heure de coaching mental</li>
                      <li><span><img src={checktick }/></span>Une date d'examen</li>
                      <li><span><img src={checktick }/></span>Une collation booster</li>
                      <li><span><img src={checktick }/></span>Demande de la carte du permis à l'ANTS</li>
                    </ul>
                    <div className='text-center'>
                    <Link to="/cart"><button type="button" class="btn btn-secondary btn-main acheter" onClick={() => handleBuy({ id: 10, name: 'MAnuelle 20H 10 jours', price: '2 999,00 €' })}>Acheter</button></Link>
                    </div>
                  </div> 
            </div>
            <div className='col-lg-4'>
              <div className='plan-first'>
                    <h2>3 199,00 €</h2>
                    <p>Manuelle 20H 5 jours</p>
                    <ul>
                      <li><span><img src={checktick }/></span>Les formalités administrative</li>
                      <li><span><img src={checktick }/></span>13 heures de conduite</li>
                      <li><span><img src={checktick }/></span>Une heure de coaching mental</li>
                      <li><span><img src={checktick }/></span>Une date d'examen</li>
                      <li><span><img src={checktick }/></span>Une collation booster</li>
                      <li><span><img src={checktick }/></span>Demande de la carte du permis à l'ANTS</li>
                    </ul>
                    <div className='text-center'>
                    <Link to="/cart"><button type="button" class="btn btn-secondary btn-main acheter" onClick={() => handleBuy({ id: 11, name: 'Manuelle 20H 5 jours', price: '3 199,00 €' })}>Acheter</button></Link>
                    </div>
                  </div> 
            </div>
                  
          </div>
          <div className='support'>
            <h2>Besoin d'accompagnement ?</h2>
            <button type="button" class="btn btn-secondary btn-main">Être rappelé par un conseiller</button>
          </div>
      </div>
      </Tab>
          {/* Add more Tabs if needed */}
        </Tabs>
      </div>
    </section>
  );
}

export default Boutique;
