import React, { useEffect, useState } from 'react';
import "../style.css"
import { Link } from 'react-router-dom'
import ps from '../../images/ps.png'
import cheq from '../../images/cheq.png'
import veriin from '../../images/veriin.png'
import axiosInstance from "../../utils/http/config/axios";
import { toast } from "react-toastify";
import CourseCard from './partials/CourseCard';


function Livret() {
  const [coursesProgress, setCoursesProgress] = useState([]); // State to store courses progress
  const [error, setError] = useState(null); // State to manage error

  // Function to fetch courses with student progress
  const getCourses = async () => {
    setError(""); // Clear any previous error

    try {
      const response = await axiosInstance.post("api/student/cours/fetch-courses-progress", {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      // Update the state with the fetched data
      setCoursesProgress(response.data.coursesProgress);

    } catch (error) {
      setError(error.response?.data?.error || "Une erreur inattendue s'est produite. Veuillez réessayer.");
      // Display error using toast or any other method
      toast.error(error.response?.data?.error || "Erreur inattendue. Veuillez réessayer.", {
        position: toast.TOP_RIGHT,
        autoClose: 3000,
      });
    }
  };

  useEffect(() => {
    getCourses(); // Call the function on component mount
  }, []);
  return (
    <>
      {/* for unsubscribed user */}
      {/* <section className='livret'>
       <div className='container'>
         <div className='wrapping'>
            <img src={nolivret} />
            <p>Vous devez acheter un plan pour accéder au livret</p>
            <Link to="/boutique" ><button type="button" class="btn btn-secondary btn-main">Acheter maintenant</button></Link>
         </div>
       </div>
   </section> */}
      <section className='booklet'>
        <div className='container'>
          <h4>Préparez-vous aux questions de l'examen</h4>
          <div className='row mt-4'>
            <div className='col-lg-4'>
              <Link to="/premierssecours">
                <div className='premier d-flex align-items-center'>
                  <img src={ps} />
                  <div className='content-top'>
                    <h5>Premiers
                      Secours
                    </h5>
                  </div>
                </div></Link>
            </div>
            <div className='col-lg-4'>
              <Link to="/check-external">
                <div className='premier d-flex align-items-center'>
                  <img src={cheq} />
                  <div className='content-top'>
                    <h5>
                      Chèques externes
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className='col-lg-4'>
              <Link to="/check-internal"><div className='premier d-flex align-items-center'>
                <img src={veriin} />
                <div className='content-top'>
                  <h5>
                    Vérifications internes
                  </h5>
                </div>
              </div></Link>
            </div>
          </div>
          <div className='row'>
            {/* <div className='col-lg-6'>
              <Link to="/master"><div className='masters-guides'>
                <div className='d-flex align-items-start justify-content-between'>
                  <div className='count_no'>
                    <span>01</span>
                  </div>
                  <h4>Maîtriser la conduite du véhicule dans un trafic faible ou nul</h4>
                  <img src={msico} />
                </div>
                <div class="progress">
                  <div class="progress-bar" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100">25%</div>
                </div>
              </div></Link>
            </div> */}

            {coursesProgress.length > 0 ? (
              coursesProgress.map((course, index) => (
                <CourseCard key={course.id} course={course} index={index} />
              ))
            ) : (
              <div className="col-12"> {/* Fallback message when no courses are available */}
                <p>Aucun cours disponible.</p>
              </div>
            )}



          </div>
        </div>
      </section>
    </>

  )
}

export default Livret