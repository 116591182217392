import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.css';
import psd from '../images/psd.svg';
import axiosInstance from '../utils/http/config/axios'; 

function ForgotPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [token, setToken] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');

  const navigate = useNavigate();

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const tokenFromUrl = query.get('token');
    if (tokenFromUrl) {
      setToken(tokenFromUrl);
    } else {
      setError('Token invalide.');
    }
  }, []);

  const handleResetPassword = async () => {
    if (!token) {
      setError('Token invalide ou manquant.');
      return;
    }
  
    if (!newPassword || !confirmPassword) {
      setError('Tous les champs doivent être remplis.');
      return;
    }
  
    if (newPassword !== confirmPassword) {
      setError('Les mots de passe ne correspondent pas.');
      return;
    }
  
    setIsSubmitting(true);
    setError('');
    setSuccess('');
  
    try {
      const response = await axiosInstance.put(`/api/students/reset-password?token=${token}`, {
        newPassword
      });
      setSuccess(response.data.message);
  
      setTimeout(() => {
        navigate('/');
      }, 2000);
  
    } catch (error) {
      if (error.response) {
        const statusCode = error.response.status;
  
        switch (statusCode) {
          case 400:
            setError("Requête invalide. Vérifiez les informations fournies.");
            break;
          case 401:
            setError("Token invalide ou mot de passe incorrect.");
            break;
          case 500:
            setError("Erreur interne du serveur. Veuillez réessayer plus tard.");
            break;
          default:
            setError(`Erreur inattendue : ${error.response.data.message || 'Veuillez réessayer.'}`);
        }
      } else if (error.request) {
        setError("Aucune réponse du serveur. Vérifiez votre connexion internet.");
      } else {
        setError(`Erreur lors de la demande : ${error.message}`);
      }
    } finally {
      setIsSubmitting(false);
    }
  };  

  return (
    <section className='verify_email'>
      <div className='container-fluid'>
        <div className='row'>
          <div className='col-lg-5 mx-auto'>
            <div className='wrapping d-flex flex-column justify-content-center align-items-center'>
              <img src={psd} alt="Réinitialiser le mot de passe" />
              <h3>Réinitialiser votre mot de passe</h3>
              <p className='text-center'>
                Nous avons reçu une demande de réinitialisation du mot de passe de votre compte gir . Veuillez entrer votre nouveau mot de passe ci-dessous :
              </p>
              <div className='row'>
                <div className='col'>
                  <div className="mb-3">
                    <label htmlFor="newPassword" className="form-label">Nouveau mot de passe</label>
                    <input
                      type="password"
                      className="form-control"
                      id="newPassword"
                      name="newPassword"
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    />
                  </div>
                </div>
                <div className='col'>
                  <div className="mb-3">
                    <label htmlFor="confirmPassword" className="form-label">Confirmer le mot de passe</label>
                    <input
                      type="password"
                      className="form-control"
                      id="confirmPassword"
                      name="confirmPassword"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <button
                type="button"
                className="btn btn-secondary btn-main mb-4 email-recover mt-4"
                onClick={handleResetPassword}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Réinitialisation en cours...' : 'Réinitialiser votre mot de passe'}
              </button>

              {success && <p className="text-success">{success}</p>}
              {error && <p className="text-danger">{error}</p>}

              <button
                type="button"
                className="btn btn-secondary btn-main mt-3" 
                onClick={() => navigate('/')}
              >
                Se connecter
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default ForgotPassword;
