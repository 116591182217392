import React from "react";

function MessageList({ messages }) {
  return (
    <div className="message-list">
      {messages.map((message) => (
        <div key={message.id} className="message">
          <div className="messages-chat">
          <span>{message.text}</span>
            </div>
          <div className="timestamp">{message.timestamp}</div>
        </div>
      ))}
    </div>
  );
}

export default MessageList;