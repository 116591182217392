import React, { useState } from 'react';
import './style.css';
import verifyemail from '../images/verify-email.svg';
import { Link, useLocation } from 'react-router-dom';
import axiosInstance from '../utils/http/config/axios'; 

function VerifyEmail() {
  const location = useLocation();
  const userEmail = location.state?.userEmail || 'votre email';
  const [isResending, setIsResending] = useState(false);
  const [resendError, setResendError] = useState("");
  const [resendSuccess, setResendSuccess] = useState("");

  const handleResendEmail = async () => {
    setIsResending(true);
    setResendError("");
    setResendSuccess("");

    try {
      const response = await axiosInstance.post('/api/students/resend-verification-email', { email: userEmail });
      setResendSuccess(response.data.message);
    } catch (error) {
      setResendError("Échec de l'envoi de l'email de vérification : " + error.message);
    } finally {
      setIsResending(false);
    }
  };

  return (
    <section className="verify_email">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-5 mx-auto">
            <div className="wrapping d-flex flex-column justify-content-center align-items-center">
              <img src={verifyemail} alt="Verify Email" />
              <h3>Vérifiez votre email</h3>
              <p className="text-center">
                L'email a été envoyé à votre adresse e-mail '{userEmail}' avec un lien pour vérifier un compte.
              </p>
              <button
                type="button"
                className="btn btn-secondary btn-main mb-4 email-recover"
                onClick={handleResendEmail}
                disabled={isResending}
              >
                {isResending ? 'Envoi en cours...' : 'Renvoyer l\'e-mail'}
              </button>
              {resendSuccess && <p className="text-success">{resendSuccess}</p>}
              {resendError && <p className="text-danger">{resendError}</p>}
              <div>
                <p>
                  Si vous n'avez reçu aucun e-mail, <Link to="#" onClick={handleResendEmail}>vous pouvez le renvoyer</Link>.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default VerifyEmail;
