import { useCallback, useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import current from "../images/current.svg";
import dashboardicon from "../images/dashboard-icon.svg";
import searchfilters from "../images/search-filters.svg";
import "./style.css";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { AdvancedMarker, APIProvider, Map } from "@vis.gl/react-google-maps";
import { addDays, format } from "date-fns";
import { toast } from "react-toastify";
import MarkerWithInfoWindow from "../components/MarkerWithInfoWindow";
import axiosInstance from "../utils/http/config/axios";
import TimeSlots from "./components/time-slots";
import { Link, useNavigate } from "react-router-dom";
import reservationbooked from "../images/reservation-booked.svg";

function Dashboard() {
  const navigate = useNavigate();
  const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;
  const [show, setShow] = useState(false); // Controls modal visibility
  const [nearInstructors, setNearInstructors] = useState([]);
  const [nearInstructorsAvailability, setNearInstructorsAvailability] = useState([]);
  const [location, setLocation] = useState(null);
  const handleClose = () => setShow(false); // Close modal
  const [userName, setUserName] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedInstructor, setSelectedInstructor] = useState(null);
  const [loadingInstructorsAvailability, setLoadingInstructorsAvailability] = useState(false);
  const [loadingNearInstructors, setLoadingNearInstructors] = useState(false);
  const [reserving, setReserving] = useState(false);
  const [joinDate, setJoinDate] = useState("");

  const handleRedirect = () => {
    navigate("/subscribed-dashboard"); // Redirect to subscribed-dashboard
  };

  useEffect(() => {
    const storedDate = localStorage.getItem("createdAt");

    if (storedDate) {
      const [day, month, year] = storedDate.split("/");
      const dateObj = new Date(`${year}-${month}-${day}`);

      const formattedDate = new Intl.DateTimeFormat("fr-FR", {
        year: "numeric",
        month: "long",
        day: "numeric",
      }).format(dateObj);

      setJoinDate(formattedDate);
    } else {
      console.log("No stored date found.");
      setJoinDate("No date available");
    }
  }, []);

  const getPostalCode = async (address) => {
    try {
      const response = await axiosInstance.get(`https://maps.googleapis.com/maps/api/geocode/json`, {
        params: {
          address: address,
          key: process.env.REACT_APP_GOOGLE_MAPS_KEY,
        },
      });

      if (response.data.results.length > 0) {
        const postalCodeComponent = response.data.results[0].address_components.find((component) =>
          component.types.includes("postal_code")
        );

        if (postalCodeComponent) {
          const postalCode = postalCodeComponent.long_name;
          setPostalCode(postalCode);
        } else {
          console.warn("Postal code not found in address components.");
          setPostalCode("");
        }
      } else {
        console.warn("No results found for the address.");
        setPostalCode("");
      }
    } catch (error) {
      console.error("Error retrieving postal code:", error);
      setPostalCode("");
    }
  };

  useEffect(() => {
    if (postalCode) {
      console.log("Postal Code:", postalCode); // Log the postal code in useEffect
    }
  }, [postalCode]);

  // Get nearby instructors based on location
  useEffect(() => {
    if (location) {
      setLoadingNearInstructors(true);
      const getNearByInstructors = async () => {
        try {
          const response = await axiosInstance.get(`/api/instructors/near-by`, {
            params: {
              location: `${location.lat},${location.long}`,
              radius: 30000000,
            },
          });
          setNearInstructors(response.data.data);
        } catch (error) {
          toast.error("Un problème est survenu lors du chargement des instructeurs, veuillez réessayer plus tard.");
        } finally {
          setLoadingNearInstructors(false);
        }
      };
      getNearByInstructors();
    }
  }, [location]);

  // Get instructor availability
  useEffect(() => {
    if (nearInstructors && nearInstructors.length) {
      setLoadingInstructorsAvailability(true);
      const getNearByInstructorsAvailability = async () => {
        try {
          const response = await axiosInstance.get(`/api/instructors/availability`, {
            params: {
              ids: nearInstructors.map((instructor) => instructor.instructorId).join(","),
              startDate: format(currentDate, "yyyy-MM-dd"),
              endDate: format(addDays(currentDate, 7), "yyyy-MM-dd"),
            },
          });
          setNearInstructorsAvailability(response.data.data);
        } catch (error) {
          console.error("Erreur lors de la récupération des disponibilités:", error);
          toast.error("Un problème est survenu lors du chargement des disponibilités.");
        } finally {
          setLoadingInstructorsAvailability(false);
        }
      };
      getNearByInstructorsAvailability();
    }
  }, [nearInstructors, currentDate]);

  // Get student location using geolocation API
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            lat: position.coords.latitude,
            long: position.coords.longitude,
          });
        },
        (err) => {
          console.log("Impossible d'obtenir votre emplacement.");
        }
      );
    }

    // Retrieve user information from localStorage
    const firstName = localStorage.getItem("firstName");
    const lastName = localStorage.getItem("lastName");
    if (firstName && lastName) {
      setUserName(`${firstName} ${lastName}`);
    }
  }, []);

  const backToList = () => {
    setSelectedInstructor(null);
  };

  const handleMarkerClick = (instructor) => {
    setSelectedInstructor(instructor);
  };

  const handleWindowClose = useCallback(() => {}, []);

  const reserveSlots = async (selected) => {
    if (selected && selected.length) {
      setReserving(true);
      try {
        const response = await axiosInstance.post(`/api/students/book`, {
          availabilityIds: selected.map((item) => item.id),
          lessonDetails: { duration: selected.length },
        });
  
        // Check if success is false and display an error message accordingly
        if (response.data.success === false) {
          toast.error(response.data.message || "Une erreur est survenue. Veuillez réessayer.");
        } else {
          // Success case
          toast.success("Réservation effectuée avec succès !");
          setShow(true); // Show modal only after success
        }
      } catch (error) {
        const errorMsg = error.response?.data?.error || "Une erreur est survenue. Veuillez réessayer plus tard.";
        toast.error(errorMsg);
      } finally {
        setReserving(false);
      }
    }  
  };

  return (
    <section className="dashboard">
      <div className="container-fluid">
        <div className=" mt-lg-5">
          <div className="row dashboard-wrap">
            <div className="col-lg-7">
              <div className="content-wrap">
                <h2>
                  Bienvenue! <br></br><span>{userName}</span>
                </h2>
                <div className="joining-date-student my-3">
                  <span><strong>Date d'adhésion: </strong><p className="my-0">{joinDate}</p></span>
                </div>
                <p>Explorez nos cours d'école de conduite</p>
              </div>
            </div>
            <div className="col-lg-5">
              <div className="text-center">
                <img src={dashboardicon} alt="Dashboard icon" />
              </div>
            </div>
          </div>
        </div>
        <div className="heures">
          <div className="content">
            <h3>Commencez à apprendre aujourd'hui</h3>
            <Link to="/boutique">
              <button type="button" className="btn btn-light">
                Voir notre offre
              </button>
            </Link>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-7 stat-cont">
            <div className="stations">
              <Accordion>
                {!loadingNearInstructors && !loadingInstructorsAvailability ? (
                  nearInstructors && nearInstructors.length ? (
                    nearInstructors.map((instructor) => {
                      // Ensure availability is sorted by time within each day
                      const instructorAvailability = nearInstructorsAvailability[instructor.instructorId];

                      const sortedSlotsByDay = instructorAvailability
                        ? Object.keys(instructorAvailability).reduce((acc, date) => {
                            const sortedSlots = instructorAvailability[date].sort((a, b) => a.from.localeCompare(b.from));
                            acc[date] = sortedSlots;
                            return acc;
                          }, {})
                        : {};

                      return (
                        <Accordion.Item
                          eventKey={instructor.instructorId}
                          key={instructor.instructorId}
                          hidden={selectedInstructor && selectedInstructor.instructorId !== instructor.instructorId}
                        >
                          <Accordion.Header>
                            {instructor?.location?.address}
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="distance">
                              <p>
                                {instructor?.location?.address?.split(",")[0]} <br />
                                {instructor?.location?.address?.split(",")[1]?.trim()}
                              </p>

                              {postalCode && (
                                <p>
                                  Code postal : {postalCode}
                                </p>
                              )}

                              <strong>
                                {instructor?.location?.distance < 10000
                                  ? `${Math.floor(instructor?.location?.distance)}m`
                                  : `${(instructor?.location?.distance / 1000).toFixed(1)} km`}
                              </strong>
                            </div>

                            {!loadingInstructorsAvailability ? (
                              <TimeSlots
                                instructor={instructor}
                                instructorAvailability={sortedSlotsByDay} // Pass the sorted availability
                                reserveSlots={(selectedTimeSlots) => reserveSlots(selectedTimeSlots)}
                                reserving={reserving}
                              />
                            ) : (
                              <div className="d-flex justify-content-center align-items-center h-100">
                                <Spinner animation="grow"></Spinner>
                                <p>Chargement des disponibilités...</p>
                              </div>
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })
                  ) : null
                ) : (
                  <div className="d-flex justify-content-center align-items-center h-100">
                    <Spinner animation="grow"></Spinner>
                    <p>Chargement des disponibilités...</p>
                  </div>
                )}

                {/* Modal to show reservation success */}
                <Modal show={show} onHide={handleClose} className="reser-book">
                  <Modal.Header closeButton className="border-0"></Modal.Header>
                  <Modal.Body>
                    <div className="transation-successful p-2">
                      <h2>Toutes nos félicitations</h2>
                      <div className="my-4">
                        <img src={reservationbooked} alt="reservation booked" />
                      </div>
                      <p>Vos créneaux ont été réservés avec succès</p>
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" className="btn-main text-white" onClick={handleRedirect}>
                      Accueil
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Accordion>
              {selectedInstructor && (
                <div className="mt-5 d-flex justify-content-center">
                  <button className="btn btn-outline-main" onClick={() => backToList()}>
                    Retour
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-12 col-lg-5 ">
            <div className="my-4 map-mobile">
              {location ? (
                <APIProvider apiKey={googleMapsKey}>
                  <Map
                    mapId={process.env.REACT_APP_MAP_ID}
                    style={{
                      width: "100%",
                      height: "100vh",
                      borderRadius: "1.5rem",
                      overflow: "hidden",
                      border: "1px solid #8d8b8ba8",
                    }}
                    defaultCenter={{ lat: location?.lat, lng: location?.long }}
                    defaultZoom={10}
                    gestureHandling={"greedy"}
                    disableDefaultUI={true}
                  />

                  <AdvancedMarker
                    position={{ lat: location.lat, lng: location.long }}
                    title={"You're current position"}
                  >
                    <div
                      style={{
                        width: 60,
                        height: 60,
                        position: "absolute",
                        top: 0,
                        backgroundColor: "#f87e4b52",
                        borderRadius: "50%",
                        transform: "translate(-50%, -50%)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#C43CAB",
                          width: 16,
                          height: 16,
                          borderRadius: "50%",
                          border: "2px solid white",
                        }}
                      ></div>
                    </div>
                  </AdvancedMarker>

                  {nearInstructors?.map((instructor) => {
                    return (
                      <MarkerWithInfoWindow
                        position={{
                          lat: instructor.location.lat,
                          lng: instructor.location.long,
                        }}
                        handleMarkerClick={() => handleMarkerClick(instructor)}
                        selectedItem={selectedInstructor}
                        key={instructor.instructorId}
                        id={instructor.instructorId}
                        handleWindowClose={handleWindowClose}
                      />
                    );
                  })}
                </APIProvider>
              ) : (
                <p>Chargement de la carte...</p>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Dashboard;
