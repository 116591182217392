import React, { useEffect, useState } from 'react';
import staer from '../images/staer.png';
import newnoti from '../images/new-noti.svg';
import calcnoti from '../images/calcnoti.svg';
import './style.css';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/Modal';
import Accordion from 'react-bootstrap/Accordion';
import Star from './Star';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

// Helper function to fetch notifications
const fetchNotifications = async (token) => {
  try {
    const response = await fetch('/api/students/notifications', {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('La réponse réseau n\'était pas correcte');
    }

    const data = await response.json();
    return data.notifications;
  } catch (error) {
    console.error('Erreur lors de la récupération des notifications :', error);
    return [];
  }
};

// Function to submit instructor review
const submitReview = async (token, instructorId, reviewData) => {
  try {
    const response = await fetch(`/api/students/instructors/${instructorId}/reviews`, {
      method: 'POST',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(reviewData),
    });

    if (!response.ok) {
      throw new Error('Échec lors de la soumission de la revue');
    }

    return await response.json();
  } catch (error) {
    console.error('Erreur lors de la soumission de la revue :', error);
  }
};

// Define notification icons based on type
const notificationIcons = {
  reservation: calcnoti,
  notification: staer,
  rappel: newnoti,
  alerte: newnoti,
  paiement: calcnoti,
  'leçon terminée': staer,
};

function Notifications() {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedNotification, setSelectedNotification] = useState(null); 
  const [ratings, setRatings] = useState({}); 
  const [comments, setComments] = useState({}); 
  const navigate = useNavigate();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const token = localStorage.getItem('token');

  const openModal = (noti) => {
    setSelectedNotification(noti);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleRatingClick = (reviewTypeId, ratingValue) => {
    setRatings(prev => ({
      ...prev,
      [reviewTypeId]: ratingValue,
    }));
  };

  const handleCommentChange = (reviewTypeId, value) => {
    setComments(prev => ({
      ...prev,
      [reviewTypeId]: value,
    }));
  };

  const handleReviewSubmit = async () => {
    if (!selectedNotification?.instructorId) {
      console.error('No instructorId associated with this notification');
      return;
    }

    const reviewData = {
      ratings: [
        { reviewTypeId: 1, ratingValue: ratings[1] || 0, comment: comments[1] || '' },
        { reviewTypeId: 2, ratingValue: ratings[2] || 0, comment: comments[2] || '' },
        { reviewTypeId: 3, ratingValue: ratings[3] || 0, comment: comments[3] || '' }
      ],
    };

    await submitReview(token, selectedNotification.instructorId, reviewData);
    closeModal();
  };

  useEffect(() => {
    if (token) {
      fetchNotifications(token)
        .then(data => {
          setNotifications(data);
          setLoading(false);
        })
        .catch(err => {
          setError('Échec lors de la récupération des notifications');
          setLoading(false);
        });
    } else {
      setError('Aucun jeton d\'authentification trouvé');
      setLoading(false);
    }
  }, [token]);

  const getUrlByType = (type) => {
    switch (type) {
      case 'reservation':
        return '/myreservation';
      case 'notification':
        return '/notifications';
      case 'rappel':
        return '/rappel';
      case 'alerte':
        return '/alertes';
      case 'paiement':
        return '/transaction';
      case 'leçon terminée':
        return null; 
      default:
        return '';
    }
  };

  // Function to handle notification click
  const handleNotificationClick = (noti) => {
    const url = getUrlByType(noti.type);
    if (url) {
      navigate(url);
    } else if (noti.type === 'leçon terminée') {
      setSelectedNotification(noti); 
      openModal(noti);
    }
  };

  if (loading) {
    return <p>Chargement...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  const goBack = () => {
    navigate(-1);
  };

  return (
    <section className='notifications'>
      <Modal show={isModalOpen} onHide={closeModal} className='inst-feedback'>
        <Modal.Header closeButton>
          <Modal.Title>Commentaires de l'instructeur</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className='inst_rating'>
            <Accordion defaultActiveKey="0">
              <Accordion.Item eventKey="0">
                <Accordion.Header>1. Qualité de l'enseignement</Accordion.Header>
                <Accordion.Body>
                  <div>
                    {[1, 2, 3, 4, 5].map((star, index) => (
                      <Star
                        key={index}
                        selected={index < (ratings[1] || 0)}
                        onClick={() => handleRatingClick(1, index + 1)}
                      />
                    ))}
                  </div>
                  <Form>
                    <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlTextarea1">
                      <Form.Control 
                        as="textarea" 
                        rows={3} 
                        placeholder="Commentez ici..." 
                        value={comments[1] || ''} 
                        onChange={(e) => handleCommentChange(1, e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1">
                <Accordion.Header>2. Attitude et comportement du moniteur</Accordion.Header>
                <Accordion.Body>
                  <div>
                    {[1, 2, 3, 4, 5].map((star, index) => (
                      <Star
                        key={index}
                        selected={index < (ratings[2] || 0)}
                        onClick={() => handleRatingClick(2, index + 1)}
                      />
                    ))}
                  </div>
                  <Form>
                    <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlTextarea2">
                      <Form.Control 
                        as="textarea" 
                        rows={3} 
                        placeholder="Commentez ici..." 
                        value={comments[2] || ''} 
                        onChange={(e) => handleCommentChange(2, e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2">
                <Accordion.Header>3. Satisfaction globale de la leçon</Accordion.Header>
                <Accordion.Body>
                  <div>
                    {[1, 2, 3, 4, 5].map((star, index) => (
                      <Star
                        key={index}
                        selected={index < (ratings[3] || 0)}
                        onClick={() => handleRatingClick(3, index + 1)}
                      />
                    ))}
                  </div>
                  <Form>
                    <Form.Group className="mb-3 mt-2" controlId="exampleForm.ControlTextarea3">
                      <Form.Control 
                        as="textarea" 
                        rows={3} 
                        placeholder="Commentez ici..." 
                        value={comments[3] || ''} 
                        onChange={(e) => handleCommentChange(3, e.target.value)}
                      />
                    </Form.Group>
                  </Form>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeModal}>
            Fermer
          </Button>
          <Button variant="primary" onClick={handleReviewSubmit}>
            Sauvegarder
          </Button>
        </Modal.Footer>
      </Modal>
      <div className='container'>
        <div className='wrapping'>
          <div className='d-flex justify-content-end'>
            <button type="button" onClick={goBack} className="btn btn-secondary btn-main mb-3">
              Retour
            </button>
          </div>
          <h5>Nouvelles notifications</h5>
          <hr />
          {notifications.length === 0 ? (
            <p>Aucune notification disponible</p>
          ) : (
            notifications.map((noti, index) => (
              <div 
                key={index} 
                className='noti-recent' 
                onClick={() => handleNotificationClick(noti)} 
                style={{ cursor: 'pointer' }}
              >
                <div className='d-flex'>
                  <img src={notificationIcons[noti.type] || staer} alt={noti.type} />
                  <div className='ps-3'>
                    <p className='mb-0'><strong>{noti.title}</strong> {noti.message}</p>
                    <div className='date-time'>
                      <span>{new Date(noti.createdAt).toLocaleDateString()}</span>
                      <span>{new Date(noti.createdAt).toLocaleTimeString()}</span>
                    </div>
                  </div>
                </div>
                {noti.isNew && (
                  <div className='new-noti'>
                    <img src={newnoti} alt="Nouvelle notification" />
                  </div>
                )}
                <hr />
              </div>
            ))
          )}
        </div>
      </div>
    </section>
  );
}

export default Notifications;
