import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from "../../utils/http/config/axios";
import "../style.css";

function ShowCours() {

  const navigate = useNavigate();
  const [skill, setSkill] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userData, setUserData] = useState(null);
  const { id } = useParams();
  // State to track ratings for each sub-skill
  const [ratings, setRatings] = useState({});
  const [error, setError] = useState(null); // State to manage error

  const goBack = () => {
    navigate(-1);
  };


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token found");
        }

        const response = await axiosInstance.get(
          "/api/students/profile",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        setUserData(response.data.student);
        console.log(response.data.student);
        fetchSkill(response.data.student.id);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError("Session expired. Please log in again.");
          localStorage.removeItem("token");
          navigate("/");
        } else {
          setError("An error occurred while fetching data. Please try again.");
        }
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, [id]);

  const fetchSkill = async (studentId) => {
    try {
      const response = await axiosInstance.post(
        "/api/student/skills/get-skill-with-progress",
        { skill_id: id, studentId },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );

      const skillData = response.data.skill;

      // Ensure the skill data and subSkills are defined before setting them in state
      if (skillData && skillData.subSkills) {
        setSkill(skillData);

        // Initialize ratings state safely
        const initialRatings = skillData.subSkills.reduce((acc, subSkill) => {
          acc[subSkill.id] = subSkill.rating;
          return acc;
        }, {});
        setRatings(initialRatings);
      } else {
        console.error('Skill data or subSkills are undefined.');
      }
    } catch (error) {
      console.error('Error fetching course data:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Chargement...</div>;
  }

  if (!skill) {
    return <div>Aucune compétence disponible.</div>;
  }

  return (
    <section className='apprehend'>
      <div className='container'>
        <h4>{skill.name}</h4>
        <div className='back-btn mt-3'>
          <button type="button" onClick={goBack} className="btn btn-secondary btn-main">
            Retour
          </button>
        </div>
        <div className='text-wraps'>
          <div className='master-title'>
            <h4>{skill.name}</h4>
          </div>
          <div className='course-details'>
            {skill.subSkills.length > 0 ? (
              skill.subSkills.map((subSkill, index) => (
                <div key={index} className="form-check d-flex align-items-center mb-2">
                  <div className="radio-group d-flex align-items-center gap-2">
                    <input
                      type="radio"
                      className="form-check-skill red-border me-2"
                      name={`skill-${subSkill.id}`}
                      id={`skill-${subSkill.id}-red`}
                      value="red"
                      checked={ratings[subSkill.id] === "red"}
                      disabled
                    />

                    <input
                      type="radio"
                      className="form-check-skill orange-border me-2"
                      name={`skill-${subSkill.id}`}
                      id={`skill-${subSkill.id}-orange`}
                      value="orange"
                      checked={ratings[subSkill.id] === "orange"}
                      disabled
                    />

                    <input
                      type="radio"
                      className="form-check-skill green-border me-2"
                      name={`skill-${subSkill.id}`}
                      id={`skill-${subSkill.id}-green`}
                      value="green"
                      checked={ratings[subSkill.id] === "green"}
                      disabled
                    />
                  </div>
                  <label className="form-check-label ms-2">
                    {subSkill.name}
                  </label>
                </div>
              ))
            ) : (
              <div className="col-12">
                <p>Aucun compétences disponible.</p>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default ShowCours;
