import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function CheckoutForm({ planDetails, onSuccess, onError }) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [erreur, setErreur] = useState(null);
  const [succes, setSucces] = useState(null);
  const [chargement, setChargement] = useState(false);

  useEffect(() => {
    console.log('Détails du plan:', planDetails);
  }, [planDetails]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      setErreur("Stripe n'est pas encore chargé.");
      return;
    }

    if (!planDetails?.id) {
      setErreur("Aucun plan valide sélectionné. Veuillez retourner en arrière et sélectionner un plan.");
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    setChargement(true);

    try {
      const { error: stripeErreur, token } = await stripe.createToken(cardElement);

      if (stripeErreur) {
        setErreur(stripeErreur.message);
        setChargement(false);
        return;
      }

      const subscriptionTypeId = planDetails.id;
      const authToken = localStorage.getItem('token');

      const response = await fetch('/api/students/achat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`
        },
        body: JSON.stringify({
          subscriptionTypeId: subscriptionTypeId,
          paymentToken: token.id,
        }),
      });

      const result = await response.json();

      if (result.success) {
        localStorage.setItem('hasSubscription', 'true');
        setSucces('Paiement réussi ! Redirection...');
        onSuccess && onSuccess();
        navigate('/transaction-successfull');
        toast.success("Paiement réussi !", {
          position: toast.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      console.error('Erreur de paiement:', error);
      setErreur('Une erreur est survenue lors du traitement du paiement.');
      onError && onError(error.message);
      toast.error("Erreur lors du paiement. Veuillez réessayer.", {
        position: toast.TOP_RIGHT,
        autoClose: 3000,
      });
    } finally {
      setChargement(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="formulaire">
      <div className='row'>
        <div className="mb-3 col">
          <label htmlFor="card-number-element" className="form-label">Numéro de carte*</label>
          <div id="card-number-element" className="form-control">
            <CardNumberElement options={{ style: { base: { fontSize: '16px' } } }} />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className="mb-3 col">
          <label htmlFor="expiry-element" className="form-label">Date d'expiration*</label>
          <div id="expiry-element" className="form-control">
            <CardExpiryElement options={{ style: { base: { fontSize: '16px' } } }} />
          </div>
        </div>
        <div className="mb-3 col-lg-6">
          <label htmlFor="cvc-element" className="form-label">CVC*</label>
          <div id="cvc-element" className="form-control">
            <CardCvcElement options={{ style: { base: { fontSize: '16px' } } }} />
          </div>
        </div>
      </div>
      <button type="submit" className="btn btn-secondary btn-main" disabled={!stripe || chargement}>
        {chargement ? 'Traitement en cours...' : 'Payer'}
      </button>
      
      <div className="messages-feedback">
        {erreur && <p className="text-danger">{erreur}</p>}
        {succes && <p className="text-success">{succes}</p>}
      </div>
    </form>
  );
}

export default CheckoutForm;
