import React, { useEffect, useState } from 'react';
import "../style.css"
import { Link, useNavigate } from 'react-router-dom'
import ps from '../../images/ps.png'
import cheq from '../../images/cheq.png'
import veriin from '../../images/veriin.png'
import axiosInstance from "../../utils/http/config/axios";
import { toast } from "react-toastify";
import CourseCard from './partials/CourseCard';


function Livret() {
  const [coursesProgress, setCoursesProgress] = useState([]); // State to store courses progress
  const [skillsWithProgress, setSkillsWithProgress] = useState([]); // State to store skills with progress
  const [error, setError] = useState(null); // State to manage error
  const [loading, setLoading] = useState(true); // State to manage loading
  const navigate = useNavigate();

  // Function to fetch courses with student progress
  const getCourses = async (studentId) => {
    setLoading(true); // Start loading
    setError(""); // Clear any previous error

    try {
      const response = await axiosInstance.post(
        "/api/student/skills/get-student-progress",
        { studentId },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );

      // Update the state with the fetched data
      setCoursesProgress(response.data.coursesProgress);
      console.log('cours', response.data)
    } catch (error) {
      setError(error.response?.data?.error || "Une erreur inattendue s'est produite. Veuillez réessayer.");
      toast.error(error.response?.data?.error || "Erreur inattendue. Veuillez réessayer.", {
        position: toast.TOP_RIGHT,
        autoClose: 3000,
      });
    } finally {
      setLoading(false); // End loading
    }
  };

  // Function to fetch skills with their progress
  const getSkillsWithProgress = async (studentId) => {
    setLoading(true); // Start loading
    setError(""); // Clear any previous error

    try {
      const response = await axiosInstance.post(
        "/api/student/skills/get-skills-with-progress",
        { studentId },
        {
          headers: {
            'Content-Type': 'application/json',
          }
        }
      );

      // Update the state with the fetched skills
      setSkillsWithProgress(response.data.skills);
      console.log('response.skills', response.data.skills)
    } catch (error) {
      setError(error.response?.data?.error || "Une erreur inattendue s'est produite. Veuillez réessayer.");
      toast.error(error.response?.data?.error || "Erreur inattendue. Veuillez réessayer.", {
        position: toast.TOP_RIGHT,
        autoClose: 3000,
      });
    } finally {
      setLoading(false); // End loading
    }
  };




  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          throw new Error("No token found");
        }

        const response = await axiosInstance.get(
          "/api/students/profile",
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        getCourses(response.data.student.id);
        getSkillsWithProgress(response.data.student.id);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          setError("Session expired. Please log in again.");
          localStorage.removeItem("token");
          navigate("/");
        } else {
          setError("An error occurred while fetching data. Please try again.");
        }
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  return (
    <>
      {/* for unsubscribed user */}
      {/* <section className='livret'>
       <div className='container'>
         <div className='wrapping'>
            <img src={nolivret} />
            <p>Vous devez acheter un plan pour accéder au livret</p>
            <Link to="/boutique" ><button type="button" class="btn btn-secondary btn-main">Acheter maintenant</button></Link>
         </div>
       </div>
   </section> */}
      <section className='booklet'>
        <div className='container'>
          <h4>Préparez-vous aux questions de l'examen</h4>
          <div className='row mt-4'>
            <div className='col-lg-4'>
              <Link to="/premierssecours">
                <div className='premier d-flex align-items-center'>
                  <img src={ps} />
                  <div className='content-top'>
                    <h5>Premiers
                      Secours
                    </h5>
                  </div>
                </div></Link>
            </div>
            <div className='col-lg-4'>
              <Link to="/check-external">
                <div className='premier d-flex align-items-center'>
                  <img src={cheq} />
                  <div className='content-top'>
                    <h5>
                    Vérifications externes
                    </h5>
                  </div>
                </div>
              </Link>
            </div>
            <div className='col-lg-4'>
              <Link to="/check-internal"><div className='premier d-flex align-items-center'>
                <img src={veriin} />
                <div className='content-top'>
                  <h5>
                    Vérifications internes
                  </h5>
                </div>
              </div></Link>
            </div>
          </div>
          <div className="row">
            {loading ? (
              <div className="col-12 text-center">
                <p>Loading...</p>
              </div>
            ) : skillsWithProgress?.length > 0 ? (
              skillsWithProgress?.map((skill, index) => (
                <CourseCard key={skill.id} skill={skill} index={index} />
              ))
            ) : (
              <div className="col-12"> {/* Fallback message when no courses are available */}
                <p>Aucun cours disponible.</p>
              </div>
            )}
          </div>
        </div>
      </section>
    </>

  )
}

export default Livret
