import { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import "./style.css";
import proimg from "../images/instproimg.png";
import email from "../images/email.svg";
import stucall from "../images/stucall.svg";
import stuchat from "../images/stuchat.svg";
import callphn from "../images/call-phn.svg";
import emailinst from "../images/emial-inst.svg";
import loct from "../images/loct.svg";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import highschool from "../images/highschool.svg";
import meter from "../images/meter.svg";
import Form from 'react-bootstrap/Form';
import userpic from "../images/user-pic.png";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as fullStar, faStarHalfAlt } from '@fortawesome/free-solid-svg-icons';
import { faStar as emptyStar } from '@fortawesome/free-regular-svg-icons';

function InstructorProfile() {
  const { instructorId } = useParams();
  const [instructor, setInstructor] = useState(null);
  const [error, setError] = useState(null);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchInstructorData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token non trouvé');
        }

        const response = await axios.get(`/api/students/instructors/${instructorId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data && response.data.data && response.data.data.data) {
          setInstructor(response.data.data.data);
        } else {
          throw new Error('Données de l\'instructeur non trouvées');
        }
      } catch (err) {
        setError(err.message);
        console.error('Erreur lors de la récupération des données de l\'instructeur:', err);
        if (err.response?.status === 401) {
          localStorage.clear();
          navigate("/"); 
        }
      }
    };

    fetchInstructorData();
  }, [instructorId, navigate]);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const goBack = () => {
    navigate(-1);
  };

  const renderStars = (rating) => {
    const fullStars = Math.floor(rating);
    const halfStars = rating % 1 >= 0.5 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStars;

    return (
      <>
        {[...Array(fullStars)].map((_, index) => (
          <FontAwesomeIcon key={index} icon={fullStar} style={{ color: 'yellow' }} />
        ))}
        {halfStars === 1 && <FontAwesomeIcon icon={faStarHalfAlt} style={{ color: 'yellow' }} />}
        {[...Array(emptyStars)].map((_, index) => (
          <FontAwesomeIcon key={index} icon={emptyStar} style={{ color: 'yellow' }} />
        ))}
      </>
    );
  };

  if (error) {
    return <div>Erreur: {error}</div>;
  }

  if (!instructor) {
    return <div>Chargement...</div>;
  }

  return (
    <>
      <div className='d-flex justify-content-between mb-3'>
        <Breadcrumb className='mb-0 mt-0'>
          <Breadcrumb.Item className='breadcrumb mb-0'><Link to="/dashboard">Acceuil</Link></Breadcrumb.Item>
          <Breadcrumb.Item>Profil d'instructeur</Breadcrumb.Item>
        </Breadcrumb>
        <button type="button" onClick={goBack} className="btn btn-secondary btn-main">
          <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M16.8822 9.06814C16.8852 9.29314 16.7987 9.51012 16.6418 9.67135C16.4848 9.83258 16.2702 9.92484 16.0452 9.92786L4.5188 10.0821L7.6367 13.1177C7.78871 13.2765 7.87314 13.4881 7.8722 13.708C7.87127 13.9278 7.78504 14.1387 7.63169 14.2962C7.47833 14.4537 7.26983 14.5455 7.0501 14.5524C6.83036 14.5592 6.61656 14.4805 6.45374 14.3328L1.86858 9.86872C1.70758 9.71178 1.61545 9.49735 1.61244 9.27253C1.60944 9.04772 1.69579 8.8309 1.85253 8.66971L6.31656 4.08455C6.47537 3.93254 6.68699 3.84811 6.90682 3.84904C7.12666 3.84998 7.33755 3.93621 7.49507 4.08956C7.65258 4.24292 7.74442 4.45142 7.75124 4.67116C7.75806 4.89089 7.67933 5.10469 7.53163 5.26751L4.49608 8.38542L16.0225 8.23113C16.2475 8.22812 16.4645 8.31461 16.6257 8.47158C16.7869 8.62855 16.8792 8.84314 16.8822 9.06814Z" fill="white" />
          </svg>
          Retour
        </button>
      </div>
      <div className='row'>
        <div className='col-lg-4'>
          <div className='side-pro-wrap'>
            <div className='text-center mb-3'> <img src={proimg} alt="Instructor" /></div>
            <div className='text-center'>
              <h4>{instructor?.user ? `${instructor.user.firstName} ${instructor.user.lastName}` : "Instructeur Inconnu"}</h4>
              <strong>instructeur</strong>
            </div>
            <div className='social-icons'>
              <img src={email} alt="Email" />
              <img src={stucall} alt="Call" />
              <img src={stuchat} alt="Chat" />
            </div>
            <hr></hr>
            <h3>Autres informations</h3>
            <div className='phone'>
              <img src={callphn} alt="Phone" />
              <div>
                <h5>Numéro de téléphone: </h5>
                <span>{instructor?.user?.countryCode ? `${instructor.user.countryCode} ${instructor.user.phoneNumber}` : 'Non spécifié'}</span>
              </div>
            </div>
            <div className='phone'>
              <img src={emailinst} alt="Email" />
              <div>
                <h5>Adresse email: </h5>
                <span>{instructor?.user?.email || 'Non spécifié'}</span>
              </div>
            </div>
            <div className='phone'>
              <img src={loct} alt="Location" />
              <div>
                <h5>Emplacement: </h5>
                <span>{instructor.workAddress?.name || 'Non spécifié'}</span>
              </div>
            </div>
          </div>
        </div>
        <div className='col-lg-8'>
          <div className='bg-white p-4 pro-document'>
            <Tabs defaultActiveKey="home" id="uncontrolled-tab-example" className="mb-3">
            <Tab eventKey="home" title="Certifications">
              <div className="certification">
                <h3>Qualification</h3>

                {/* Qualification Section */}
                <div className="list qualification-section">
                  <div className="d-flex block">
                    <img src={highschool} alt="Highschool" />
                    <strong>Diplôme d'études secondaires</strong>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="com-status">
                      <span className="non-completed-text">Non complété</span> {/* Ensure consistent styling here */}
                    </div>
                  </div>
                </div>

                <h3 className="mt-3">Certification</h3>

                {/* Certification Section */}
                <div className="list certification-section">
                  <div className="d-flex block">
                    <img src={meter} alt="Meter" />
                    <strong>Programme de formation à la conduite</strong>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="com-status">
                      <span className="non-completed-text">Non complété</span> {/* Ensure consistent styling here */}
                    </div>
                  </div>
                </div>
              </div>
            </Tab>
            <Tab eventKey="contact" title="Avis">
              <div className="reviews">
                <h3>Avis récents</h3>
                {instructor.reviews && instructor.reviews.length > 0 ? (
                  <div className="container">
                    <div className="row">
                      {instructor.reviews.map((review, index) => (
                        <div key={index} className="col-md-6 mb-4">
                          <div className="rev">
                            <div className="revby">
                              <div className="d-flex justify-content-between align-items-center">
                                <div className="d-flex justify-content-between align-items-center">
                                  <img src={userpic} alt="User" />
                                  <div className="ps-2">
                                    <h5 className="mb-0">{review.studentFirstName} {review.studentLastName}</h5>
                                    <p className="mb-0">Étudiant</p>
                                  </div>
                                </div>
                                <div>
                                  <h6>Date et heure</h6>
                                  <strong>{new Date(review.createdAt).toLocaleString('fr-FR')}</strong>
                                </div>
                              </div>
                              <hr />
                              <div className="rev-pts">
                                <div className="pts">
                                  <p className="mb-0">1. Qualité de l'enseignement</p>
                                  <div className="rating">
                                    {renderStars(review.ratingTeaching)} 
                                  </div>
                                </div>
                                <p>{review.commentTeaching || 'Pas de commentaire.'}</p>
                              </div>
                              <hr />
                              <div className="rev-pts">
                                <div className="pts">
                                  <p className="mb-0">2. Attitude et comportement du moniteur</p>
                                  <div className="rating">
                                    {renderStars(review.ratingCommunication)}
                                  </div>
                                </div>
                                <p>{review.commentCommunication || 'Pas de commentaire.'}</p>
                              </div>
                              <hr />
                              <div className="rev-pts">
                                <div className="pts">
                                  <p className="mb-0">3. Réactions et réflexes</p>
                                  <div className="rating">
                                    {renderStars(review.ratingReflexes)}
                                  </div>
                                </div>
                                <p>{review.commentReflexes || 'Pas de commentaire.'}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                ) : (
                  <p>Aucun avis trouvé.</p>
                )}
              </div>
            </Tab>
            </Tabs>
          </div>
        </div>
      </div>

      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Réserver un emplacement</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-4'>
          <div className="row">
            <div className="col">
              <input type="date" className="form-control" placeholder="DD/MM/YY" />
            </div>
            <div className="col">
              <input type="time" className="form-control" placeholder="Time" />
            </div>
          </div>
          <div className='mt-4'>
            <input type="text" className="form-control" id="inputAddress" placeholder="Meeting place" />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>Fermer</Button>
          <Button variant="primary" onClick={handleClose}>Confirmer</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default InstructorProfile;
