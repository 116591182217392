import { useState } from "react";
import Form from "react-bootstrap/Form";
import "./style.css";
import cuate from "../images/cuate.svg";
import { Button } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import subscr from "../images/subscr-img.svg";
import userpic from "../images/user-pic.png";
import avatar from "../images/avatar.png";
import messaeg_call from "../images/messaeg_call.svg";
import gala_video from "../images/gala_video.svg";
import user_side_img from "../images/user_side_img.png";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import MessageList from "./MessageList";
import MessageInput from "./MessageInput";
import axiosInstance from "../utils/http/config/axios";
import React, { useEffect } from "react";
import io from "socket.io-client";
import { formatDistanceToNow } from "date-fns";
import { fr } from "date-fns/locale";

function Message() {
  //   const [show, setShow] = useState(false);

  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);
  const [searchQuery, setSearchQuery] = useState(""); // State for the search query
  const [searchedInstructors, setSearchedInstructors] = useState([]); // State for storing fetched instructors from search API
  const [combinedList, setCombinedList] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [messages, setMessages] = useState([]);
  const [selectedContact, setSelectedContact] = useState([]);
  const userId = localStorage.getItem("userId"); // Replace with how you store user ID
  const socket = io('wss://app.gmp-auto-ecole.com', {
    path: '/socket.io',
    transports: ["websocket"], // Force WebSocket transport
    timeout: 10000  // 10 seconds timeout
});
 
  console.log(localStorage);

  const fetchContacts = async () => {
    if (!userId) {
      console.error("User ID is not available.");
      return;
    }
    try {
      const response = await axiosInstance.get(
        `/api/messages/fetchContacts/${userId}`
      ); // Replace with your fetchContacts API endpoint
      setContacts(response.data); // Set the response data in state
      setCombinedList(response.data); // Set initial combinedList with contacts
      console.log("contacts", response);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };
  useEffect(() => {
    const fetchInstructors = async () => {
      try {
        const response = await axiosInstance.get(
          "/api/instructors/search",
          {
            params: { query: searchQuery },
          }
        );

        // Transform the fetched instructors data to match the contacts structure
        const transformedInstructors = response.data.map((instructor) => ({
          id: instructor.id,
          senderId: instructor.user.id,
          receiverId: parseInt(userId),
          content: "",
          createdAt: instructor.createdAt,
          sender: {
            id: instructor.user.id,
            firstName: instructor.user.firstName,
            lastName: instructor.user.lastName,
            profilePicture: instructor.user.profilePicture || "default.png",
          },
          receiver: {
            id: parseInt(userId),
            //   firstName: "",
            //   lastName: "",
            //   profilePicture: "",
          },
        }));

        console.log(transformedInstructors);
        // Filter local contacts based on search query
        const localFilteredContacts = contacts?.filter((contact) =>
          `${contact.sender.firstName} ${contact.sender.lastName}`
            .toLowerCase()
            .includes(searchQuery.toLowerCase())
        );

        // Combine with existing contacts and remove duplicates
        const combinedList = [
          ...localFilteredContacts,
          ...transformedInstructors?.filter(
            (instructor) =>
              !contacts.some(
                (contact) => contact.senderId === instructor.senderId
                // contact.receiverId === instructor.senderId
              )
          ),
        ];
        console.log("combinedList", combinedList);

        setCombinedList(combinedList); // Update your combined list state
      } catch (error) {
        console.error("Error fetching instructors:", error);
      }
    };

    if (searchQuery.trim()) {
      fetchInstructors();
    } else {
      setCombinedList(contacts);
    }
  }, [searchQuery, contacts, userId]);

  useEffect(() => {
    socket.emit("joinRoom", {
      senderId: userId,
      receiverId: selectedContact?.id,
    });

    socket.on("newMessage", (message) => {
      console.log("New message received:", message);

      if (
        message.senderId === selectedContact?.id ||
        message.receiverId === selectedContact?.id
      ) {
        setMessages((prevMessages) => [...prevMessages, message]);
      }
    });
    let reconnectAttempts = 0;
const maxReconnectAttempts = 5;

socket.on('connect_error', (err) => {
  console.error('Connect error:', err);

  if (reconnectAttempts < maxReconnectAttempts) {
    reconnectAttempts++;
    setTimeout(() => {
      socket.connect();
    }, 2000); 
  } 
});

    return () => {
      socket.off("newMessage");
    };
  }, [userId, selectedContact]);

  const fetchMessages = async (contactId) => {
    try {
      const response = await axiosInstance.get(
        `/api/messages/fetch/${contactId}`
      );
      console.log(response);
      setMessages(response.data);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  };

  const handleContactClick = (contact) => {
    console.log("Selected contact:", contact);
    if (!contact || !contact.id) {
      console.error("Invalid contact selected:", contact);
      return;
    }
    setSelectedContact(contact);
    fetchMessages(contact.id);
  };

  useEffect(() => {
    fetchContacts();
  }, [userId]);

  return (
    <>
      <section className="Message-chat">
        <div className="container">
          <div className="row">
            <div className="col-lg-3">
              <div className="users_list">
                <div className="search_bar">
                  <svg
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M16.3131 15.5172L12.0651 11.2692C13.0859 10.0437 13.595 8.47182 13.4863 6.88054C13.3777 5.28927 12.6598 3.80113 11.4819 2.7257C10.304 1.65027 8.75688 1.07035 7.16231 1.10658C5.56774 1.14281 4.04853 1.79241 2.92071 2.92022C1.79289 4.04804 1.1433 5.56726 1.10707 7.16182C1.07084 8.75639 1.65076 10.3035 2.72619 11.4814C3.80162 12.6593 5.28976 13.3772 6.88103 13.4858C8.4723 13.5945 10.0442 13.0854 11.2697 12.0646L15.5177 16.3126L16.3131 15.5172ZM2.25058 7.3126C2.25058 6.31133 2.54749 5.33255 3.10377 4.50002C3.66004 3.6675 4.4507 3.01862 5.37575 2.63546C6.3008 2.25229 7.3187 2.15203 8.30073 2.34737C9.28276 2.54271 10.1848 3.02486 10.8928 3.73287C11.6008 4.44087 12.083 5.34292 12.2783 6.32495C12.4736 7.30698 12.3734 8.32488 11.9902 9.24993C11.6071 10.175 10.9582 10.9656 10.1257 11.5219C9.29313 12.0782 8.31435 12.3751 7.31308 12.3751C5.97088 12.3736 4.68408 11.8398 3.735 10.8907C2.78592 9.9416 2.25207 8.6548 2.25058 7.3126Z"
                      fill="black"
                    />
                  </svg>
                  <Form.Control
                    type="email"
                    placeholder="Rechercher..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                  />
                </div>
                <div>
                  {combinedList
                    .filter((contact) => contact.senderId !== parseInt(userId)) // Exclude the authenticated user
                    .map((contact) => (
                      <div
                        className="message_list"
                        key={contact.id}
                        onClick={() => handleContactClick(contact.sender)}
                      >
                        <div className="cover_msg">
                          <img
                            src={contact.sender?.profilePicture || avatar}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = avatar;
                            }}
                          />
                          <div className="name_user ms-2">
                            {/* Check whether the authenticated user is the sender or receiver */}
                            <h5>
                              {contact.senderId !== parseInt(userId)
                                ? `${contact.sender?.firstName} ${contact.sender?.lastName}`
                                : `${contact.receiver?.firstName} ${contact.receiver?.lastName}`}
                            </h5>
                            <p className="mb-0">{contact.content}</p>{" "}
                            {/* Message content */}
                          </div>
                        </div>
                        <span className="time-when">
                          {/* Convert createdAt to a readable time, e.g., '2 minutes ago' */}
                          {formatDistanceToNow(new Date(contact.createdAt), {
                            addSuffix: true,
                            locale: fr,
                          })}{" "}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
            </div>
            <div className="col-lg-9">
              <div className="row top-msghead">
                <div className="col-lg-7">
                  <div className="">
                    <div className="d-flex justify-content-between">
                      <div className="user-details">
                        <div className="user_pic">
                          <img
                            src={selectedContact.profilePicture || avatar}
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = avatar;
                            }}
                          />
                          <div className="user_status">
                            {/* <svg
                              width="11"
                              height="11"
                              viewBox="0 0 11 11"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <circle
                                cx="5.5"
                                cy="5.5"
                                r="5"
                                fill="#2CBB29"
                                stroke="white"
                              />
                            </svg> */}
                          </div>
                        </div>
                        <div className="ms-3">
                          {" "}
                          <h5 className="mb-0">
                            {selectedContact?.firstName}{" "}
                            {selectedContact?.lastName}
                          </h5>
                          <span>En ligne</span>
                        </div>
                      </div>
                      <div className="audio_video">
                        <img src={messaeg_call} />
                        <img className="ms-3" src={gala_video} />
                      </div>
                    </div>
                    <hr></hr>
                    <div className="chat-area">
                      <MessageList messages={messages} userId={userId} />
                    </div>
                    <MessageInput
                      socket={socket}
                      userId={userId}
                      receiver={selectedContact.id}
                      // onNewMessage={handleNewMessage}
                    />
                  </div>
                </div>
                <div className="col-lg-5">
                  <div className="user_details">
                    <img
                      src={selectedContact.profilePicture || avatar}
                      onError={(e) => {
                        e.target.onerror = null;
                        e.target.src = avatar;
                      }}
                    />
                    <h4>
                      {selectedContact?.firstName} {selectedContact?.lastName}
                    </h4>
                    <p className="mb-0">Moniteur d'auto-école</p>
                    <strong className="phn_nomb">
                      {selectedContact?.countryCode}{" "}
                      {selectedContact?.phoneNumber}
                    </strong>
                  </div>
                  <div className="aboutmedia_shared">
                    <Tabs
                      defaultActiveKey="home"
                      id="uncontrolled-tab-example"
                      className="mb-3"
                    >
                      <Tab eventKey="home" title=" Déposer">
                        <div className="file px-3">
                          <div className="d-flex ">
                            <svg
                              width="21"
                              height="25"
                              viewBox="0 0 21 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.5006 0.416504L10.642 0.424962C10.9117 0.45677 11.1629 0.578516 11.3549 0.770559C11.547 0.962602 11.6687 1.21374 11.7005 1.48346L11.709 1.62484V6.45817L11.715 6.63942C11.7583 7.2148 12.0059 7.75571 12.4133 8.16437C12.8206 8.57304 13.3607 8.82247 13.9359 8.86759L14.1256 8.87484H18.959L19.1004 8.8833C19.3701 8.9151 19.6212 9.03685 19.8133 9.22889C20.0053 9.42094 20.127 9.67208 20.1589 9.9418L20.1673 10.0832V20.9582C20.1674 21.8828 19.8141 22.7725 19.1798 23.4452C18.5454 24.118 17.678 24.5229 16.755 24.5771L16.5423 24.5832H4.45898C3.53435 24.5832 2.64465 24.2299 1.97192 23.5956C1.29918 22.9613 0.894271 22.0939 0.840026 21.1708L0.833984 20.9582V4.0415C0.833933 3.11687 1.18721 2.22717 1.82154 1.55444C2.45586 0.881704 3.32328 0.476791 4.24632 0.422546L4.45898 0.416504H10.5006Z"
                                fill="#CACACA"
                              />
                              <path
                                d="M18.9595 6.45808H14.1262L14.125 1.62354L18.9595 6.45808Z"
                                fill="#CACACA"
                              />
                            </svg>
                            <div className="ms-2">
                              <strong>Fichier124</strong>
                              <p className="mb-0">12 mars 2023</p>
                            </div>
                          </div>
                          <h3>125 Mo</h3>
                        </div>
                        <hr></hr>
                        <div className="file px-3">
                          <div className="d-flex ">
                            <svg
                              width="21"
                              height="25"
                              viewBox="0 0 21 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M10.5006 0.416504L10.642 0.424962C10.9117 0.45677 11.1629 0.578516 11.3549 0.770559C11.547 0.962602 11.6687 1.21374 11.7005 1.48346L11.709 1.62484V6.45817L11.715 6.63942C11.7583 7.2148 12.0059 7.75571 12.4133 8.16437C12.8206 8.57304 13.3607 8.82247 13.9359 8.86759L14.1256 8.87484H18.959L19.1004 8.8833C19.3701 8.9151 19.6212 9.03685 19.8133 9.22889C20.0053 9.42094 20.127 9.67208 20.1589 9.9418L20.1673 10.0832V20.9582C20.1674 21.8828 19.8141 22.7725 19.1798 23.4452C18.5454 24.118 17.678 24.5229 16.755 24.5771L16.5423 24.5832H4.45898C3.53435 24.5832 2.64465 24.2299 1.97192 23.5956C1.29918 22.9613 0.894271 22.0939 0.840026 21.1708L0.833984 20.9582V4.0415C0.833933 3.11687 1.18721 2.22717 1.82154 1.55444C2.45586 0.881704 3.32328 0.476791 4.24632 0.422546L4.45898 0.416504H10.5006Z"
                                fill="#CACACA"
                              />
                              <path
                                d="M18.9595 6.45808H14.1262L14.125 1.62354L18.9595 6.45808Z"
                                fill="#CACACA"
                              />
                            </svg>
                            <div className="ms-2">
                              <strong>Fichier124</strong>
                              <p className="mb-0">12 mars 2023</p>
                            </div>
                          </div>
                          <h3>125 Mo</h3>
                        </div>
                      </Tab>
                      <Tab eventKey="profile" title="Médias">
                        Tab content for Profile
                      </Tab>
                      <Tab eventKey="liens" title="Liens">
                        Tab content for Profile
                      </Tab>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Message;
