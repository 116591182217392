import { useNavigate } from 'react-router-dom';
import { useAdvancedMarkerRef, InfoWindow, AdvancedMarker } from "@vis.gl/react-google-maps";
import markerSvg from "../images/marker.svg";
import "./style.css";

const MarkerWithInfoWindow = ({
    position,
    selectedItem,
    handleMarkerClick,
    id,
    handleWindowClose,
}) => {
    const [markerRef, marker] = useAdvancedMarkerRef();
    const navigate = useNavigate();  // Use the useNavigate hook

    // Function to handle redirection to the instructor profile
    const handleViewMoreClick = () => {
        navigate(`/instructorprofile`, { state: { instructor: selectedItem } });
    };    

    return (
        <>
            <AdvancedMarker
                ref={markerRef}
                position={position}
                onClick={handleMarkerClick}
            >
                <img src={markerSvg} width={40} height={50} alt="Marker" />
            </AdvancedMarker>

            {selectedItem && selectedItem.instructorId === id && (
                <InfoWindow
                    anchor={marker}
                    onClose={handleWindowClose}
                    minWidth={250}
                >
                    <div className="d-flex flex-column map-window justify-content-center">
                        <div className="d-flex flex-row justify-content-between align-items-center mb-3">
                            <div>
                                <span>{`${selectedItem.firstName} ${selectedItem.lastName}`}</span>
                            </div>
                            <button
                                className="btn btn-primary"
                                onClick={handleViewMoreClick} 
                            >
                                Voir plus
                            </button>
                        </div>
                        <div className="d-flex flex-row justify-content-between align-items-center">
                            <span>Nombre de cours dispensés</span>
                            <span>30</span>
                        </div>
                    </div>
                </InfoWindow>
            )}
        </>
    );
};

export default MarkerWithInfoWindow;
