import React, { useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";
import noti from "../images/noti.svg";
import setting from "../images/setting.svg";
import Sidebar from "./Sidebar";
import logout from "../images/logout.svg";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

function Index() {
  const [navHeading, setNavHeading] = useState("");
  const navigate = useNavigate(); 
  const location = useLocation();

  const updateHeading = (newHeading) => {
    setNavHeading(newHeading);
  };

  const handleLogout = async () => {
    try {
      // Make a request to the backend to log out
      await axios.get("/api/students/logout", {}, {
        headers: {
          'Content-Type': 'application/json',
        }
      });

      // Clear user data (e.g., token) from local storage
      localStorage.removeItem("token");
      localStorage.removeItem("role");

      // Redirect to login page or any other page
      navigate("/");

      // Show success message
      toast.success("Successfully logged out!");
    } catch (error) {
      console.error("Logout error:", error);
      toast.error("An error occurred while logging out. Please try again.");
    }
  };

  let headerTitle = "";

  switch (location.pathname) {
    case "/notifications":
      headerTitle = "Notifications";
      break;
    case "/feedback-instructor":
      headerTitle = "Feedback Instructor";
      break;
    default:
      headerTitle = ""; 
  } 

  return (
    <>
      <div className="main-wrap">
        <div className="container-fluid side-head">
          <div className="container-wrap">
            <Sidebar updateHeading={updateHeading} />

            <div className="side-content">
            <div className="herder-fixed">
            <section className="header" >
             <div className="d-flex header-toggle">
           
                {headerTitle ? (
                  <h3 className="mb-0">
                   {headerTitle}</h3>
                ) : (
                  navHeading && <h3 className="other-head mb-0">{navHeading}</h3>
                )}
             </div>
                <div className="other-navigation">
                  <Link to="/notifications">
                    <img src={noti} alt="Notifications" />
                  </Link>
                  <Link to="/parameter"> 
                    <img src={setting} alt="Settings" />
                  </Link>
                  <img
                    src={logout}
                    alt="Logout"
                    onClick={handleLogout}
                    style={{ cursor: 'pointer' }} 
                  />
                </div>
              </section>
            </div>
              <Outlet />
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default Index;
