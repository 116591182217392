import { useCallback, useEffect, useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import current from "../images/current.svg";
import dashboardicon from "../images/dashboard-icon.png";
import searchfilters from "../images/search-filters.svg";
import "./style.css";
import Form from "react-bootstrap/Form";
import Spinner from "react-bootstrap/Spinner";
import { AdvancedMarker, APIProvider, Map } from "@vis.gl/react-google-maps";
import { addDays, format } from "date-fns";
import { toast } from "react-toastify";
import MarkerWithInfoWindow from "../components/MarkerWithInfoWindow";
import axiosInstance from "../utils/http/config/axios";
import TimeSlots from "./components/time-slots";
import { useNavigate } from "react-router-dom";

function Dashboard() {
  const navigate = useNavigate();
  const googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_KEY; // Ensure this key is valid
  const [show, setShow] = useState(false);
  const [nearInstructors, setNearInstructors] = useState([]);
  const [nearInstructorsAvailability, setNearInstructorsAvailability] = useState([]);
  const [location, setLocation] = useState(null); // Store location

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [userName, setUserName] = useState("");
  const [currentDate, setCurrentDate] = useState(new Date());
  const [selectedInstructor, setSelectedInstructor] = useState(null);
  const [loadingInstructorsAvailability, setLoadingInstructorsAvailability] = useState(false);
  const [loadingNearInstructors, setLoadingNearInstructors] = useState(false);
  const [reserving, setReserving] = useState(false);

  // Get nearby instructors based on location
  useEffect(() => {
    if (location) {
      setLoadingNearInstructors(true);
      const getNearByInstructors = async () => {
        try {
          const response = await axiosInstance.get(`/api/instructors/near-by`, {
            params: {
              location: `${location.lat},${location.long}`,
              radius: 30000000, // Adjust radius if needed
            },
          });
          setNearInstructors(response.data.data);
        } catch (error) {
          toast(error.message || "Something went wrong, try later!");
        } finally {
          setLoadingNearInstructors(false);
        }
      };
      getNearByInstructors();
    }
  }, [location]);

  // Get instructor availability
  useEffect(() => {
    if (nearInstructors && nearInstructors.length) {
      setLoadingInstructorsAvailability(true);
      const getNearByInstructorsAvailability = async () => {
        try {
          const response = await axiosInstance.get(`/api/instructors/availability`, {
            params: {
              ids: nearInstructors.map((instructor) => instructor.instructorId).join(","),
              startDate: format(currentDate, "yyyy-MM-dd"),
              endDate: format(addDays(currentDate, 7), "yyyy-MM-dd"),
            },
          });
          setNearInstructorsAvailability(response.data.data);
        } catch (error) {
          console.log("error: ", error);
        } finally {
          setLoadingInstructorsAvailability(false);
        }
      };
      getNearByInstructorsAvailability();
    }
  }, [nearInstructors]);

  // Get student location using geolocation API
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          setLocation({
            lat: position.coords.latitude,
            long: position.coords.longitude,
          });
        },
        (err) => {
          console.log("Unable to get location");
        }
      );
    }
  }, []);

  // Retrieve user information from localStorage
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user) {
      setUserName(`${user.lastName} ${user.firstName}`);
    }
  }, []);

  const backToList = () => {
    setSelectedInstructor(null);
  };
  const handleMarkerClick = (instructor) => {
    setSelectedInstructor(instructor);
  };

  const handleWindowClose = useCallback(() => {}, []);

  const reserveSlots = async (selected) => {
  if (selected && selected.length) {
    setReserving(true);
    try {
      const response = await axiosInstance.post(`/api/students/book`, {
        availabilityIds: selected.map((item) => item.id),
        lessonDetails: { duration: selected.length },
      });
      toast(response.data.message || "Réservation effectuée avec succès !");
      navigate("/slotconfirmation");
    } catch (error) {
      const errorMsg = error.response?.data?.error || "Une erreur est survenue. Veuillez réessayer plus tard.";
      toast(errorMsg);
    } finally {
      setReserving(false);
    }
  }
};

  return (
    <section className="dashboard">
      <div className="container-fluid">
        <div className=" mt-5">
          <div className="row dashboard-wrap">
            <div className="col-lg-8">
              <div className="content-wrap">
                <h2>
                  Bienvenue! <span>{userName}</span>
                </h2>
                <p>Explorez nos cours d'école de conduite</p>
              </div>
            </div>
            <div className="col-lg-4">
              <img src={dashboardicon} alt="Dashboard icon" />
            </div>
          </div>
        </div>
        <div className="heures">
          <div className="content">
            <h3>Acheter des heures</h3>
            <button type="button" className="btn btn-light">
              Acheter maintenant
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-7">
            <div className="filters">
              <div className="mb-3 inp-fil">
                <div className="search-icon">
                  <img src={current} alt="Current location" />
                </div>
                <input
                  type="email"
                  className="form-control"
                  placeholder="recherche.."
                />
                <div className="current-loc">
                  <img src={searchfilters} alt="Search filters" />
                </div>
              </div>
              <div className="filters-cions" onClick={handleShow}>
                <svg
                  width="48"
                  height="48"
                  viewBox="0 0 48 48"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle
                    cx="24"
                    cy="24"
                    r="24"
                    fill="url(#paint0_linear_1710_5178)"
                  />
                  <path
                    d="M26.0001 24V31.88C26.0401 32.18 25.9401 32.5 25.7101 32.71C25.6176 32.8027 25.5077 32.8762 25.3868 32.9264C25.2658 32.9766 25.1361 33.0024 25.0051 33.0024C24.8742 33.0024 24.7445 32.9766 24.6235 32.9264C24.5025 32.8762 24.3926 32.8027 24.3001 32.71L22.2901 30.7C22.1811 30.5933 22.0982 30.4629 22.0479 30.319C21.9976 30.175 21.9813 30.0213 22.0001 29.87V24H21.9701L16.2101 16.62C16.0477 16.4115 15.9745 16.1473 16.0063 15.8849C16.0382 15.6226 16.1726 15.3835 16.3801 15.22C16.5701 15.08 16.7801 15 17.0001 15H31.0001C31.2201 15 31.4301 15.08 31.6201 15.22C31.8277 15.3835 31.9621 15.6226 31.9939 15.8849C32.0258 16.1473 31.9525 16.4115 31.7901 16.62L26.0301 24H26.0001Z"
                    fill="white"
                  />
                  <defs>
                    <linearGradient
                      id="paint0_linear_1710_5178"
                      x1="2.45669"
                      y1="14.2041"
                      x2="46.2793"
                      y2="18.0883"
                      gradientUnits="userSpaceOnUse"
                    >
                      <stop stopColor="#C43CAB" />
                      <stop offset="1" stopColor="#F87E4B" />
                    </linearGradient>
                  </defs>
                </svg>
              </div>
            </div>
            <div className="stations">
              <Accordion>
                {!loadingNearInstructors && !loadingInstructorsAvailability ? (
                  nearInstructors && nearInstructors.length ? (
                    nearInstructors.map((instructor) => {
                      return (
                        <Accordion.Item
                          eventKey={instructor.instructorId}
                          key={instructor.instructorId}
                          hidden={
                            selectedInstructor &&
                            selectedInstructor.instructorId !==
                              instructor.instructorId
                          }
                        >
                          <Accordion.Header>
                            {instructor?.location?.address}
                          </Accordion.Header>
                          <Accordion.Body>
                            <div className="distance">
                              <p>95630, Mériel</p>
                              <strong>{`${Math.floor(
                                instructor?.location?.distance
                              )}m`}</strong>
                            </div>
                            {!loadingInstructorsAvailability ? (
                              <TimeSlots
                                instructor={instructor}
                                instructorAvailability={
                                  nearInstructorsAvailability[
                                    instructor.instructorId
                                  ]
                                }
                                reserveSlots={(selectedTimeSlots) =>
                                  reserveSlots(selectedTimeSlots)
                                }
                                reserving={reserving}
                              />
                            ) : (
                              <div className="d-flex justify-content-center align-items-center h-100">
                                <Spinner animation="grow"></Spinner>
                              </div>
                            )}
                          </Accordion.Body>
                        </Accordion.Item>
                      );
                    })
                  ) : (
                    <></>
                  )
                ) : (
                  <div className="d-flex justify-content-center align-items-center h-100">
                    <Spinner animation="grow"></Spinner>
                  </div>
                )}
              </Accordion>
              {selectedInstructor && (
                <div className="mt-5 d-flex justify-content-center">
                  <button
                    className="btn btn-outline-main"
                    onClick={() => backToList()}
                  >
                    Back to list
                  </button>
                </div>
              )}
            </div>
          </div>
          <div className="col-md-12 col-lg-5">
            <div className="my-4">
              {/* map */}
              {location ? (
                <APIProvider apiKey={googleMapsKey}>
                  <Map
                    mapId={process.env.REACT_APP_MAP_ID}
                    style={{
                      width: "100%",
                      height: "100vh",
                      borderRadius: "1.5rem",
                      overflow: "hidden",
                      border: "1px solid #8d8b8ba8",
                    }}
                    defaultCenter={{ lat: location?.lat, lng: location?.long }}
                    defaultZoom={10}
                    gestureHandling={"greedy"}
                    disableDefaultUI={true}
                  />

                  <AdvancedMarker
                    position={{ lat: location.lat, lng: location.long }}
                    title={"You're current position"}
                  >
                    <div
                      style={{
                        width: 60,
                        height: 60,
                        position: "absolute",
                        top: 0,
                        h: 0,
                        backgroundColor: "#f87e4b52",
                        borderRadius: "50%",
                        transform: "translate(-50%, -50%)",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <div
                        style={{
                          backgroundColor: "#C43CAB",
                          width: 16,
                          height: 16,
                          borderRadius: "50%",
                          border: "2px solid white",
                        }}
                      ></div>
                    </div>
                  </AdvancedMarker>

                  {nearInstructors?.map((instructor) => {
                    return (
                      <MarkerWithInfoWindow
                        position={{
                          lat: instructor.location.lat,
                          lng: instructor.location.long,
                        }}
                        handleMarkerClick={() => handleMarkerClick(instructor)}
                        selectedItem={selectedInstructor}
                        key={instructor.instructorId}
                        id={instructor.instructorId}
                        handleWindowClose={handleWindowClose}
                      />
                    );
                  })}
                </APIProvider>
              ) : (
                <p>Loading map...</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Filtres</Modal.Title>
        </Modal.Header>
        <Modal.Body className="select-filters">
          <div className="options1">
            <label>Évaluation des instructeurs</label>
            <div className="d-flex mb-3">
              <div className="form-check">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault1"
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  3
                  <svg
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.49984 11.672L10.7853 13.6591C11.3869 14.0232 12.1232 13.4849 11.9648 12.8041L11.094 9.0674L13.9994 6.5499C14.5298 6.09073 14.2448 5.2199 13.5482 5.16448L9.72443 4.8399L8.22818 1.30906C7.95901 0.667812 7.04068 0.667812 6.77151 1.30906L5.27526 4.83198L1.45151 5.15656C0.754844 5.21198 0.469844 6.08281 1.00026 6.54198L3.90568 9.05948L3.03484 12.7961C2.87651 13.477 3.61276 14.0153 4.21443 13.6511L7.49984 11.672Z"
                      fill="#FFA800"
                    />
                  </svg>
                </label>
              </div>
              <div className="form-check ms-2">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault2"
                />
                <label className="form-check-label" htmlFor="flexRadioDefault2">
                  4
                  <svg
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.49984 11.672L10.7853 13.6591C11.3869 14.0232 12.1232 13.4849 11.9648 12.8041L11.094 9.0674L13.9994 6.5499C14.5298 6.09073 14.2448 5.2199 13.5482 5.16448L9.72443 4.8399L8.22818 1.30906C7.95901 0.667812 7.04068 0.667812 6.77151 1.30906L5.27526 4.83198L1.45151 5.15656C0.754844 5.21198 0.469844 6.08281 1.00026 6.54198L3.90568 9.05948L3.03484 12.7961C2.87651 13.477 3.61276 14.0153 4.21443 13.6511L7.49984 11.672Z"
                      fill="#FFA800"
                    />
                  </svg>
                </label>
              </div>
              <div className="form-check mx-2">
                <input
                  className="form-check-input"
                  type="radio"
                  name="flexRadioDefault"
                  id="flexRadioDefault3"
                />
                <label className="form-check-label" htmlFor="flexRadioDefault1">
                  5
                  <svg
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M7.49984 11.672L10.7853 13.6591C11.3869 14.0232 12.1232 13.4849 11.9648 12.8041L11.094 9.0674L13.9994 6.5499C14.5298 6.09073 14.2448 5.2199 13.5482 5.16448L9.72443 4.8399L8.22818 1.30906C7.95901 0.667812 7.04068 0.667812 6.77151 1.30906L5.27526 4.83198L1.45151 5.15656C0.754844 5.21198 0.469844 6.08281 1.00026 6.54198L3.90568 9.05948L3.03484 12.7961C2.87651 13.477 3.61276 14.0153 4.21443 13.6511L7.49984 11.672Z"
                      fill="#FFA800"
                    />
                  </svg>
                </label>
              </div>
            </div>
          </div>
          <label>Distance de votre emplacement</label>
          <Form.Select aria-label="Default select example">
            <option>Sélectionnez la distance</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Select>
          <label>Disponibilité des plages horaires</label>
          <Form.Select aria-label="Default select example">
            <option>Time slots</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Select>
          <label>Langues parlées par le moniteur</label>
          <Form.Select aria-label="Default select example">
            <option>Select Language</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
          </Form.Select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleClose}>
            Filters
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
}

export default Dashboard;
