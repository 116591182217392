import React from 'react'
import './style.css'
import psd from '../images/psd.svg'
import { Link } from 'react-router-dom'

function forgot_psd() {
  return (
    <section className='verify_email'>
    <div className='container-fluid'>
        <div className='row'>
            <div className='col-lg-5 mx-auto'>
               <div className='wrapping d-flex flex-column justify-content-center align-items-center'>
               <img src={psd} />
                <h3>Réinitialiser votre mot de passe</h3>
                <p className='text-center'>Nous avons reçu une demande de réinitialisation du mot de passe de votre compte VNB-IT. Si vous avez fait cette demande, veuillez cliquer sur le bouton ci-dessous pour créer un nouveau mot de passe :</p>
                <div className='row'>
              <div className='col'>
                <div className="mb-3">
                  <label htmlFor="currentPassword" className="form-label">Mot de passe actuel</label>
                  <input
                    type="password"
                    className="form-control"
                    id="currentPassword"
                    name="currentPassword"
                   
                  />
                </div>
              </div>
              <div className='col'>
                <div className="mb-3">
                  <label htmlFor="newPassword" className="form-label">Nouveau mot de passe</label>
                  <input
                    type="password"
                    className="form-control"
                    id="newPassword"
                    name="newPassword"
                   
                  />
                </div>
              </div>
            </div>
                <button type="button" class="btn btn-secondary btn-main mb-4 email-recove mt-4">Réinitialiser votre mot de passe</button>
              
               </div>
            </div>
        </div>
    </div>
</section>
  )
}

export default forgot_psd