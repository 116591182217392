import React, { useState } from "react";
import './style.css'
import Form from 'react-bootstrap/Form';
import { Link } from "react-router-dom";
import sent from '../images/sent.svg';
import mage_attachment from '../images/mage_attachment.svg';

function MessageInput({ addMessage }) {
    const [inputValue, setInputValue] = useState('');

    const handleSubmit = (e) => {
        e.preventDefault();
        if (inputValue.trim()) {
            const newMessage = {
                id: Date.now(),
                text: inputValue,
                timestamp: new Date().toLocaleString(),
            };
            addMessage(newMessage);
            setInputValue('');
        }
    };


  return (
    <div className="message-input">
          <div className='text_input'>

<Form.Control  type="text"
        
        value={inputValue}
        onChange={(e) => setInputValue(e.target.value)}
                placeholder='tapez un message..'  />
<div className='attchmnts'>
  <Link to="" ><img src={mage_attachment} /></Link>
  <Link to="" onClick={handleSubmit} ><img src={sent} /></Link>
</div>
</div>
     
    
    </div>
  );
}

export default MessageInput;