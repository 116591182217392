import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axiosInstance from "../../utils/http/config/axios";
import "../style.css";

function ShowCours() {
  const navigate = useNavigate();
  const [courseData, setCourseData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [progress, setProgress] = useState(0);
  const { id } = useParams();
  const [savedProgress, setSavedProgress] = useState(0);
  let scrollTimeout = null;

  const goBack = () => {
    navigate(-1);
  };

  const handleScroll = () => {
    if (scrollTimeout) {
      clearTimeout(scrollTimeout);
    }
    const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
    const scrollPosition = window.scrollY;

    if (totalHeight > 0) {
      const scrollProgress = parseInt((scrollPosition / totalHeight) * 100);
      setProgress(scrollProgress);
    }

    // Set a timeout to detect scroll stop (debounce pattern)
    scrollTimeout = setTimeout(() => {
      sendProgressUpdate();
    }, 100); // 300ms after the user stops scrolling, send the request
  };

  const sendProgressUpdate = async () => {
    if (progress > savedProgress) { // Only send if the current progress is higher than savedProgress
      try {
        await axiosInstance.post("/api/student/cours/update-course-progress", {
          progressPercentage: progress, // send the progress percentage
          courseId: id, // the course ID
        }, {
          headers: {
            'Content-Type': 'application/json',
          }
        });

        setSavedProgress(progress); // Update savedProgress after successful update
      } catch (error) {
        console.error('Error updating course progress:', error);
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    const fetchCourseData = async () => {
      try {
        const response = await axiosInstance.post("/api/student/cours/get-course", { id }, {
          headers: {
            'Content-Type': 'application/json',
          }
        });

        setCourseData(response.data.course);
        setSavedProgress(response.data.progress);

        // Calculate the scroll position based on the saved progress
        const totalHeight = document.documentElement.scrollHeight - window.innerHeight;
        const scrollPosition = (response.data.progress / 100) * totalHeight;

        // Scroll to the position based on the saved progress
        window.scrollTo(0, scrollPosition);
      } catch (error) {
        console.error('Error fetching course data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchCourseData();

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [id]);

  useEffect(() => {
    // Recalculate scroll progress when loading finishes
    if (!loading) {
      handleScroll();
    }
  }, [loading]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!courseData) {
    return <div>No course data available.</div>;
  }

  return (
    <section className='apprehend'>
      <div className='container'>
        <h4>{courseData.titre}</h4>
        <div className='back-btn mt-3'>
          <button type="button" onClick={goBack} className="btn btn-secondary btn-main">
            Retour
          </button>
        </div>
        <div className='text-wraps'>
          <div className='master-title'>
            <h4>{courseData.titre}</h4>
          </div>
          <div className='course-details'>
            <p><strong>Durée estimée :</strong> {courseData.estimated_duration}</p>
            <div dangerouslySetInnerHTML={{ __html: courseData.body }} />
          </div>
        </div>
      </div>
    </section>
  );
}

export default ShowCours;
