import React, { useState } from 'react';
import './style.css';
import verifyemail from '../images/verify-email.svg';
import { useNavigate, useLocation } from 'react-router-dom';
import axiosInstance from '../utils/http/config/axios'; 

function VerifyEmail() {
  const location = useLocation();
  const navigate = useNavigate(); 
  const userEmail = location.state?.userEmail || 'votre email';
  const [isResending, setIsResending] = useState(false);
  const [resendError, setResendError] = useState("");
  const [resendSuccess, setResendSuccess] = useState("");

  const handleResendEmail = async () => {
    setIsResending(true);
    setResendError("");  
    setResendSuccess("");  

    try {
      const response = await axiosInstance.post('/api/students/resend-verification-email', { email: userEmail });
      setResendSuccess(response.data.message); 
    } catch (error) {
      if (error.response) {
        const statusCode = error.response.status;
        // Handle different error cases based on the status code
        switch (statusCode) {
          case 400:
            setResendError("Requête invalide. Veuillez vérifier l'adresse e-mail fournie.");
            break;
          case 404:
            setResendError("Adresse e-mail non trouvée. Veuillez vérifier votre saisie.");
            break;
          case 500:
            setResendError("Erreur interne du serveur. Veuillez réessayer plus tard.");
            break;
          default:
            setResendError(`Erreur inattendue : ${error.response.data.message || 'Veuillez réessayer.'}`);
        }
      } else if (error.request) {
        setResendError("Aucune réponse du serveur. Veuillez vérifier votre connexion internet.");
      } else {
        setResendError(`Erreur lors de la demande : ${error.message}`);
      }
    } finally {
      setIsResending(false);  
    }
  };

  const handleLogin = () => {
    navigate('/');  
  };

  return (
    <section className="verify_email">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-5 mx-auto">
            <div className="wrapping d-flex flex-column justify-content-center align-items-center">
              <img src={verifyemail} alt="Verify Email" />
              <h3>Vérifiez votre email</h3>
              <p className="text-center">
                L'email a été envoyé à votre adresse '{userEmail}' avec un lien pour vérifier votre compte.
              </p>

              {/* Resend verification email button */}
              <button
                type="button"
                className="btn btn-secondary btn-main mb-4 email-recover"
                onClick={handleResendEmail}
                disabled={isResending}
              >
                {isResending ? 'Envoi en cours...' : 'Renvoyer l\'e-mail'}
              </button>

              {/* Redirect to login button */}
              <button
                type="button"
                className="btn btn-primary btn-main mb-4 email-recover"
                onClick={handleLogin}
              >
                Connexion
              </button>

              {/* Display success or error messages */}
              {resendSuccess && <p className="text-success">{resendSuccess}</p>}
              {resendError && <p className="text-danger">{resendError}</p>}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default VerifyEmail;
