import React, { useState, useEffect } from 'react';
import { useStripe, useElements, CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Button, Modal } from 'react-bootstrap';
import transsuccess from '../images/trans-success.svg';

function CheckoutForm({ planDetails, onSuccess, onError }) {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => setShowModal(false);

  const handleRedirect = () => {
  navigate('/dashboard'); 
  };

  useEffect(() => {
    console.log('Détails du plan:', planDetails);
  }, [planDetails]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      setError("Stripe n'est pas encore chargé.");
      return;
    }

    if (!planDetails?.id) {
      setError("Aucun plan valide sélectionné. Veuillez retourner en arrière et sélectionner un plan.");
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    setLoading(true);

    try {
      const { error: stripeError, token } = await stripe.createToken(cardElement);

      if (stripeError) {
        setError(stripeError.message);
        setLoading(false);
        return;
      }

      const subscriptionTypeId = planDetails.id;
      const authToken = localStorage.getItem('token');

      const response = await fetch('/api/students/achat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          subscriptionTypeId,
          paymentToken: token.id,
        }),
      });

      const result = await response.json();

      if (result.success) {
        localStorage.setItem('hasSubscription', 'true');
        setSuccess('Paiement réussi !');
        setShowModal(true); // Show modal on success
        onSuccess && onSuccess();
        toast.success("Paiement réussi !", {
          position: toast.TOP_RIGHT,
          autoClose: 3000,
        });
      } else {
        throw new Error(result.message);
      }
    } catch (error) {
      console.error('Erreur de paiement:', error);
      setError('Une erreur est survenue lors du traitement du paiement.');
      onError && onError(error.message);
      toast.error("Erreur lors du paiement. Veuillez réessayer.", {
        position: toast.TOP_RIGHT,
        autoClose: 3000,
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="formulaire">
      <div className='row'>
        <div className="mb-3 col">
          <label htmlFor="card-number-element" className="form-label">Numéro de carte*</label>
          <div id="card-number-element" className="form-control">
            <CardNumberElement options={{ style: { base: { fontSize: '16px' } } }} />
          </div>
        </div>
      </div>
      <div className='row'>
        <div className="mb-3 col">
          <label htmlFor="expiry-element" className="form-label">Date d'expiration*</label>
          <div id="expiry-element" className="form-control">
            <CardExpiryElement options={{ style: { base: { fontSize: '16px' } } }} />
          </div>
        </div>
        <div className="mb-3 col-lg-6">
          <label htmlFor="cvc-element" className="form-label">CVC*</label>
          <div id="cvc-element" className="form-control">
            <CardCvcElement options={{ style: { base: { fontSize: '16px' } } }} />
          </div>
        </div>
      </div>
      <button type="submit" className="btn btn-secondary btn-main" disabled={!stripe || loading}>
        {loading ? 'Traitement en cours...' : 'Payer'}
      </button>

      {/* Success Modal */}
      <Modal show={showModal} onHide={handleCloseModal} className="trans-popup">
        <Modal.Header closeButton className="border-0"></Modal.Header>
        <Modal.Body>
          <div className='transation-successful p-2'>
            <h2>Transaction réussie !</h2>
            <div className="my-4">
              <img src={transsuccess} alt="transaction" />
            </div>
            <p>Votre paiement a été traité avec succès</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="d-flex justify-content-center">
          <Button variant="secondary" className="btn-main text-white" onClick={handleRedirect}>
            réservé un cours de conduite
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Error Feedback */}
      <div className="messages-feedback">
        {error && <p className="text-danger">{error}</p>}
        {success && <p className="text-success">{success}</p>}
      </div>
    </form>
  );
}

export default CheckoutForm;
