import React from 'react'
import './style.css'
import profile from '../images/profile.png'
import lock from '../images/lock.png'
import traingicon from '../images/traingicon.png'
import adminfile from '../images/adminfile.png'
import contacticon from '../images/contacticon.png'
import legalicon from '../images/legalicon.png'
import conicon from '../images/con-icon.png'
import lgicon from '../images/lgicon.png'
import support from '../images/support_icon.png'
import myreservation from '../images/myreservation.png'
import myevaluation from '../images/myevaluation.png'
import reoprtshare from '../images/reoprtshare.png'
import { Link } from 'react-router-dom'


function parameter() {
  return (
    <section className='parameter'>
        <div className='container'>
            <div className='row param'>
                <div className='col-lg-6'>
                    <Link to="/profile">
                    <div className='cover-wrap'>
                        <img src={profile} />
                        <div>
                            <strong>Profil</strong><br></br>
                            <span>Complétez vos informations personnelles et dites-nous comment vous contacter.</span>
                        </div>
                    </div>
                    </Link>
                </div>
                <div className='col-lg-6'>
                <Link to="/changepassword">
                <div className='cover-wrap lock'>
                        <img src={lock} />
                        <div>
                            <strong>Mot de passe</strong><br></br>
                            <span>Protégez votre compte en mettant régulièrement à jour votre mot de passe.</span>
                        </div>
                    </div>
                </Link>
                    </div>
                    <div className='col-lg-6'>
                    <Link to="/myreservation">
                    <div className='cover-wrap myreser'>
                        <img src={myreservation} />
                        <div>
                            <strong>Mes réservations</strong><br></br>
                            <span>vous pouvez vérifier vos réservations ici.</span>
                        </div>
                    </div>
                    </Link>
                </div>
                
                    
                <div className='col-lg-6'>
                <Link to="/neph">
                <div className='cover-wrap admin'>
                        <img src={adminfile} />
                        <div>
                            <strong>Dossier administratif</strong><br></br>
                            <span>Le NEPH est obligatoire pour réserver des cours de conduite.</span>
                        </div>
                    </div>
                </Link>
                    </div>
                    {/* <div className='col-lg-6'>
                    <Link to="/sponser">
                    <div className='cover-wrap cont'>
                        <img src={contacticon} />
                        <div>
                            <strong>Parrainage</strong><br></br>
                            <span>Partagez votre code de parrainage autour de vous pour gagner des récompenses.</span>
                        </div>
                    </div>
                    </Link>
                </div> */}
                
                    <div className='col-lg-6'>
                    <Link to="https://gmp-auto-ecole.com/contact/"><div className='cover-wrap coni'>
                        <img src={conicon} />
                        <div>
                            <strong>Contactez-nous</strong><br></br>
                            <span>Vos paramètres d'entraînement nous aident à personnaliser votre expérience.</span>
                        </div>
                    </div></Link>
                </div>
                <div className='col-lg-6'>
                <Link to="/legal">
                <div className='cover-wrap lgi'>
                        <img src={lgicon} />
                        <div>
                            <strong>Légal</strong><br></br>
                            <span>Le NEPH est obligatoire pour réserver des cours de conduite.</span>
                        </div>
                    </div>
                </Link>
                    </div>
                    <div className='col-lg-6'>
                <Link to="/helpsupport">
                <div className='cover-wrap sup'>
                        <img src={support} />
                        <div>
                            <strong>Support technique</strong><br></br>
                            
                        </div>
                    </div>
                </Link>
                    </div>
                    {/* <div className='col-lg-6'>
                        <Link to="/report-admin" >
                        <div className='cover-wrap reportshare'>
                        <img src={reoprtshare} />
                        <div>
                            <strong>Reports share by admin</strong><br></br>
                            
                        </div>
                    </div></Link>
                    </div> */}
                    {/* <div className='dis-conncet col-lg-12 mt-4'>
            <button type="button" class="btn btn-secondary btn-main"><svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.5 2C12.5 1.60218 12.342 1.22064 12.0607 0.93934C11.7794 0.658035 11.3978 0.5 11 0.5C10.6022 0.5 10.2206 0.658035 9.93934 0.93934C9.65804 1.22064 9.5 1.60218 9.5 2V12C9.5 12.3978 9.65804 12.7794 9.93934 13.0607C10.2206 13.342 10.6022 13.5 11 13.5C11.3978 13.5 11.7794 13.342 12.0607 13.0607C12.342 12.7794 12.5 12.3978 12.5 12V2ZM6.854 4.75C7.01815 4.64094 7.15922 4.50061 7.26914 4.33703C7.37906 4.17345 7.45568 3.98982 7.49464 3.79663C7.53359 3.60344 7.53411 3.40447 7.49617 3.21107C7.45823 3.01768 7.38257 2.83365 7.2735 2.6695C7.16444 2.50535 7.02411 2.36428 6.86053 2.25436C6.69695 2.14444 6.51333 2.06782 6.32013 2.02886C6.12694 1.98991 5.92797 1.98939 5.73457 2.02733C5.54118 2.06527 5.35715 2.14094 5.193 2.25C3.74911 3.20761 2.56484 4.50788 1.74593 6.03472C0.927026 7.56155 0.498975 9.26742 0.500002 11C0.500002 16.799 5.201 21.5 11 21.5C16.799 21.5 21.5 16.799 21.5 11C21.5 7.346 19.633 4.13 16.807 2.25C16.4755 2.02987 16.0701 1.95045 15.68 2.02922C15.2899 2.10798 14.9471 2.33848 14.727 2.67C14.5069 3.00152 14.4275 3.40691 14.5062 3.79698C14.585 4.18706 14.8155 4.52987 15.147 4.75C16.4896 5.64064 17.5097 6.93996 18.0563 8.45556C18.6028 9.97117 18.6468 11.6225 18.1817 13.165C17.7165 14.7076 16.767 16.0594 15.4738 17.0202C14.1805 17.981 12.6122 18.4999 11.001 18.4999C9.38985 18.4999 7.82153 17.981 6.52825 17.0202C5.23496 16.0594 4.28546 14.7076 3.82034 13.165C3.35521 11.6225 3.39919 9.97117 3.94575 8.45556C4.49231 6.93996 5.51141 5.64064 6.854 4.75Z" fill="white"/>
</svg> Deconnecter</button>
            </div> */}
            </div>
           
        </div>
    </section>
  )
}

export default parameter