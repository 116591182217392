import React from 'react';
import { useNavigate } from 'react-router-dom'; 
import "./style.css";
import amico from "../images/amico.png";

function Slotconfirmation() {
  const navigate = useNavigate();  

  const handleReturnHome = () => {
    navigate('/subscribed-dashboard');  
  };

  return (
    <section className='slotconfirmation'>
      <div className='mt-5 text-center mb-5'>
        <h3>Toutes nos félicitations</h3>
        <span>Vos créneaux ont été réservés avec succès</span>
      </div>
      <div>
        <img src={amico} alt="Confirmation" />
      </div>
      <div className='btns'>
        <button 
          type="button" 
          className="btn btn-primary btn-main" 
          onClick={handleReturnHome}  
        >
          De retour à la maison
        </button>
      </div>
    </section>
  );
}

export default Slotconfirmation;
