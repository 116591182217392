import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import './style.css'
import downloadfile from '../images/downloadfile.png'

import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Training() {
    const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1); // Navigate back to the previous page
    };
  return (
    <section className='training'>
        <div className='container'>
        <div className='wrapping'>
        <div className='back-btn'>
       <button type="button" onClick={goBack} class="btn btn-secondary btn-main"> <svg width="19" height="19" viewBox="0 0 19 19"  xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.8055 9.06832C16.8086 9.29332 16.7221 9.5103 16.5651 9.67153C16.4081 9.83276 16.1935 9.92503 15.9685 9.92804L4.44214 10.0823L7.56004 13.1179C7.71205 13.2767 7.79648 13.4883 7.79554 13.7081C7.79461 13.928 7.70838 14.1389 7.55503 14.2964C7.40167 14.4539 7.19317 14.5457 6.97343 14.5526C6.7537 14.5594 6.5399 14.4806 6.37708 14.3329L1.79192 9.86891C1.63092 9.71196 1.53879 9.49753 1.53578 9.27272C1.53278 9.0479 1.61913 8.83108 1.77587 8.66989L6.2399 4.08473C6.39871 3.93272 6.61033 3.84829 6.83016 3.84923C7.05 3.85016 7.26089 3.93639 7.41841 4.08975C7.57592 4.2431 7.66776 4.45161 7.67458 4.67134C7.6814 4.89107 7.60267 5.10487 7.45497 5.2677L4.41942 8.38561L15.9458 8.23132C16.1708 8.2283 16.3878 8.3148 16.549 8.47177C16.7103 8.62874 16.8025 8.84332 16.8055 9.06832Z" fill="white"/>
</svg>
Retour</button>
       </div>
      <div className='my-4'>
      <h3>Informations personnelles</h3>
       <p>Vous trouverez ici un résumé de vos objectifs ainsi que de vos préférences de conduite..</p>
      </div>
        <div className='form'>
            <h4 className='my-4'>Objectif de conduite</h4>
            <div className='row'>
                <div className='col'>
                <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">Quand souhaitez-vous obtenir votre permis ?</label>
                <select class="form-select" aria-label="Default select example">
                <option selected>Open this select menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
                </select>
                </div>
                </div>
                <div className='col'>
                <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">Votre objectif</label>
                <input type="text" class="form-control" id="exampleFormControlInput1" />
                </div>
                </div>
            </div>
            <div className='row'>
                <div className='col-lg-6'>
                <div class="mb-3">
  <label for="exampleFormControlInput1" class="form-label">Nombre d'heures évaluées</label>
  <input type="email" class="form-control" id="exampleFormControlInput1" />
</div>
                </div>
            </div>
            <div className="submit-btn">
           <button type="button" class="btn btn-secondary btn-main">Mettre à jour</button>
           </div>
        </div>
        <div className='form'>
            <h4 className='my-4'>Votre formation pratique</h4>
            <div className='row'>
                <div className='col'>
                <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">Type de formation</label>
                <select class="form-select" aria-label="Default select example">
                <option selected>Formation classique</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
                </select>
                </div>
                </div>
                <div className='col'>
                <div class="mb-3">
                <label for="exampleFormControlInput1" class="form-label">Type de transmission</label>
                <select class="form-select" aria-label="Default select example">
                <option selected>Formation classique</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
                </select>
                </div>
                </div>
            </div>
            
            <div className="submit-btn">
           <button type="button" class="btn btn-secondary btn-main">Mettre à jour</button>
           </div>
        </div>
        <div className='form'>
            <h4 className='my-4'>Préférences de planification</h4>
            <p>Veuillez sélectionner au moins 6 créneaux pour atteindre vos objectifs. Cependant, nous ne pouvons pas garantir la disponibilité des enseignants pour ces créneaux.</p>
            <div className='schedule'>
            <Form>
            {['checkbox'].map((type) => (
        <div key={`default-${type}`} className="mb-3">
         <div className='d-flex justify-content-between mb-2'>
         <Form.Check 
            type={type}
            id={`default-${type}`}
            label={`Lundi`}
            onClick={handleShow}
          />
          <div className='d-flex'>
     
           <div className='modify'>
          <svg width="18" height="18" viewBox="0 0 18 18"  xmlns="http://www.w3.org/2000/svg">
<path d="M11.25 4.50002L13.5 6.75002M9.75 15H15.75M3.75 12L3 15L6 14.25L14.6895 5.56052C14.9707 5.27923 15.1287 4.89776 15.1287 4.50002C15.1287 4.10227 14.9707 3.72081 14.6895 3.43952L14.5605 3.31052C14.2792 3.02931 13.8977 2.87134 13.5 2.87134C13.1023 2.87134 12.7208 3.02931 12.4395 3.31052L3.75 12Z"  stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<span></span>
          </div>
        </div>
         </div>
 <div className='d-flex justify-content-between mb-2'>
 <Form.Check 
            type={type}
            id={`default-${type}`}
            label={`Mardi`}
          />
        <div className='d-flex'>
      
           <div className='modify'>
          <svg width="18" height="18" viewBox="0 0 18 18"  xmlns="http://www.w3.org/2000/svg">
<path d="M11.25 4.50002L13.5 6.75002M9.75 15H15.75M3.75 12L3 15L6 14.25L14.6895 5.56052C14.9707 5.27923 15.1287 4.89776 15.1287 4.50002C15.1287 4.10227 14.9707 3.72081 14.6895 3.43952L14.5605 3.31052C14.2792 3.02931 13.8977 2.87134 13.5 2.87134C13.1023 2.87134 12.7208 3.02931 12.4395 3.31052L3.75 12Z"  stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<span></span>
          </div>
        </div>
 </div>
 <div className='d-flex justify-content-between mb-2'>
 <Form.Check 
            type={type}
            id={`default-${type}`}
            label={`Mercredi`}
          />
      <div className='d-flex'>
    
           <div className='modify'>
          <svg width="18" height="18" viewBox="0 0 18 18"  xmlns="http://www.w3.org/2000/svg">
<path d="M11.25 4.50002L13.5 6.75002M9.75 15H15.75M3.75 12L3 15L6 14.25L14.6895 5.56052C14.9707 5.27923 15.1287 4.89776 15.1287 4.50002C15.1287 4.10227 14.9707 3.72081 14.6895 3.43952L14.5605 3.31052C14.2792 3.02931 13.8977 2.87134 13.5 2.87134C13.1023 2.87134 12.7208 3.02931 12.4395 3.31052L3.75 12Z"  stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<span></span>
          </div>
      </div>
 </div>
 <div className='d-flex justify-content-between mb-2'>
 <Form.Check 
            type={type}
            id={`default-${type}`}
            label={`Jeudi`}
          />
          <div className='d-flex'>
         
           <div className='modify'>
          <svg width="18" height="18" viewBox="0 0 18 18"  xmlns="http://www.w3.org/2000/svg">
<path d="M11.25 4.50002L13.5 6.75002M9.75 15H15.75M3.75 12L3 15L6 14.25L14.6895 5.56052C14.9707 5.27923 15.1287 4.89776 15.1287 4.50002C15.1287 4.10227 14.9707 3.72081 14.6895 3.43952L14.5605 3.31052C14.2792 3.02931 13.8977 2.87134 13.5 2.87134C13.1023 2.87134 12.7208 3.02931 12.4395 3.31052L3.75 12Z"  stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<span></span>
          </div>
          </div>
 </div>
 <div className='d-flex justify-content-between mb-2'>
 <Form.Check 
            type={type}
            id={`default-${type}`}
            label={`Vendredi`}
          />
          <div className='d-flex'>
         
           <div className='modify'>
          <svg width="18" height="18" viewBox="0 0 18 18"  xmlns="http://www.w3.org/2000/svg">
<path d="M11.25 4.50002L13.5 6.75002M9.75 15H15.75M3.75 12L3 15L6 14.25L14.6895 5.56052C14.9707 5.27923 15.1287 4.89776 15.1287 4.50002C15.1287 4.10227 14.9707 3.72081 14.6895 3.43952L14.5605 3.31052C14.2792 3.02931 13.8977 2.87134 13.5 2.87134C13.1023 2.87134 12.7208 3.02931 12.4395 3.31052L3.75 12Z"  stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<span></span>
          </div>
          </div>
 </div>
 <div className='d-flex justify-content-between mb-2'>
 <Form.Check 
            type={type}
            id={`default-${type}`}
            label={`Samedi`}
          />
         <div className='d-flex'>
         
           <div className='modify'>
          <svg width="18" height="18" viewBox="0 0 18 18"  xmlns="http://www.w3.org/2000/svg">
<path d="M11.25 4.50002L13.5 6.75002M9.75 15H15.75M3.75 12L3 15L6 14.25L14.6895 5.56052C14.9707 5.27923 15.1287 4.89776 15.1287 4.50002C15.1287 4.10227 14.9707 3.72081 14.6895 3.43952L14.5605 3.31052C14.2792 3.02931 13.8977 2.87134 13.5 2.87134C13.1023 2.87134 12.7208 3.02931 12.4395 3.31052L3.75 12Z"  stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<span></span>
          </div>
         </div>
 </div>
 <div className='d-flex justify-content-between mb-2'>
 <Form.Check 
            type={type}
            id={`default-${type}`}
            label={`Dimanche`}
          />
        <div className='d-flex'>
       
           <div className='modify'>
          <svg width="18" height="18" viewBox="0 0 18 18"  xmlns="http://www.w3.org/2000/svg">
<path d="M11.25 4.50002L13.5 6.75002M9.75 15H15.75M3.75 12L3 15L6 14.25L14.6895 5.56052C14.9707 5.27923 15.1287 4.89776 15.1287 4.50002C15.1287 4.10227 14.9707 3.72081 14.6895 3.43952L14.5605 3.31052C14.2792 3.02931 13.8977 2.87134 13.5 2.87134C13.1023 2.87134 12.7208 3.02931 12.4395 3.31052L3.75 12Z"  stroke-linecap="round" stroke-linejoin="round"/>
</svg>
<span></span>
          </div>
        </div>
 </div>
        </div>
      ))}
            </Form>
            </div>
            
            <div className="submit-btn">
           <button type="button" class="btn btn-secondary btn-main mt-4">Mettre à jour</button>
           </div>
        </div>
        <Modal show={show} onHide={handleClose} className="day-status">
        <Modal.Header closeButton>
          <Modal.Title>Lundi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
                <div className='day-routine'>
                    <h5>Le matin</h5>
                    <div className='rou'>
                        <p><span>06:00 - 07:00</span></p>
                        <p><span>07:00 - 08:00</span></p>
                        <p><span>08:00 - 09:00</span></p>
                        <p><span>09:00 - 10:00</span></p>
                        <p> <span>10:00 - 11:00</span></p>
                        <p><span>11:00 - 12:00</span></p>
                    </div>
                    <h5>Midi</h5>
                    <div className='rou'>
                    <p><span>12:00 -13:00</span></p>
                    <p><span>13:00 - 14:00 </span></p>
                     
                    </div>
                    <h5>L'après midi</h5>
                    <div className='rou'>
                    <p><span>14:00 -15:00</span></p>
                    <p> <span>15:00 - 16:00 </span></p>
                    <p> <span>16:00 - 17:00 </span></p>
                    <p>  <span>17:00 - 18:00 </span></p>
                    </div>
                    <h5>Le soir</h5>
                    <div className='rou'>
                    <p> <span>18:00 -19:00</span></p>
                    <p> <span>20:00 - 21:00 </span></p>
                    <p>  <span>21:00 - 22:00 </span></p>
                    <p>  <span>22:00 - 23:00 </span></p>
                    </div>
                </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary"onClick={handleClose}>
            valider cette journée
          </Button>
          <Button  variant="secondary" className='btn-outline-main'  onClick={handleClose}>
          désactiver ce jour
          </Button>
        </Modal.Footer>
      </Modal>
      <div className='locations'>
      <h3>Emplacement</h3>
       <p>Naviguez sur la carte et cliquez sur les zones de conduite disponibles.<br></br>
       Cliquez ensuite sur « Sélectionner » pour ajouter un secteur à vos favoris.</p>
<Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label>Ma ville</Form.Label>
        <Form.Control type="email" placeholder="" />
      </Form.Group>
      <div className='map'>
      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d83998.96777924348!2d2.2646343994186475!3d48.858825492002346!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e66e1f06e2b70f%3A0x40b82c3688c9460!2sParis%2C%20France!5e0!3m2!1sen!2sin!4v1723810268052!5m2!1sen!2sin" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </div>
      <div className="submit-btn">
           <button type="button" class="btn btn-secondary btn-main mt-4">Mettre à jour</button>
           </div>
      </div>
      <div className='administaror-document'>
        <h3>Documents administratifs</h3>
        <div className='admin-file'>
          <p>Contrat de candidat</p>
          <img src={downloadfile} />
        </div>
      </div>
        </div>
        </div>
    </section>
  )
}

export default Training