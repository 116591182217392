import React, { useEffect, useState } from 'react';
import './style.css';
import trans from '../images/trans.png';
import Pagination from 'react-bootstrap/Pagination';
import { useNavigate } from 'react-router-dom';

// Helper function to fetch transactions
const fetchTransactions = async (token) => {
  try {
    const response = await fetch("/api/students/transactions", {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error fetching transactions:', error);
    return [];
  }
};

function Transaction() {
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1); 
  const [itemsPerPage] = useState(10); 
  const navigate = useNavigate(); 

  useEffect(() => {
    const token = localStorage.getItem('token');

    if (token) {
      fetchTransactions(token)
        .then(data => {
          setTransactions(data);
          setLoading(false);
        })
        .catch(err => {
          setError('Failed to fetch transactions');
          setLoading(false);
        });
    } else {
      setError('No authentication token found');
      setLoading(false);
    }
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowClick = (transactionId) => {
    navigate(`/achats/${transactionId}`);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTransactions = transactions.slice(indexOfFirstItem, indexOfLastItem);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (error) {
    return <p>{error}</p>;
  }

  return (
    <section className='transaction'>
      <div className='container-fluid'>
        <div className='wrapping'>
          <h3>Mes Transactions</h3>

          {transactions.length === 0 ? (
            <div className=''>
              <img src={trans} alt="No Transactions" />
              <p>Aucune transaction pour le moment !</p>
            </div>
          ) : (
            <div className="eva-time table-responsive">
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>S.no</th>
                    <th>Date</th>
                    <th>Méthode</th>
                    <th>Achat</th>
                    <th>Prix</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {currentTransactions.map((transaction, index) => (
                    <tr key={transaction.id} onClick={() => handleRowClick(transaction.id)} style={{ cursor: 'pointer' }}>
                      <td>{index + 1 + (currentPage - 1) * itemsPerPage}</td>
                      <td>{new Date(transaction.date).toLocaleDateString()}</td>
                      <td>{transaction.method}</td>
                      <td>{transaction.achat}</td>
                      <td>{transaction.prix} €</td>
                      <td>{transaction.status}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Pagination */}
          <Pagination className='mb-0'>
            <Pagination.First onClick={() => handlePageChange(1)} disabled={currentPage === 1} />
            <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1} />
            {[...Array(Math.ceil(transactions.length / itemsPerPage))].map((_, pageIndex) => (
              <Pagination.Item
                key={pageIndex + 1}
                active={currentPage === pageIndex + 1}
                onClick={() => handlePageChange(pageIndex + 1)}
              >
                {pageIndex + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === Math.ceil(transactions.length / itemsPerPage)} />
            <Pagination.Last onClick={() => handlePageChange(Math.ceil(transactions.length / itemsPerPage))} disabled={currentPage === Math.ceil(transactions.length / itemsPerPage)} />
          </Pagination>
        </div>
      </div>
    </section>
  );
}

export default Transaction;
