import React, { useState, useEffect } from 'react';
import axiosInstance from "../utils/http/config/axios";
import { useNavigate } from "react-router-dom";
import './style.css';
import back_arrows from '../images/back-arrows.svg';
import rseervation from '../images/rseervation.svg';
import Pagination from 'react-bootstrap/Pagination';

function Myreservation() {
  const [reservations, setReservations] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [cancelLoading, setCancelLoading] = useState(null); 
  const navigate = useNavigate();

  // Pagination State
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  // Function to go back to the previous page
  const goBack = () => {
    navigate(-1);
  };

  // Fetch the student dashboard (reservations) on component mount
  useEffect(() => {
    const fetchStudentDashboard = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Aucun jeton trouvé');
        }

        // Fetch reservations from the dashboard API
        const dashboardResponse = await axiosInstance.get('/api/students/dashboard', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (dashboardResponse.data.success) {
          const { lessons } = dashboardResponse.data.data;
          setReservations(lessons);
        } else {
          // If no lessons are found, display the message from the API
          setError(dashboardResponse.data.message || 'Aucune réservation trouvée');
        }
      } catch (err) {
        console.error(err);
        setError('Une erreur est survenue lors de la récupération des réservations');
      } finally {
        setLoading(false);
      }
    };

    fetchStudentDashboard();
  }, []);

  // Handle lesson cancellation
  const handleCancelLesson = async (lessonId, lessonStatus) => {
    if (lessonStatus === 'réalisé' || lessonStatus === 'en cours' || lessonStatus === 'annulé') {
      alert('La leçon ne peut pas être annulée car elle est déjà réalisée, en cours ou annulée.');
      return;
    }

    if (window.confirm('Êtes-vous sûr de vouloir annuler cette leçon ?')) {
      try {
        setCancelLoading(lessonId); 
        const token = localStorage.getItem('token');
        const response = await axiosInstance.delete(`/api/students/lessons/${lessonId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.data.success) {
          setReservations(prevReservations =>
            prevReservations.filter(reservation => reservation.id !== lessonId)
          );
          alert('Leçon annulée avec succès.');
        } else {
          alert(response.data.message || 'Impossible d\'annuler la leçon.');
        }
      } catch (err) {
        console.error(err);
        alert('Une erreur est survenue lors de l\'annulation de la leçon.');
      } finally {
        setCancelLoading(null);
      }
    }
  };

  if (loading) {
    return <div>Chargement...</div>;
  }

  // If there is an error message (no lessons found or other errors), show the "no reservation" section
  if (error) {
    return (
      <section className='myreservation'>
        <div className='container-fluid'>
          <div className='wrapping'>
            <button type="button" onClick={goBack} className="btn btn-secondary btn-main">
              <img src={back_arrows} alt="Retour" /> Retour
            </button>

            <div className='dont-reservation'>
              <img src={rseervation} alt="rservation" />
              <div className='rser-content'>
                <h3>{error}</h3>
                <button
                  type="button"
                  className="btn btn-secondary btn-main"
                  onClick={() => navigate('/dashboard')} // Redirect to the dashboard or reservation page
                >
                  Réservez maintenant
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }

  // Calculate current reservations to display
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentReservations = reservations.slice(indexOfFirstItem, indexOfLastItem);
  const totalPages = Math.ceil(reservations.length / itemsPerPage);

  return (
    <section className='myreservation'>
      <div className='container-fluid'>
        <div className='wrapping'>
          <button type="button" onClick={goBack} className="btn btn-secondary btn-main">
            <img src={back_arrows} alt="Retour" /> Retour
          </button>

          {/* Reservations table if reservations are available */}
          <>
            <h4>Mes réservations</h4>
            <div className='table-responsive'>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col">Date et heure</th>
                    <th scope="col">Heure</th>
                    <th scope="col">Lieu</th>
                    <th scope="col">Instructeur</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {currentReservations.length > 0 ? (
                    currentReservations.map((reservation) => (
                      <tr key={reservation.id}>
                        <td>{new Date(reservation.timeslot?.date).toLocaleDateString()}</td>
                        <td>{reservation.timeslot?.startTime} - {reservation.timeslot?.endTime}</td>
                        <td>{reservation.location || 'Non spécifiée'}</td>
                        <td>
                          {reservation.timeslot?.instructor?.user?.firstName} {reservation.timeslot?.instructor?.user?.lastName}
                        </td>
                        <td>
                          <button
                            className="btn btn-danger btn-sm"
                            onClick={() => handleCancelLesson(reservation.id, reservation.status)}
                            disabled={cancelLoading === reservation.id || reservation.status === 'réalisé' || reservation.status === 'annulé' || reservation.status === 'en cours'}
                          >
                            {cancelLoading === reservation.id ? 'Annulation...' : 'Annuler'}
                          </button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="5">Aucune réservation trouvée</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>

            {/* Pagination */}
            <Pagination className='mb-0'>
              <Pagination.First onClick={() => setCurrentPage(1)} disabled={currentPage === 1} />
              <Pagination.Prev onClick={() => setCurrentPage(prev => Math.max(prev - 1, 1))} disabled={currentPage === 1} />
              {[...Array(totalPages)].map((_, pageIndex) => (
                <Pagination.Item
                  key={pageIndex + 1}
                  active={currentPage === pageIndex + 1}
                  onClick={() => setCurrentPage(pageIndex + 1)}
                >
                  {pageIndex + 1}
                </Pagination.Item>
              ))}
              <Pagination.Next onClick={() => setCurrentPage(prev => Math.min(prev + 1, totalPages))} disabled={currentPage === totalPages} />
              <Pagination.Last onClick={() => setCurrentPage(totalPages)} disabled={currentPage === totalPages} />
            </Pagination>
          </>
        </div>
      </div>
    </section>
  );
}

export default Myreservation;
