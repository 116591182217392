import React, { useState, useEffect } from "react";
import Spinner from 'react-bootstrap/Spinner';
import { useNavigate } from "react-router-dom";
import { addDays, format, isToday, subDays } from "date-fns";

// Helper function to fetch subscription status from profile API
const fetchProfileData = async (token) => {
  try {
    const response = await fetch('/api/students/profile', {
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Le réseau a rencontré un problème');
    }

    const data = await response.json();
    return data.student.hasSubscription; 
  } catch (error) {
    console.error('Erreur lors de la récupération des informations de profil :', error);
    return false; 
  }
};

const TimeSlots = ({ instructorAvailability, reserveSlots, reserving }) => {
  const MAX_TIME_SLOTS = 5;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [dates, setDates] = useState([]);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState([]);
  const [maxTimeSlotsByDate, setMaxTimeSlotsByDate] = useState({});
  const [hasSubscription, setHasSubscription] = useState(null); 

  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");

    if (token) {
      fetchProfileData(token)
        .then((subscriptionStatus) => {
          setHasSubscription(subscriptionStatus); 
        })
        .catch(() => {
          setHasSubscription(false); 
        });
    }
  }, []);

  useEffect(() => {
    const generateDatesConfig = async () => {
      const dates = await generateDatesHeader(currentDate);
      setDates(dates);
    };
    generateDatesConfig();
  }, [currentDate]);

  useEffect(() => {
    if (dates && dates.length && instructorAvailability) {
      let maxTimeslotsCount = MAX_TIME_SLOTS;
      dates.forEach(date => {
        if (instructorAvailability[date.date]) {
          if (instructorAvailability[date.date].length > maxTimeslotsCount) {
            maxTimeslotsCount = instructorAvailability[date.date].length;
          }
        }
      });

      const maxTimeSlotsByDate = {};
      dates.forEach(date => {
        maxTimeSlotsByDate[date.date] = instructorAvailability && instructorAvailability[date.date] 
          ? maxTimeslotsCount - instructorAvailability[date.date].length 
          : maxTimeslotsCount;
      });
      setMaxTimeSlotsByDate(maxTimeSlotsByDate);
    }
  }, [instructorAvailability, dates]);

  const handleNextWeek = () => {
    setCurrentDate(prevDate => addDays(prevDate, 7));
  };

  const handlePreviousWeek = () => {
    setCurrentDate(prevDate => subDays(prevDate, 7));
  };

  const handleBuySubscription = () => {
    navigate("/boutique"); // Navigate to subscription page
  };

  const generateDatesHeader = async (startDate, options = { dateFormat: "yyyy-MM-dd" }) => {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const currentDate = addDays(startDate, i);
      const dayName = format(currentDate, "EEE");
      const formattedDate = format(currentDate, options.dateFormat);

      dates.push({
        date: formattedDate,
        day: dayName,
      });
    }
    return dates;
  };

  const selectTimeSlot = (slot) => {
    slot.selected = !slot.selected;
    setSelectedTimeSlots(prevSelectedSlots => {
      const timeslots = [...prevSelectedSlots, slot];
      return timeslots.filter(slot => !!slot.selected);
    });
  };

  const renderEmptyTimeslots = (date) => {
    const emptySlots = Array(maxTimeSlotsByDate[date.date]).fill(1);

    return emptySlots.map((_, idx) => {
      return <button key={idx} className="s1" disabled>-</button>;
    });
  };

  const isButtonDisabled = () => {
    return buttonText === "Réserver un créneau" && !selectedTimeSlots.length;
  };

  // Determine the button text once subscription status is known
  const buttonText = hasSubscription === null ? "Chargement..." : hasSubscription ? "Réserver un créneau" : "Acheter un abonnement";

  return (
    <>
      <div className="calc align-items-start">
        <button
          className="prev navigation-btn"
          onClick={handlePreviousWeek}
          disabled={isToday(currentDate)}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="10" cy="10" r="10" fill="#D9D9D9" />
            <path
              d="M12.0837 5.83325L7.91699 9.99992L12.0837 14.1666"
              stroke="black"
            />
          </svg>
        </button>
        <div className="week">
          {dates?.map((day) => (
            <div key={day.date}>
              <span>{day.day}</span>
              <strong>{format(new Date(day.date), "dd MMM")}</strong>
              <div className="slots">
                {instructorAvailability && instructorAvailability[day.date] && instructorAvailability[day.date].map(
                  (availability) => {
                    return (
                      <button
                        key={availability.id}
                        className={`s1 ${availability.selected ? "select" : ""}`}
                        onClick={() => selectTimeSlot(availability)}
                      >
                        {availability?.from}
                      </button>
                    );
                  }
                )}
                {instructorAvailability && maxTimeSlotsByDate && renderEmptyTimeslots(day)}
              </div>
            </div>
          ))}
        </div>

        <button
          className="next navigation-btn"
          onClick={handleNextWeek}
        >
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              cx="10"
              cy="10"
              r="10"
              transform="matrix(-1 0 0 1 20 0)"
              fill="#D9D9D9"
            />
            <path
              d="M7.91634 5.83325L12.083 9.99992L7.91634 14.1666"
              stroke="black"
            />
          </svg>
        </button>
      </div>
      {!instructorAvailability && <div className="no-availiblity-overlay">Pas de disponibilité</div>}
      <div className="res-slots">
      <button
  type="button"
  className="btn btn-dark px-3 py-2"
  disabled={buttonText === "Acheter un abonnement" ? reserving : isButtonDisabled() || reserving}
  onClick={() => {
    if (buttonText === "Acheter un abonnement") {
      handleBuySubscription();
    } else {
      reserveSlots(selectedTimeSlots);  // Handle reservation
    }
  }}
  >
    <div className="d-flex gap-2 align-items-center justify-content-center">
      {reserving && <Spinner variant="light" size="sm" />}
      <span>{buttonText}</span>
    </div>
  </button>

      </div>
    </>
  );
};

export default TimeSlots;
