// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.noti-recent {
    display: flex;
    justify-content: space-between;
}
section.notifications h4 {
    font-family: 'poppins';
}
span.linktoshow {
    color: #c43cab;
    cursor: pointer;
}
.fade.inst-feedback .modal-dialog{
    max-width: 600px;
    width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/Notifications/style.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,8BAA8B;AAClC;AACA;IACI,sBAAsB;AAC1B;AACA;IACI,cAAc;IACd,eAAe;AACnB;AACA;IACI,gBAAgB;IAChB,WAAW;AACf","sourcesContent":[".noti-recent {\n    display: flex;\n    justify-content: space-between;\n}\nsection.notifications h4 {\n    font-family: 'poppins';\n}\nspan.linktoshow {\n    color: #c43cab;\n    cursor: pointer;\n}\n.fade.inst-feedback .modal-dialog{\n    max-width: 600px;\n    width: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
