import React from 'react'
import './style.css'
import { useNavigate } from 'react-router-dom';

function NEPH() {
    const navigate = useNavigate();

    const goBack = () => {
        navigate(-1); // Navigate back to the previous page
    };
  return (
    <section className='neph'>
        <div className='container'>
            <div className='wrapping'>
            <div className='back-btn'>
       <button type="button" onClick={goBack} class="btn btn-secondary btn-main"> <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M16.8055 9.06832C16.8086 9.29332 16.7221 9.5103 16.5651 9.67153C16.4081 9.83276 16.1935 9.92503 15.9685 9.92804L4.44214 10.0823L7.56004 13.1179C7.71205 13.2767 7.79648 13.4883 7.79554 13.7081C7.79461 13.928 7.70838 14.1389 7.55503 14.2964C7.40167 14.4539 7.19317 14.5457 6.97343 14.5526C6.7537 14.5594 6.5399 14.4806 6.37708 14.3329L1.79192 9.86891C1.63092 9.71196 1.53879 9.49753 1.53578 9.27272C1.53278 9.0479 1.61913 8.83108 1.77587 8.66989L6.2399 4.08473C6.39871 3.93272 6.61033 3.84829 6.83016 3.84923C7.05 3.85016 7.26089 3.93639 7.41841 4.08975C7.57592 4.2431 7.66776 4.45161 7.67458 4.67134C7.6814 4.89107 7.60267 5.10487 7.45497 5.2677L4.41942 8.38561L15.9458 8.23132C16.1708 8.2283 16.3878 8.3148 16.549 8.47177C16.7103 8.62874 16.8025 8.84332 16.8055 9.06832Z" fill="white"/>
</svg>
Retour</button>
       </div>
       <div className='top-heading-profile'>
       <h3 className=' ms-0 mb-3'>Votre NEPH</h3>
       </div>
       <div className='form'>
           <div className='row'>
               <div className='col-lg-12'>
          
               <div class="mb-3 copyico">
                <label for="exampleFormControlInput1" class="form-label">NEPH</label>
                <input type="password" class="form-control" id="exampleFormControlInput1"  />
                <div className='copy'>
                <svg width="25" height="25" viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M21.0938 3.125H8.59375C8.38655 3.125 8.18784 3.20731 8.04132 3.35382C7.89481 3.50034 7.8125 3.69905 7.8125 3.90625V7.8125H3.90625C3.69905 7.8125 3.50034 7.89481 3.35382 8.04132C3.20731 8.18784 3.125 8.38655 3.125 8.59375V21.0938C3.125 21.301 3.20731 21.4997 3.35382 21.6462C3.50034 21.7927 3.69905 21.875 3.90625 21.875H16.4062C16.6135 21.875 16.8122 21.7927 16.9587 21.6462C17.1052 21.4997 17.1875 21.301 17.1875 21.0938V17.1875H21.0938C21.301 17.1875 21.4997 17.1052 21.6462 16.9587C21.7927 16.8122 21.875 16.6135 21.875 16.4062V3.90625C21.875 3.69905 21.7927 3.50034 21.6462 3.35382C21.4997 3.20731 21.301 3.125 21.0938 3.125ZM15.625 20.3125H4.6875V9.375H15.625V20.3125ZM20.3125 15.625H17.1875V8.59375C17.1875 8.38655 17.1052 8.18784 16.9587 8.04132C16.8122 7.89481 16.6135 7.8125 16.4062 7.8125H9.375V4.6875H20.3125V15.625Z" fill="#393939"/>
</svg>
                </div>
                </div>
             
               </div>
           </div>
       </div>
       <div className='btn-bottom text-end nept-btns'>
       <button type="button" class="btn btn-secondary btn-main">Mettre à jour</button>
       <button type="button" class="btn btn-outline-secondary btn-outline-main">Réservez un cours</button>
       </div>
            </div>
        </div>
    </section>
  )
}

export default NEPH