import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import { useLocation, useParams, Link } from 'react-router-dom';
import "./style.css"
import proimg from "../images/instproimg.png"
import email from "../images/email.svg"
import stucall from "../images/stucall.svg"
import stuchat from "../images/stuchat.svg"
import callphn from "../images/call-phn.svg"
import emailinst from "../images/emial-inst.svg"
import loct from "../images/loct.svg"
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import highschool from "../images/highschool.svg"
import meter from "../images/meter.svg"
import Form from 'react-bootstrap/Form';
import userpic from "../images/user-pic.png"

function Intructorprofile() {
  const [show, setShow] = useState(false);
  const { instructorId } = useParams();
  const location = useLocation();
  const instructor = location.state?.instructor;

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
     <Breadcrumb>
      <Breadcrumb.Item className='breadcrumb'><Link to="/dashboard">Tableau de bord</Link></Breadcrumb.Item>
      <Breadcrumb.Item >
      Profil d'instructeur
      </Breadcrumb.Item>
    </Breadcrumb>
    <div className='row'>
        <div className='col-lg-4'>
            <div className='side-pro-wrap'>
               <div className='text-center mb-3'> <img src={proimg} /></div>
              <div className='text-center'>
              <h4>{instructor ? `${instructor.firstName} ${instructor.lastName}` : "Profil de l'instructeur"}</h4>
                <strong>instructor</strong>
              </div>
              <div className='social-icons'>
                  <img src={email} />
                  <img src={stucall} />
                  <img src={stuchat} />
              </div>
              <hr></hr>
              <h3>Other information</h3>
              <div className='phone'>
                  <img src={callphn} />
                  <div>
                      <h5>Phone number: </h5>
                      <span>+748348834757</span>
                  </div>
              </div>
              <div className='phone'>
                  <img src={emailinst} />
                  <div>
                      <h5>Email Address: </h5>
                      <span>ayoog71@gmail.com</span>
                  </div>
              </div>
              <div className='phone'>
                  <img src={loct} />
                  <div>
                      <h5>Location:  </h5>
                      <span>49 Quai des Belges, Meaux, Île-de-France</span>
                  </div>
              </div>
            </div>
        </div>
        <div className='col-lg-8'>
       <div className='bg-white p-4 pro-document'>
       <Tabs
      defaultActiveKey="profile"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="home" title="Certifications">
      <div className='certification'>
          <h3>Qualification</h3>
          <div className='list'>
            <div className='d-flex block'>
                <img src={highschool} />
                <strong>High school diploma</strong>
            </div>
            <div className='d-block block'>
                <strong>Passed out year</strong><br></br>
                <span>10/20</span>
            </div>
            <p className='mb-0 blocks'>Completed</p>
          </div>
          <h3 className='mt-3'>Certification</h3>
          <div className='list'>
            <div className='d-flex block'>
                <img src={meter} />
                <strong>Driving training program</strong>
            </div>
            <div className='d-block block'>
                <strong>Passed out year</strong><br></br>
                <span>10/20</span>
            </div>
            <p className='mb-0 blocks'>Completed</p>
          </div>
          <div className='list mt-3'>
            <div className='d-flex block'>
                <img src={meter} />
                <strong>First Aid and CPR Certification</strong>
            </div>
            <div className='d-block block'>
                <strong>Passed out year</strong><br></br>
                <span>10/20</span>
            </div>
            <p className='mb-0 blocks'>Completed</p>
          </div>
      </div>
      </Tab>
      <Tab eventKey="profile" title="Créneau horaire">
    <div className='time-slots'>
    <Tabs
      defaultActiveKey="profile"
      id="uncontrolled-tab-example"
      className="mb-3"
    >
      <Tab eventKey="home" title="Monday">
    <div className='days'>
        <div className='day-time'>
            <h5>Le matin</h5>
            <div className='sl'>
            <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="06h00 - 07h00"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <Form.Check
            inline
            label="07h00 - 08h00"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
          <Form.Check
            inline
            label="08h00 - 09h00"
            type={type}
            id={`inline-${type}-3`}
          />
           <Form.Check
            inline
            label="09h00 - 10h00"
            type={type}
            id={`inline-${type}-4`}
          />
        </div>
      ))}
    </Form>
            </div>
        </div>
        <div className='day-time'>
            <h5>Midi</h5>
            <div className='sl'>
            <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="12h00 - 13h00"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <Form.Check
            inline
            label="13h00 - 14h00 "
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
        
        </div>
      ))}
    </Form>
            </div>
        </div>
        <div className='day-time'>
            <h5>L'après-midi</h5>
            <div className='sl'>
            <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="14h00 - 15h00"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <Form.Check
            inline
            label="15h00 - 16h00"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
        
        </div>
      ))}
    </Form>
            </div>
        </div>
        <div className='day-time'>
            <h5>Le soir</h5>
            <div className='sl'>
            <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="18h00 - 19h00"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <Form.Check
            inline
            label="19h00 - 20h00"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
        
        </div>
      ))}
    </Form>
            </div>
        </div>
    </div>
      </Tab>
      <Tab eventKey="profile" title="Tuesday">
        <div className='days'>
        <div className='day-time'>
            <h5>Le matin</h5>
            <div className='sl'>
            <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="06h00 - 07h00"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <Form.Check
            inline
            label="07h00 - 08h00"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
          <Form.Check
            inline
            label="08h00 - 09h00"
            type={type}
            id={`inline-${type}-3`}
          />
           <Form.Check
            inline
            label="09h00 - 10h00"
            type={type}
            id={`inline-${type}-4`}
          />
        </div>
      ))}
    </Form>
            </div>
        </div>
        <div className='day-time'>
            <h5>Midi</h5>
            <div className='sl'>
            <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="12h00 - 13h00"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <Form.Check
            inline
            label="13h00 - 14h00 "
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
        
        </div>
      ))}
    </Form>
            </div>
        </div>
        <div className='day-time'>
            <h5>L'après-midi</h5>
            <div className='sl'>
            <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="14h00 - 15h00"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <Form.Check
            inline
            label="15h00 - 16h00"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
        
        </div>
      ))}
    </Form>
            </div>
        </div>
        <div className='day-time'>
            <h5>Le soir</h5>
            <div className='sl'>
            <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="18h00 - 19h00"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <Form.Check
            inline
            label="19h00 - 20h00"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
        
        </div>
      ))}
    </Form>
            </div>
        </div>
    </div>
      </Tab>
      <Tab eventKey="contact" title="Wednesday" >
      <div className='days'>
        <div className='day-time'>
            <h5>Le matin</h5>
            <div className='sl'>
            <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="06h00 - 07h00"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <Form.Check
            inline
            label="07h00 - 08h00"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
          <Form.Check
            inline
            label="08h00 - 09h00"
            type={type}
            id={`inline-${type}-3`}
          />
           <Form.Check
            inline
            label="09h00 - 10h00"
            type={type}
            id={`inline-${type}-4`}
          />
        </div>
      ))}
    </Form>
            </div>
        </div>
        <div className='day-time'>
            <h5>Midi</h5>
            <div className='sl'>
            <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="12h00 - 13h00"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <Form.Check
            inline
            label="13h00 - 14h00 "
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
        
        </div>
      ))}
    </Form>
            </div>
        </div>
        <div className='day-time'>
            <h5>L'après-midi</h5>
            <div className='sl'>
            <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="14h00 - 15h00"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <Form.Check
            inline
            label="15h00 - 16h00"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
        
        </div>
      ))}
    </Form>
            </div>
        </div>
        <div className='day-time'>
            <h5>Le soir</h5>
            <div className='sl'>
            <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="18h00 - 19h00"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <Form.Check
            inline
            label="19h00 - 20h00"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
        
        </div>
      ))}
    </Form>
            </div>
        </div>
    </div>
      </Tab>
      <Tab eventKey="thur" title="Thursday" >
      <div className='days'>
        <div className='day-time'>
            <h5>Le matin</h5>
            <div className='sl'>
            <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="06h00 - 07h00"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <Form.Check
            inline
            label="07h00 - 08h00"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
          <Form.Check
            inline
            label="08h00 - 09h00"
            type={type}
            id={`inline-${type}-3`}
          />
           <Form.Check
            inline
            label="09h00 - 10h00"
            type={type}
            id={`inline-${type}-4`}
          />
        </div>
      ))}
    </Form>
            </div>
        </div>
        <div className='day-time'>
            <h5>Midi</h5>
            <div className='sl'>
            <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="12h00 - 13h00"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <Form.Check
            inline
            label="13h00 - 14h00 "
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
        
        </div>
      ))}
    </Form>
            </div>
        </div>
        <div className='day-time'>
            <h5>L'après-midi</h5>
            <div className='sl'>
            <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="14h00 - 15h00"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <Form.Check
            inline
            label="15h00 - 16h00"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
        
        </div>
      ))}
    </Form>
            </div>
        </div>
        <div className='day-time'>
            <h5>Le soir</h5>
            <div className='sl'>
            <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="18h00 - 19h00"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <Form.Check
            inline
            label="19h00 - 20h00"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
        
        </div>
      ))}
    </Form>
            </div>
        </div>
    </div>
      </Tab>
      <Tab eventKey="fri" title="Friday" >
      <div className='days'>
        <div className='day-time'>
            <h5>Le matin</h5>
            <div className='sl'>
            <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="06h00 - 07h00"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <Form.Check
            inline
            label="07h00 - 08h00"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
          <Form.Check
            inline
            label="08h00 - 09h00"
            type={type}
            id={`inline-${type}-3`}
          />
           <Form.Check
            inline
            label="09h00 - 10h00"
            type={type}
            id={`inline-${type}-4`}
          />
        </div>
      ))}
    </Form>
            </div>
        </div>
        <div className='day-time'>
            <h5>Midi</h5>
            <div className='sl'>
            <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="12h00 - 13h00"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <Form.Check
            inline
            label="13h00 - 14h00 "
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
        
        </div>
      ))}
    </Form>
            </div>
        </div>
        <div className='day-time'>
            <h5>L'après-midi</h5>
            <div className='sl'>
            <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="14h00 - 15h00"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <Form.Check
            inline
            label="15h00 - 16h00"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
        
        </div>
      ))}
    </Form>
            </div>
        </div>
        <div className='day-time'>
            <h5>Le soir</h5>
            <div className='sl'>
            <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="18h00 - 19h00"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <Form.Check
            inline
            label="19h00 - 20h00"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
        
        </div>
      ))}
    </Form>
            </div>
        </div>
    </div>
      </Tab>
      <Tab eventKey="sat" title="Saturday" >
      <div className='days'>
        <div className='day-time'>
            <h5>Le matin</h5>
            <div className='sl'>
            <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="06h00 - 07h00"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <Form.Check
            inline
            label="07h00 - 08h00"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
          <Form.Check
            inline
            label="08h00 - 09h00"
            type={type}
            id={`inline-${type}-3`}
          />
           <Form.Check
            inline
            label="09h00 - 10h00"
            type={type}
            id={`inline-${type}-4`}
          />
        </div>
      ))}
    </Form>
            </div>
        </div>
        <div className='day-time'>
            <h5>Midi</h5>
            <div className='sl'>
            <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="12h00 - 13h00"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <Form.Check
            inline
            label="13h00 - 14h00 "
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
        
        </div>
      ))}
    </Form>
            </div>
        </div>
        <div className='day-time'>
            <h5>L'après-midi</h5>
            <div className='sl'>
            <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="14h00 - 15h00"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <Form.Check
            inline
            label="15h00 - 16h00"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
        
        </div>
      ))}
    </Form>
            </div>
        </div>
        <div className='day-time'>
            <h5>Le soir</h5>
            <div className='sl'>
            <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="18h00 - 19h00"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <Form.Check
            inline
            label="19h00 - 20h00"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
        
        </div>
      ))}
    </Form>
            </div>
        </div>
    </div>
      </Tab>
      <Tab eventKey="sun" title="Sunday" >
      <div className='days'>
        <div className='day-time'>
            <h5>Le matin</h5>
            <div className='sl'>
            <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="06h00 - 07h00"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <Form.Check
            inline
            label="07h00 - 08h00"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
          <Form.Check
            inline
            label="08h00 - 09h00"
            type={type}
            id={`inline-${type}-3`}
          />
           <Form.Check
            inline
            label="09h00 - 10h00"
            type={type}
            id={`inline-${type}-4`}
          />
        </div>
      ))}
    </Form>
            </div>
        </div>
        <div className='day-time'>
            <h5>Midi</h5>
            <div className='sl'>
            <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="12h00 - 13h00"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <Form.Check
            inline
            label="13h00 - 14h00 "
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
        
        </div>
      ))}
    </Form>
            </div>
        </div>
        <div className='day-time'>
            <h5>L'après-midi</h5>
            <div className='sl'>
            <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="14h00 - 15h00"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <Form.Check
            inline
            label="15h00 - 16h00"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
        
        </div>
      ))}
    </Form>
            </div>
        </div>
        <div className='day-time'>
            <h5>Le soir</h5>
            <div className='sl'>
            <Form>
      {['checkbox'].map((type) => (
        <div key={`inline-${type}`} className="mb-3">
          <Form.Check
            inline
            label="18h00 - 19h00"
            name="group1"
            type={type}
            id={`inline-${type}-1`}
          />
          <Form.Check
            inline
            label="19h00 - 20h00"
            name="group1"
            type={type}
            id={`inline-${type}-2`}
          />
        
        </div>
      ))}
    </Form>
            </div>
        </div>
    </div>
      </Tab>
    </Tabs>
    <div className='px-3 text-end'>
    <Button variant="success" onClick={handleShow}>Reserve maintenant</Button>
    </div>
    </div>
      </Tab>
      <Tab eventKey="contact" title="Avis" >
     <div className='reviews'>
       <h3>Recent Reviews</h3>
       <div className='rev'>
         <div className='revby'>
          <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex justify-content-between align-items-center'>
          <img src={userpic} />
          <div className='ps-2'>
          <h5 className='mb-0'>Florencia D</h5>
            <p className='mb-0'>Étudiante</p>
          </div>
          </div>
         <div>
         <h6>Date & time</h6>
          <strong>2 juin, 13h00</strong>
         </div>
          </div>
          <hr></hr>
          <div className='rev-pts'>
            <div className='pts'>
                <p className='mb-0'>1. Qualité de l'enseignement</p>
                <div className='rating'>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.7169 5.85307L9.50779 7.75927L10.1808 10.61C10.218 10.7647 10.2084 10.927 10.1534 11.0763C10.0983 11.2257 10.0002 11.3553 9.87153 11.4489C9.74283 11.5425 9.58927 11.5959 9.43025 11.6023C9.27123 11.6087 9.11389 11.5678 8.9781 11.4848L6.499 9.95904L4.01843 11.4848C3.88266 11.5673 3.72551 11.6078 3.56677 11.6012C3.40802 11.5946 3.25479 11.5411 3.12635 11.4476C2.99792 11.3541 2.90003 11.2246 2.84501 11.0756C2.79 10.9265 2.78031 10.7645 2.81718 10.61L3.49267 7.75927L1.28357 5.85307C1.16345 5.74925 1.07657 5.61233 1.03379 5.45943C0.991009 5.30653 0.994224 5.14441 1.04303 4.99332C1.09184 4.84223 1.18408 4.70887 1.30823 4.6099C1.43238 4.51092 1.58294 4.45071 1.7411 4.43679L4.63747 4.20312L5.75478 1.49918C5.81526 1.35182 5.91819 1.22577 6.05049 1.13706C6.1828 1.04834 6.33849 1.00098 6.49778 1.00098C6.65707 1.00098 6.81276 1.04834 6.94506 1.13706C7.07736 1.22577 7.18029 1.35182 7.24077 1.49918L8.35759 4.20312L11.254 4.43679C11.4124 4.45019 11.5634 4.51007 11.688 4.60891C11.8126 4.70775 11.9053 4.84116 11.9544 4.99243C12.0035 5.1437 12.0069 5.3061 11.9641 5.45928C11.9214 5.61247 11.8343 5.74962 11.7139 5.85356L11.7169 5.85307Z" fill="#FFA800"/>
              </svg>
                <strong>4.5</strong>
                </div>
            </div>
            <div className='pts'>
                <p className='mb-0'>2. Attitude et comportement du moniteur</p>
                <div className='rating'>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.7169 5.85307L9.50779 7.75927L10.1808 10.61C10.218 10.7647 10.2084 10.927 10.1534 11.0763C10.0983 11.2257 10.0002 11.3553 9.87153 11.4489C9.74283 11.5425 9.58927 11.5959 9.43025 11.6023C9.27123 11.6087 9.11389 11.5678 8.9781 11.4848L6.499 9.95904L4.01843 11.4848C3.88266 11.5673 3.72551 11.6078 3.56677 11.6012C3.40802 11.5946 3.25479 11.5411 3.12635 11.4476C2.99792 11.3541 2.90003 11.2246 2.84501 11.0756C2.79 10.9265 2.78031 10.7645 2.81718 10.61L3.49267 7.75927L1.28357 5.85307C1.16345 5.74925 1.07657 5.61233 1.03379 5.45943C0.991009 5.30653 0.994224 5.14441 1.04303 4.99332C1.09184 4.84223 1.18408 4.70887 1.30823 4.6099C1.43238 4.51092 1.58294 4.45071 1.7411 4.43679L4.63747 4.20312L5.75478 1.49918C5.81526 1.35182 5.91819 1.22577 6.05049 1.13706C6.1828 1.04834 6.33849 1.00098 6.49778 1.00098C6.65707 1.00098 6.81276 1.04834 6.94506 1.13706C7.07736 1.22577 7.18029 1.35182 7.24077 1.49918L8.35759 4.20312L11.254 4.43679C11.4124 4.45019 11.5634 4.51007 11.688 4.60891C11.8126 4.70775 11.9053 4.84116 11.9544 4.99243C12.0035 5.1437 12.0069 5.3061 11.9641 5.45928C11.9214 5.61247 11.8343 5.74962 11.7139 5.85356L11.7169 5.85307Z" fill="#FFA800"/>
              </svg>
                <strong>4.5</strong>
                </div>
            </div>
            <div className='pts'>
                <p className='mb-0'>3. Réactions et réflexes</p>
                <div className='rating'>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.7169 5.85307L9.50779 7.75927L10.1808 10.61C10.218 10.7647 10.2084 10.927 10.1534 11.0763C10.0983 11.2257 10.0002 11.3553 9.87153 11.4489C9.74283 11.5425 9.58927 11.5959 9.43025 11.6023C9.27123 11.6087 9.11389 11.5678 8.9781 11.4848L6.499 9.95904L4.01843 11.4848C3.88266 11.5673 3.72551 11.6078 3.56677 11.6012C3.40802 11.5946 3.25479 11.5411 3.12635 11.4476C2.99792 11.3541 2.90003 11.2246 2.84501 11.0756C2.79 10.9265 2.78031 10.7645 2.81718 10.61L3.49267 7.75927L1.28357 5.85307C1.16345 5.74925 1.07657 5.61233 1.03379 5.45943C0.991009 5.30653 0.994224 5.14441 1.04303 4.99332C1.09184 4.84223 1.18408 4.70887 1.30823 4.6099C1.43238 4.51092 1.58294 4.45071 1.7411 4.43679L4.63747 4.20312L5.75478 1.49918C5.81526 1.35182 5.91819 1.22577 6.05049 1.13706C6.1828 1.04834 6.33849 1.00098 6.49778 1.00098C6.65707 1.00098 6.81276 1.04834 6.94506 1.13706C7.07736 1.22577 7.18029 1.35182 7.24077 1.49918L8.35759 4.20312L11.254 4.43679C11.4124 4.45019 11.5634 4.51007 11.688 4.60891C11.8126 4.70775 11.9053 4.84116 11.9544 4.99243C12.0035 5.1437 12.0069 5.3061 11.9641 5.45928C11.9214 5.61247 11.8343 5.74962 11.7139 5.85356L11.7169 5.85307Z" fill="#FFA800"/>
              </svg>
                <strong>4.5</strong>
                </div>
            </div>
          </div>
         </div>
         <div className='revby'>
          <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex justify-content-between align-items-center'>
          <img src={userpic} />
          <div className='ps-2'>
          <h5 className='mb-0'>Florencia D</h5>
            <p className='mb-0'>Étudiante</p>
          </div>
          </div>
         <div>
         <h6>Date & time</h6>
          <strong>2 juin, 13h00</strong>
         </div>
          </div>
          <hr></hr>
          <div className='rev-pts'>
            <div className='pts'>
                <p className='mb-0'>1. Qualité de l'enseignement</p>
                <div className='rating'>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.7169 5.85307L9.50779 7.75927L10.1808 10.61C10.218 10.7647 10.2084 10.927 10.1534 11.0763C10.0983 11.2257 10.0002 11.3553 9.87153 11.4489C9.74283 11.5425 9.58927 11.5959 9.43025 11.6023C9.27123 11.6087 9.11389 11.5678 8.9781 11.4848L6.499 9.95904L4.01843 11.4848C3.88266 11.5673 3.72551 11.6078 3.56677 11.6012C3.40802 11.5946 3.25479 11.5411 3.12635 11.4476C2.99792 11.3541 2.90003 11.2246 2.84501 11.0756C2.79 10.9265 2.78031 10.7645 2.81718 10.61L3.49267 7.75927L1.28357 5.85307C1.16345 5.74925 1.07657 5.61233 1.03379 5.45943C0.991009 5.30653 0.994224 5.14441 1.04303 4.99332C1.09184 4.84223 1.18408 4.70887 1.30823 4.6099C1.43238 4.51092 1.58294 4.45071 1.7411 4.43679L4.63747 4.20312L5.75478 1.49918C5.81526 1.35182 5.91819 1.22577 6.05049 1.13706C6.1828 1.04834 6.33849 1.00098 6.49778 1.00098C6.65707 1.00098 6.81276 1.04834 6.94506 1.13706C7.07736 1.22577 7.18029 1.35182 7.24077 1.49918L8.35759 4.20312L11.254 4.43679C11.4124 4.45019 11.5634 4.51007 11.688 4.60891C11.8126 4.70775 11.9053 4.84116 11.9544 4.99243C12.0035 5.1437 12.0069 5.3061 11.9641 5.45928C11.9214 5.61247 11.8343 5.74962 11.7139 5.85356L11.7169 5.85307Z" fill="#FFA800"/>
              </svg>
                <strong>4.5</strong>
                </div>
            </div>
            <div className='pts'>
                <p className='mb-0'>2. Attitude et comportement du moniteur</p>
                <div className='rating'>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.7169 5.85307L9.50779 7.75927L10.1808 10.61C10.218 10.7647 10.2084 10.927 10.1534 11.0763C10.0983 11.2257 10.0002 11.3553 9.87153 11.4489C9.74283 11.5425 9.58927 11.5959 9.43025 11.6023C9.27123 11.6087 9.11389 11.5678 8.9781 11.4848L6.499 9.95904L4.01843 11.4848C3.88266 11.5673 3.72551 11.6078 3.56677 11.6012C3.40802 11.5946 3.25479 11.5411 3.12635 11.4476C2.99792 11.3541 2.90003 11.2246 2.84501 11.0756C2.79 10.9265 2.78031 10.7645 2.81718 10.61L3.49267 7.75927L1.28357 5.85307C1.16345 5.74925 1.07657 5.61233 1.03379 5.45943C0.991009 5.30653 0.994224 5.14441 1.04303 4.99332C1.09184 4.84223 1.18408 4.70887 1.30823 4.6099C1.43238 4.51092 1.58294 4.45071 1.7411 4.43679L4.63747 4.20312L5.75478 1.49918C5.81526 1.35182 5.91819 1.22577 6.05049 1.13706C6.1828 1.04834 6.33849 1.00098 6.49778 1.00098C6.65707 1.00098 6.81276 1.04834 6.94506 1.13706C7.07736 1.22577 7.18029 1.35182 7.24077 1.49918L8.35759 4.20312L11.254 4.43679C11.4124 4.45019 11.5634 4.51007 11.688 4.60891C11.8126 4.70775 11.9053 4.84116 11.9544 4.99243C12.0035 5.1437 12.0069 5.3061 11.9641 5.45928C11.9214 5.61247 11.8343 5.74962 11.7139 5.85356L11.7169 5.85307Z" fill="#FFA800"/>
              </svg>
                <strong>4.5</strong>
                </div>
            </div>
            <div className='pts'>
                <p className='mb-0'>3. Réactions et réflexes</p>
                <div className='rating'>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.7169 5.85307L9.50779 7.75927L10.1808 10.61C10.218 10.7647 10.2084 10.927 10.1534 11.0763C10.0983 11.2257 10.0002 11.3553 9.87153 11.4489C9.74283 11.5425 9.58927 11.5959 9.43025 11.6023C9.27123 11.6087 9.11389 11.5678 8.9781 11.4848L6.499 9.95904L4.01843 11.4848C3.88266 11.5673 3.72551 11.6078 3.56677 11.6012C3.40802 11.5946 3.25479 11.5411 3.12635 11.4476C2.99792 11.3541 2.90003 11.2246 2.84501 11.0756C2.79 10.9265 2.78031 10.7645 2.81718 10.61L3.49267 7.75927L1.28357 5.85307C1.16345 5.74925 1.07657 5.61233 1.03379 5.45943C0.991009 5.30653 0.994224 5.14441 1.04303 4.99332C1.09184 4.84223 1.18408 4.70887 1.30823 4.6099C1.43238 4.51092 1.58294 4.45071 1.7411 4.43679L4.63747 4.20312L5.75478 1.49918C5.81526 1.35182 5.91819 1.22577 6.05049 1.13706C6.1828 1.04834 6.33849 1.00098 6.49778 1.00098C6.65707 1.00098 6.81276 1.04834 6.94506 1.13706C7.07736 1.22577 7.18029 1.35182 7.24077 1.49918L8.35759 4.20312L11.254 4.43679C11.4124 4.45019 11.5634 4.51007 11.688 4.60891C11.8126 4.70775 11.9053 4.84116 11.9544 4.99243C12.0035 5.1437 12.0069 5.3061 11.9641 5.45928C11.9214 5.61247 11.8343 5.74962 11.7139 5.85356L11.7169 5.85307Z" fill="#FFA800"/>
              </svg>
                <strong>4.5</strong>
                </div>
            </div>
          </div>
         </div>
         <div className='revby'>
          <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex justify-content-between align-items-center'>
          <img src={userpic} />
          <div className='ps-2'>
          <h5 className='mb-0'>Florencia D</h5>
            <p className='mb-0'>Étudiante</p>
          </div>
          </div>
         <div>
         <h6>Date & time</h6>
          <strong>2 juin, 13h00</strong>
         </div>
          </div>
          <hr></hr>
          <div className='rev-pts'>
            <div className='pts'>
                <p className='mb-0'>1. Qualité de l'enseignement</p>
                <div className='rating'>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.7169 5.85307L9.50779 7.75927L10.1808 10.61C10.218 10.7647 10.2084 10.927 10.1534 11.0763C10.0983 11.2257 10.0002 11.3553 9.87153 11.4489C9.74283 11.5425 9.58927 11.5959 9.43025 11.6023C9.27123 11.6087 9.11389 11.5678 8.9781 11.4848L6.499 9.95904L4.01843 11.4848C3.88266 11.5673 3.72551 11.6078 3.56677 11.6012C3.40802 11.5946 3.25479 11.5411 3.12635 11.4476C2.99792 11.3541 2.90003 11.2246 2.84501 11.0756C2.79 10.9265 2.78031 10.7645 2.81718 10.61L3.49267 7.75927L1.28357 5.85307C1.16345 5.74925 1.07657 5.61233 1.03379 5.45943C0.991009 5.30653 0.994224 5.14441 1.04303 4.99332C1.09184 4.84223 1.18408 4.70887 1.30823 4.6099C1.43238 4.51092 1.58294 4.45071 1.7411 4.43679L4.63747 4.20312L5.75478 1.49918C5.81526 1.35182 5.91819 1.22577 6.05049 1.13706C6.1828 1.04834 6.33849 1.00098 6.49778 1.00098C6.65707 1.00098 6.81276 1.04834 6.94506 1.13706C7.07736 1.22577 7.18029 1.35182 7.24077 1.49918L8.35759 4.20312L11.254 4.43679C11.4124 4.45019 11.5634 4.51007 11.688 4.60891C11.8126 4.70775 11.9053 4.84116 11.9544 4.99243C12.0035 5.1437 12.0069 5.3061 11.9641 5.45928C11.9214 5.61247 11.8343 5.74962 11.7139 5.85356L11.7169 5.85307Z" fill="#FFA800"/>
              </svg>
                <strong>4.5</strong>
                </div>
            </div>
            <div className='pts'>
                <p className='mb-0'>2. Attitude et comportement du moniteur</p>
                <div className='rating'>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.7169 5.85307L9.50779 7.75927L10.1808 10.61C10.218 10.7647 10.2084 10.927 10.1534 11.0763C10.0983 11.2257 10.0002 11.3553 9.87153 11.4489C9.74283 11.5425 9.58927 11.5959 9.43025 11.6023C9.27123 11.6087 9.11389 11.5678 8.9781 11.4848L6.499 9.95904L4.01843 11.4848C3.88266 11.5673 3.72551 11.6078 3.56677 11.6012C3.40802 11.5946 3.25479 11.5411 3.12635 11.4476C2.99792 11.3541 2.90003 11.2246 2.84501 11.0756C2.79 10.9265 2.78031 10.7645 2.81718 10.61L3.49267 7.75927L1.28357 5.85307C1.16345 5.74925 1.07657 5.61233 1.03379 5.45943C0.991009 5.30653 0.994224 5.14441 1.04303 4.99332C1.09184 4.84223 1.18408 4.70887 1.30823 4.6099C1.43238 4.51092 1.58294 4.45071 1.7411 4.43679L4.63747 4.20312L5.75478 1.49918C5.81526 1.35182 5.91819 1.22577 6.05049 1.13706C6.1828 1.04834 6.33849 1.00098 6.49778 1.00098C6.65707 1.00098 6.81276 1.04834 6.94506 1.13706C7.07736 1.22577 7.18029 1.35182 7.24077 1.49918L8.35759 4.20312L11.254 4.43679C11.4124 4.45019 11.5634 4.51007 11.688 4.60891C11.8126 4.70775 11.9053 4.84116 11.9544 4.99243C12.0035 5.1437 12.0069 5.3061 11.9641 5.45928C11.9214 5.61247 11.8343 5.74962 11.7139 5.85356L11.7169 5.85307Z" fill="#FFA800"/>
              </svg>
                <strong>4.5</strong>
                </div>
            </div>
            <div className='pts'>
                <p className='mb-0'>3. Réactions et réflexes</p>
                <div className='rating'>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.7169 5.85307L9.50779 7.75927L10.1808 10.61C10.218 10.7647 10.2084 10.927 10.1534 11.0763C10.0983 11.2257 10.0002 11.3553 9.87153 11.4489C9.74283 11.5425 9.58927 11.5959 9.43025 11.6023C9.27123 11.6087 9.11389 11.5678 8.9781 11.4848L6.499 9.95904L4.01843 11.4848C3.88266 11.5673 3.72551 11.6078 3.56677 11.6012C3.40802 11.5946 3.25479 11.5411 3.12635 11.4476C2.99792 11.3541 2.90003 11.2246 2.84501 11.0756C2.79 10.9265 2.78031 10.7645 2.81718 10.61L3.49267 7.75927L1.28357 5.85307C1.16345 5.74925 1.07657 5.61233 1.03379 5.45943C0.991009 5.30653 0.994224 5.14441 1.04303 4.99332C1.09184 4.84223 1.18408 4.70887 1.30823 4.6099C1.43238 4.51092 1.58294 4.45071 1.7411 4.43679L4.63747 4.20312L5.75478 1.49918C5.81526 1.35182 5.91819 1.22577 6.05049 1.13706C6.1828 1.04834 6.33849 1.00098 6.49778 1.00098C6.65707 1.00098 6.81276 1.04834 6.94506 1.13706C7.07736 1.22577 7.18029 1.35182 7.24077 1.49918L8.35759 4.20312L11.254 4.43679C11.4124 4.45019 11.5634 4.51007 11.688 4.60891C11.8126 4.70775 11.9053 4.84116 11.9544 4.99243C12.0035 5.1437 12.0069 5.3061 11.9641 5.45928C11.9214 5.61247 11.8343 5.74962 11.7139 5.85356L11.7169 5.85307Z" fill="#FFA800"/>
              </svg>
                <strong>4.5</strong>
                </div>
            </div>
          </div>
         </div>
         <div className='revby'>
          <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex justify-content-between align-items-center'>
          <img src={userpic} />
          <div className='ps-2'>
          <h5 className='mb-0'>Florencia D</h5>
            <p className='mb-0'>Étudiante</p>
          </div>
          </div>
         <div>
         <h6>Date & time</h6>
          <strong>2 juin, 13h00</strong>
         </div>
          </div>
          <hr></hr>
          <div className='rev-pts'>
            <div className='pts'>
                <p className='mb-0'>1. Qualité de l'enseignement</p>
                <div className='rating'>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.7169 5.85307L9.50779 7.75927L10.1808 10.61C10.218 10.7647 10.2084 10.927 10.1534 11.0763C10.0983 11.2257 10.0002 11.3553 9.87153 11.4489C9.74283 11.5425 9.58927 11.5959 9.43025 11.6023C9.27123 11.6087 9.11389 11.5678 8.9781 11.4848L6.499 9.95904L4.01843 11.4848C3.88266 11.5673 3.72551 11.6078 3.56677 11.6012C3.40802 11.5946 3.25479 11.5411 3.12635 11.4476C2.99792 11.3541 2.90003 11.2246 2.84501 11.0756C2.79 10.9265 2.78031 10.7645 2.81718 10.61L3.49267 7.75927L1.28357 5.85307C1.16345 5.74925 1.07657 5.61233 1.03379 5.45943C0.991009 5.30653 0.994224 5.14441 1.04303 4.99332C1.09184 4.84223 1.18408 4.70887 1.30823 4.6099C1.43238 4.51092 1.58294 4.45071 1.7411 4.43679L4.63747 4.20312L5.75478 1.49918C5.81526 1.35182 5.91819 1.22577 6.05049 1.13706C6.1828 1.04834 6.33849 1.00098 6.49778 1.00098C6.65707 1.00098 6.81276 1.04834 6.94506 1.13706C7.07736 1.22577 7.18029 1.35182 7.24077 1.49918L8.35759 4.20312L11.254 4.43679C11.4124 4.45019 11.5634 4.51007 11.688 4.60891C11.8126 4.70775 11.9053 4.84116 11.9544 4.99243C12.0035 5.1437 12.0069 5.3061 11.9641 5.45928C11.9214 5.61247 11.8343 5.74962 11.7139 5.85356L11.7169 5.85307Z" fill="#FFA800"/>
              </svg>
                <strong>4.5</strong>
                </div>
            </div>
            <div className='pts'>
                <p className='mb-0'>2. Attitude et comportement du moniteur</p>
                <div className='rating'>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.7169 5.85307L9.50779 7.75927L10.1808 10.61C10.218 10.7647 10.2084 10.927 10.1534 11.0763C10.0983 11.2257 10.0002 11.3553 9.87153 11.4489C9.74283 11.5425 9.58927 11.5959 9.43025 11.6023C9.27123 11.6087 9.11389 11.5678 8.9781 11.4848L6.499 9.95904L4.01843 11.4848C3.88266 11.5673 3.72551 11.6078 3.56677 11.6012C3.40802 11.5946 3.25479 11.5411 3.12635 11.4476C2.99792 11.3541 2.90003 11.2246 2.84501 11.0756C2.79 10.9265 2.78031 10.7645 2.81718 10.61L3.49267 7.75927L1.28357 5.85307C1.16345 5.74925 1.07657 5.61233 1.03379 5.45943C0.991009 5.30653 0.994224 5.14441 1.04303 4.99332C1.09184 4.84223 1.18408 4.70887 1.30823 4.6099C1.43238 4.51092 1.58294 4.45071 1.7411 4.43679L4.63747 4.20312L5.75478 1.49918C5.81526 1.35182 5.91819 1.22577 6.05049 1.13706C6.1828 1.04834 6.33849 1.00098 6.49778 1.00098C6.65707 1.00098 6.81276 1.04834 6.94506 1.13706C7.07736 1.22577 7.18029 1.35182 7.24077 1.49918L8.35759 4.20312L11.254 4.43679C11.4124 4.45019 11.5634 4.51007 11.688 4.60891C11.8126 4.70775 11.9053 4.84116 11.9544 4.99243C12.0035 5.1437 12.0069 5.3061 11.9641 5.45928C11.9214 5.61247 11.8343 5.74962 11.7139 5.85356L11.7169 5.85307Z" fill="#FFA800"/>
              </svg>
                <strong>4.5</strong>
                </div>
            </div>
            <div className='pts'>
                <p className='mb-0'>3. Réactions et réflexes</p>
                <div className='rating'>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.7169 5.85307L9.50779 7.75927L10.1808 10.61C10.218 10.7647 10.2084 10.927 10.1534 11.0763C10.0983 11.2257 10.0002 11.3553 9.87153 11.4489C9.74283 11.5425 9.58927 11.5959 9.43025 11.6023C9.27123 11.6087 9.11389 11.5678 8.9781 11.4848L6.499 9.95904L4.01843 11.4848C3.88266 11.5673 3.72551 11.6078 3.56677 11.6012C3.40802 11.5946 3.25479 11.5411 3.12635 11.4476C2.99792 11.3541 2.90003 11.2246 2.84501 11.0756C2.79 10.9265 2.78031 10.7645 2.81718 10.61L3.49267 7.75927L1.28357 5.85307C1.16345 5.74925 1.07657 5.61233 1.03379 5.45943C0.991009 5.30653 0.994224 5.14441 1.04303 4.99332C1.09184 4.84223 1.18408 4.70887 1.30823 4.6099C1.43238 4.51092 1.58294 4.45071 1.7411 4.43679L4.63747 4.20312L5.75478 1.49918C5.81526 1.35182 5.91819 1.22577 6.05049 1.13706C6.1828 1.04834 6.33849 1.00098 6.49778 1.00098C6.65707 1.00098 6.81276 1.04834 6.94506 1.13706C7.07736 1.22577 7.18029 1.35182 7.24077 1.49918L8.35759 4.20312L11.254 4.43679C11.4124 4.45019 11.5634 4.51007 11.688 4.60891C11.8126 4.70775 11.9053 4.84116 11.9544 4.99243C12.0035 5.1437 12.0069 5.3061 11.9641 5.45928C11.9214 5.61247 11.8343 5.74962 11.7139 5.85356L11.7169 5.85307Z" fill="#FFA800"/>
              </svg>
                <strong>4.5</strong>
                </div>
            </div>
          </div>
         </div>
         <div className='revby'>
          <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex justify-content-between align-items-center'>
          <img src={userpic} />
          <div className='ps-2'>
          <h5 className='mb-0'>Florencia D</h5>
            <p className='mb-0'>Étudiante</p>
          </div>
          </div>
         <div>
         <h6>Date & time</h6>
          <strong>2 juin, 13h00</strong>
         </div>
          </div>
          <hr></hr>
          <div className='rev-pts'>
            <div className='pts'>
                <p className='mb-0'>1. Qualité de l'enseignement</p>
                <div className='rating'>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.7169 5.85307L9.50779 7.75927L10.1808 10.61C10.218 10.7647 10.2084 10.927 10.1534 11.0763C10.0983 11.2257 10.0002 11.3553 9.87153 11.4489C9.74283 11.5425 9.58927 11.5959 9.43025 11.6023C9.27123 11.6087 9.11389 11.5678 8.9781 11.4848L6.499 9.95904L4.01843 11.4848C3.88266 11.5673 3.72551 11.6078 3.56677 11.6012C3.40802 11.5946 3.25479 11.5411 3.12635 11.4476C2.99792 11.3541 2.90003 11.2246 2.84501 11.0756C2.79 10.9265 2.78031 10.7645 2.81718 10.61L3.49267 7.75927L1.28357 5.85307C1.16345 5.74925 1.07657 5.61233 1.03379 5.45943C0.991009 5.30653 0.994224 5.14441 1.04303 4.99332C1.09184 4.84223 1.18408 4.70887 1.30823 4.6099C1.43238 4.51092 1.58294 4.45071 1.7411 4.43679L4.63747 4.20312L5.75478 1.49918C5.81526 1.35182 5.91819 1.22577 6.05049 1.13706C6.1828 1.04834 6.33849 1.00098 6.49778 1.00098C6.65707 1.00098 6.81276 1.04834 6.94506 1.13706C7.07736 1.22577 7.18029 1.35182 7.24077 1.49918L8.35759 4.20312L11.254 4.43679C11.4124 4.45019 11.5634 4.51007 11.688 4.60891C11.8126 4.70775 11.9053 4.84116 11.9544 4.99243C12.0035 5.1437 12.0069 5.3061 11.9641 5.45928C11.9214 5.61247 11.8343 5.74962 11.7139 5.85356L11.7169 5.85307Z" fill="#FFA800"/>
              </svg>
                <strong>4.5</strong>
                </div>
            </div>
            <div className='pts'>
                <p className='mb-0'>2. Attitude et comportement du moniteur</p>
                <div className='rating'>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.7169 5.85307L9.50779 7.75927L10.1808 10.61C10.218 10.7647 10.2084 10.927 10.1534 11.0763C10.0983 11.2257 10.0002 11.3553 9.87153 11.4489C9.74283 11.5425 9.58927 11.5959 9.43025 11.6023C9.27123 11.6087 9.11389 11.5678 8.9781 11.4848L6.499 9.95904L4.01843 11.4848C3.88266 11.5673 3.72551 11.6078 3.56677 11.6012C3.40802 11.5946 3.25479 11.5411 3.12635 11.4476C2.99792 11.3541 2.90003 11.2246 2.84501 11.0756C2.79 10.9265 2.78031 10.7645 2.81718 10.61L3.49267 7.75927L1.28357 5.85307C1.16345 5.74925 1.07657 5.61233 1.03379 5.45943C0.991009 5.30653 0.994224 5.14441 1.04303 4.99332C1.09184 4.84223 1.18408 4.70887 1.30823 4.6099C1.43238 4.51092 1.58294 4.45071 1.7411 4.43679L4.63747 4.20312L5.75478 1.49918C5.81526 1.35182 5.91819 1.22577 6.05049 1.13706C6.1828 1.04834 6.33849 1.00098 6.49778 1.00098C6.65707 1.00098 6.81276 1.04834 6.94506 1.13706C7.07736 1.22577 7.18029 1.35182 7.24077 1.49918L8.35759 4.20312L11.254 4.43679C11.4124 4.45019 11.5634 4.51007 11.688 4.60891C11.8126 4.70775 11.9053 4.84116 11.9544 4.99243C12.0035 5.1437 12.0069 5.3061 11.9641 5.45928C11.9214 5.61247 11.8343 5.74962 11.7139 5.85356L11.7169 5.85307Z" fill="#FFA800"/>
              </svg>
                <strong>4.5</strong>
                </div>
            </div>
            <div className='pts'>
                <p className='mb-0'>3. Réactions et réflexes</p>
                <div className='rating'>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.7169 5.85307L9.50779 7.75927L10.1808 10.61C10.218 10.7647 10.2084 10.927 10.1534 11.0763C10.0983 11.2257 10.0002 11.3553 9.87153 11.4489C9.74283 11.5425 9.58927 11.5959 9.43025 11.6023C9.27123 11.6087 9.11389 11.5678 8.9781 11.4848L6.499 9.95904L4.01843 11.4848C3.88266 11.5673 3.72551 11.6078 3.56677 11.6012C3.40802 11.5946 3.25479 11.5411 3.12635 11.4476C2.99792 11.3541 2.90003 11.2246 2.84501 11.0756C2.79 10.9265 2.78031 10.7645 2.81718 10.61L3.49267 7.75927L1.28357 5.85307C1.16345 5.74925 1.07657 5.61233 1.03379 5.45943C0.991009 5.30653 0.994224 5.14441 1.04303 4.99332C1.09184 4.84223 1.18408 4.70887 1.30823 4.6099C1.43238 4.51092 1.58294 4.45071 1.7411 4.43679L4.63747 4.20312L5.75478 1.49918C5.81526 1.35182 5.91819 1.22577 6.05049 1.13706C6.1828 1.04834 6.33849 1.00098 6.49778 1.00098C6.65707 1.00098 6.81276 1.04834 6.94506 1.13706C7.07736 1.22577 7.18029 1.35182 7.24077 1.49918L8.35759 4.20312L11.254 4.43679C11.4124 4.45019 11.5634 4.51007 11.688 4.60891C11.8126 4.70775 11.9053 4.84116 11.9544 4.99243C12.0035 5.1437 12.0069 5.3061 11.9641 5.45928C11.9214 5.61247 11.8343 5.74962 11.7139 5.85356L11.7169 5.85307Z" fill="#FFA800"/>
              </svg>
                <strong>4.5</strong>
                </div>
            </div>
          </div>
         </div>
         <div className='revby'>
          <div className='d-flex justify-content-between align-items-center'>
          <div className='d-flex justify-content-between align-items-center'>
          <img src={userpic} />
          <div className='ps-2'>
          <h5 className='mb-0'>Florencia D</h5>
            <p className='mb-0'>Étudiante</p>
          </div>
          </div>
         <div>
         <h6>Date & time</h6>
          <strong>2 juin, 13h00</strong>
         </div>
          </div>
          <hr></hr>
          <div className='rev-pts'>
            <div className='pts'>
                <p className='mb-0'>1. Qualité de l'enseignement</p>
                <div className='rating'>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.7169 5.85307L9.50779 7.75927L10.1808 10.61C10.218 10.7647 10.2084 10.927 10.1534 11.0763C10.0983 11.2257 10.0002 11.3553 9.87153 11.4489C9.74283 11.5425 9.58927 11.5959 9.43025 11.6023C9.27123 11.6087 9.11389 11.5678 8.9781 11.4848L6.499 9.95904L4.01843 11.4848C3.88266 11.5673 3.72551 11.6078 3.56677 11.6012C3.40802 11.5946 3.25479 11.5411 3.12635 11.4476C2.99792 11.3541 2.90003 11.2246 2.84501 11.0756C2.79 10.9265 2.78031 10.7645 2.81718 10.61L3.49267 7.75927L1.28357 5.85307C1.16345 5.74925 1.07657 5.61233 1.03379 5.45943C0.991009 5.30653 0.994224 5.14441 1.04303 4.99332C1.09184 4.84223 1.18408 4.70887 1.30823 4.6099C1.43238 4.51092 1.58294 4.45071 1.7411 4.43679L4.63747 4.20312L5.75478 1.49918C5.81526 1.35182 5.91819 1.22577 6.05049 1.13706C6.1828 1.04834 6.33849 1.00098 6.49778 1.00098C6.65707 1.00098 6.81276 1.04834 6.94506 1.13706C7.07736 1.22577 7.18029 1.35182 7.24077 1.49918L8.35759 4.20312L11.254 4.43679C11.4124 4.45019 11.5634 4.51007 11.688 4.60891C11.8126 4.70775 11.9053 4.84116 11.9544 4.99243C12.0035 5.1437 12.0069 5.3061 11.9641 5.45928C11.9214 5.61247 11.8343 5.74962 11.7139 5.85356L11.7169 5.85307Z" fill="#FFA800"/>
              </svg>
                <strong>4.5</strong>
                </div>
            </div>
            <div className='pts'>
                <p className='mb-0'>2. Attitude et comportement du moniteur</p>
                <div className='rating'>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.7169 5.85307L9.50779 7.75927L10.1808 10.61C10.218 10.7647 10.2084 10.927 10.1534 11.0763C10.0983 11.2257 10.0002 11.3553 9.87153 11.4489C9.74283 11.5425 9.58927 11.5959 9.43025 11.6023C9.27123 11.6087 9.11389 11.5678 8.9781 11.4848L6.499 9.95904L4.01843 11.4848C3.88266 11.5673 3.72551 11.6078 3.56677 11.6012C3.40802 11.5946 3.25479 11.5411 3.12635 11.4476C2.99792 11.3541 2.90003 11.2246 2.84501 11.0756C2.79 10.9265 2.78031 10.7645 2.81718 10.61L3.49267 7.75927L1.28357 5.85307C1.16345 5.74925 1.07657 5.61233 1.03379 5.45943C0.991009 5.30653 0.994224 5.14441 1.04303 4.99332C1.09184 4.84223 1.18408 4.70887 1.30823 4.6099C1.43238 4.51092 1.58294 4.45071 1.7411 4.43679L4.63747 4.20312L5.75478 1.49918C5.81526 1.35182 5.91819 1.22577 6.05049 1.13706C6.1828 1.04834 6.33849 1.00098 6.49778 1.00098C6.65707 1.00098 6.81276 1.04834 6.94506 1.13706C7.07736 1.22577 7.18029 1.35182 7.24077 1.49918L8.35759 4.20312L11.254 4.43679C11.4124 4.45019 11.5634 4.51007 11.688 4.60891C11.8126 4.70775 11.9053 4.84116 11.9544 4.99243C12.0035 5.1437 12.0069 5.3061 11.9641 5.45928C11.9214 5.61247 11.8343 5.74962 11.7139 5.85356L11.7169 5.85307Z" fill="#FFA800"/>
              </svg>
                <strong>4.5</strong>
                </div>
            </div>
            <div className='pts'>
                <p className='mb-0'>3. Réactions et réflexes</p>
                <div className='rating'>
                <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M11.7169 5.85307L9.50779 7.75927L10.1808 10.61C10.218 10.7647 10.2084 10.927 10.1534 11.0763C10.0983 11.2257 10.0002 11.3553 9.87153 11.4489C9.74283 11.5425 9.58927 11.5959 9.43025 11.6023C9.27123 11.6087 9.11389 11.5678 8.9781 11.4848L6.499 9.95904L4.01843 11.4848C3.88266 11.5673 3.72551 11.6078 3.56677 11.6012C3.40802 11.5946 3.25479 11.5411 3.12635 11.4476C2.99792 11.3541 2.90003 11.2246 2.84501 11.0756C2.79 10.9265 2.78031 10.7645 2.81718 10.61L3.49267 7.75927L1.28357 5.85307C1.16345 5.74925 1.07657 5.61233 1.03379 5.45943C0.991009 5.30653 0.994224 5.14441 1.04303 4.99332C1.09184 4.84223 1.18408 4.70887 1.30823 4.6099C1.43238 4.51092 1.58294 4.45071 1.7411 4.43679L4.63747 4.20312L5.75478 1.49918C5.81526 1.35182 5.91819 1.22577 6.05049 1.13706C6.1828 1.04834 6.33849 1.00098 6.49778 1.00098C6.65707 1.00098 6.81276 1.04834 6.94506 1.13706C7.07736 1.22577 7.18029 1.35182 7.24077 1.49918L8.35759 4.20312L11.254 4.43679C11.4124 4.45019 11.5634 4.51007 11.688 4.60891C11.8126 4.70775 11.9053 4.84116 11.9544 4.99243C12.0035 5.1437 12.0069 5.3061 11.9641 5.45928C11.9214 5.61247 11.8343 5.74962 11.7139 5.85356L11.7169 5.85307Z" fill="#FFA800"/>
              </svg>
                <strong>4.5</strong>
                </div>
            </div>
          </div>
         </div>
       </div>
     </div>
      </Tab>
    </Tabs>
       </div>
        </div>
    </div>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Réserver un emplacement</Modal.Title>
        </Modal.Header>
        <Modal.Body className='p-4'>
        <div class="row">
  <div class="col">
    <input type="date" class="form-control" placeholder="DD/MM/YY" />
  </div>
  <div class="col">
    <input type="time" class="form-control" placeholder="Time" />
  </div>

</div>

<div className='mt-4'>
<input type="text" class="form-control" id="inputAddress" placeholder="Meeting place"></input>
</div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
          fermer
          </Button>
          <Button variant="primary" onClick={handleClose}>
            confirmer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default Intructorprofile