// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.map-window .btn-primary {
  background-image: linear-gradient(to right, #c43cab, #f87e4b);
  border: 0;
  border-radius: 26px;
  font-size: 0.75rem;
}

.map-window {
  padding: 0 0.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/components/style.css"],"names":[],"mappings":"AAAA;EACE,6DAA6D;EAC7D,SAAS;EACT,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":[".map-window .btn-primary {\n  background-image: linear-gradient(to right, #c43cab, #f87e4b);\n  border: 0;\n  border-radius: 26px;\n  font-size: 0.75rem;\n}\n\n.map-window {\n  padding: 0 0.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
